import { useState } from 'react';
import styled from 'styled-components';
// @ts-ignore
import { getKeypair, Generator } from 'mitumc';

import copy from 'copy-to-clipboard';

import { version, key } from '../../text/hint.json';
import { KEY_BTC, KEY_ETHER, KEY_STELLAR } from '../../constant';
import SideBar from '../../components/layout/Sidebar';
import COPY_IMG from '../../assets/images/ico_copy.png';
import { isWeightsValidToThres } from '../../lib/Vadation';
import { useRecoilValue } from 'recoil';
import { accountInfoAtom } from '../../store/atoms';
import { useMaintainHook } from '../../hooks/useIsMaintainHook';

const InputContainer = styled.div`
  display: flex;
`;

const KeyGeneratorPage = () => {
  const accountInfo = useRecoilValue(accountInfoAtom);

  const [privKey, setPrivKey] = useState('');
  const [pubKey, setPubKey] = useState('');
  const [address, setAddress] = useState('');
  const [visibleLogo, setVisibleLogo] = useState('');
  const [keys, setKeys] = useState<{ key: string; weight: string }[]>([]);

  const onCopy = (type: 'private' | 'public') => () => {
    switch (type) {
      case 'private':
        copy(privKey);
        alert('copied');
        return;
      case 'public':
        copy(pubKey);
        alert('copied');
        return;
    }
  };

  const generateKey = (type: 'btc' | 'ether' | 'stellar') => () => {
    const keypair = getKeypair(type);

    let privateKey = keypair.privKey.key + '~';
    let publicKey = keypair.pubKey + '~';

    switch (type) {
      case KEY_BTC:
        privateKey += key.btc.priv;
        publicKey += key.btc.pub;
        break;
      case KEY_ETHER:
        privateKey += key.ether.priv;
        publicKey += key.ether.pub;
        break;
      case KEY_STELLAR:
        privateKey += key.stellar.priv;
        publicKey += key.stellar.pub;
        break;
      default:
        throw new Error('Invalid key type');
    }
    privateKey += '-' + version;
    publicKey += '-' + version;

    setPrivKey(privateKey);
    setPubKey(publicKey);
    return [privateKey, publicKey];
  };

  const onCreateAddress = (type: 'single' | 'multi') => () => {
    switch (type) {
      case 'single':
        const [privateKey, pubicKey] = generateKey('ether')();
        const keyArr = [{ key: pubicKey.trim(), weight: '100' }];

        if (
          !isWeightsValidToThres(
            keyArr.map((x) => x.weight),
            '100'
          )
        ) {
          alert('작업을 생성할 수 없습니다.');
          return;
        }

        try {
          const generator = new Generator.currency(process.env.REACT_APP_NETWORK_ID);
          const generatorKeys = generator.createKeys(
            keyArr.map((k) => generator.formatKey(k.key, parseInt(k.weight))),
            100
          );

          // const generator;
        } catch (err) {}

        setKeys([{ key: pubicKey.trim(), weight: '100' }]);

        break;
    }
  };

  useMaintainHook();

  return (
    <main>
      <div id="contents">
        <article style={{ paddingTop: '12px' }}>
          <section>
            <div className="cont-wrap">
              <div className="middle-inner">
                <div
                  className="cont-box"
                  style={{ marginTop: '80px', position: 'relative', top: '16px' }}
                >
                  <SideBar
                    visible={visibleLogo}
                    setVisible={setVisibleLogo}
                    isLogin={!!accountInfo.address}
                    activeLogo={3}
                  />
                  <div className="cont-body keypair">
                    <strong className="tit">Generate Keypair</strong>
                    <div>
                      <p>
                        CRYPTOWORLD Wallet은 여러가지 암호화 알고리즘을 조합하여 키를 생성합니다.
                        발급받은 주소로 바로 CRYPWORLD Wallet을 이용할 수 있습니다.
                      </p>
                      <a
                        style={{ cursor: 'pointer' }}
                        className="btn-type01"
                        onClick={onCreateAddress('single')}
                      >
                        생성하기
                      </a>
                    </div>
                    <div className="readonly-wrap">
                      <strong className="sub-tit">Public Key</strong>
                      <div className="key-area">
                        <p>{pubKey}</p>
                        <button type="button" className="copy" onClick={onCopy('public')}>
                          <img
                            src={COPY_IMG}
                            alt=""
                            style={{ position: 'relative', bottom: !pubKey ? '12px' : '' }}
                          />
                        </button>
                      </div>
                    </div>
                    <div className="readonly-wrap">
                      <strong className="sub-tit">Private Key</strong>
                      <div className="key-area">
                        <p>{privKey}</p>
                        <button type="button" className="copy" onClick={onCopy('private')}>
                          <img
                            src={COPY_IMG}
                            alt=""
                            style={{ position: 'relative', bottom: !pubKey ? '12px' : '' }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </article>
      </div>
    </main>

    // <div>
    //   <div>
    //     <h2>KEY GENERATE</h2>
    //     <div>
    //       <button onClick={generateKey(KEY_BTC)}>BTC</button>
    //       <button onClick={generateKey(KEY_ETHER)}>ETHER</button>
    //       <button onClick={generateKey(KEY_STELLAR)}>STELLAR</button>
    //     </div>
    //
    //     <div>
    //       <InputContainer>
    //         <input
    //           autoComplete="off"
    //           autoCapitalize="off"
    //           autoCorrect="off"
    //           type="text"
    //           value={privKey ? privKey : ''}
    //         />
    //         <button onClick={onCopy('private')}>copy</button>
    //       </InputContainer>
    //
    //       <div>
    //         <input
    //           autoComplete="off"
    //           autoCapitalize="off"
    //           autoCorrect="off"
    //           type="text"
    //           value={pubKey ? pubKey : ''}
    //         />
    //         <button onClick={onCopy('public')}>copy</button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default KeyGeneratorPage;
