import Loader from 'react-loader-spinner';
import { ToastContainer } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  accountInfoAtom,
  bufferOperationAtom,
  currentOperationTypeAtom,
  operationAtom,
} from '../../../store/atoms';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../constant';
import download from '../../../lib/Url';
import { addSignOperation, removeMySignOperation } from '../../../utils/helper';
import { useTranslation } from "react-i18next";

const SignOperationLoadingPage = () => {
  const [bufferOperation, setBufferOperation] = useRecoilState(bufferOperationAtom);
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [currentOperationType, setCurrentOperationType] = useRecoilState(currentOperationTypeAtom);
  const [operation, setOperation] = useRecoilState(operationAtom);
  const router = useHistory();
  const { SIGN_OPERATION_TRANSFER } = routes;
  const { t } = useTranslation();

  useEffect(() => {
    if (bufferOperation.length === 1) {
      setOperation({
        json: bufferOperation[0],
        download: download(bufferOperation[0]),
        filename: bufferOperation[0].hash,
        operation: currentOperationType,
      });

      setTimeout(() => {
        router.push(SIGN_OPERATION_TRANSFER);
      }, 1);
      return;
    }

    const removeDuplicateSigns: any = [];
    bufferOperation.forEach((b: any) => {
      removeDuplicateSigns.push({
        ...b,
        fact_signs: removeMySignOperation(accountInfo.publicKey, b),
      });
    });
    const result = addSignOperation(removeDuplicateSigns[0], removeDuplicateSigns);
    setOperation({
      json: result,
      download: download(result),
      filename: result.hash,
      operation: currentOperationType,
    });

    setTimeout(() => {
      router.push(SIGN_OPERATION_TRANSFER);
    }, 1);
  }, []);


  return (
    <>
      <div
        style={{
          margin: '400px auto',
          // height: '100%',
          width: '500px',
          textAlign: 'center',
        }}
      >
        <div style={{ marginBottom: '30px' }}>
          <Loader type="Bars" color="#2e4bae" height={100} width={100} />
        </div>

        <h3 style={{ fontSize: '20px' }}>{t(`info_text_15`)}</h3>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: '#000',
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '16px',
        }}
      />
    </>
  );
};

export default SignOperationLoadingPage;
