import { useRecoilValue } from 'recoil';
import { accountInfoAtom, amountsAtoms, operationAtom, setResultAtom } from '../../store/atoms';
import copy from 'copy-to-clipboard';
import { OPER_CREATE_ACCOUNT, routes } from '../../constant';
import { useIsLogin } from '../../hooks/useIsLogis';
import { useEffect, useState } from 'react';
import SideBar from '../../components/layout/Sidebar';
import { Link } from 'react-router-dom';
import COPY_IMG from '../../assets/images/ico_open-new.png';
import { useMaintainHook } from '../../hooks/useIsMaintainHook';
import { convertCoinAmount } from '../../utils/helper';
import { ArticleWrapper } from '../../styles/responsive';
import hint from '../../text/hint.json';
import styled from 'styled-components';
import { useIsMobile } from '../../hooks/useIsMobile';

const ResponsePage = () => {
  const operationState = useRecoilValue(operationAtom);
  const operationResult = useRecoilValue(setResultAtom);
  const amountsArr = useRecoilValue(amountsAtoms);
  const accountInfo = useRecoilValue(accountInfoAtom);

  const [visibleLogo, setVisibleLogo] = useState('');

  const [_hash, setHash] = useState('');
  const [_items, setItems] = useState<any>([]);
  const isMobile = useIsMobile();
  const { SIGN_OPERATION_TRANSFER } = routes;

  const { operation } = operationState;
  const { data } = operationResult;
  const onCopy = (msg: string) => () => {
    copy(msg);
    alert('copied');
  };
  useIsLogin();

  useEffect(() => {
    if (operationState.json) {
      console.log(operationState, 'operationState');
      const { download } = operationState;
      const {
        json: {
          fact: { items },
        },
      } = operationState;

      const {
        json: {
          fact: { hash },
        },
      } = operationState;

      setItems(items);
      setHash(hash);
    }
  }, []);

  console.log(_items, '123123', data);

  useMaintainHook();
  return (
    <main>
      <div id="contents">
        <SectionLayout className="res-body">
          <div className="cont-wrap transfer completed">
            <div className="middle-inner">
              <div className="cont-box sub-box">
                {!isMobile && (
                  <SideBar
                    visible={visibleLogo}
                    setVisible={setVisibleLogo}
                    isLogin={true}
                    activeLogo={0}
                  />
                )}

                <div className="cont-body sub-body">
                  <div className="nav">
                    <Link to={`/wallet`}>
                      <strong className="tit back">Transfer</strong>
                    </Link>
                  </div>
                  <h3 className="res-header">Completed 🎉</h3>
                  <div className="transfer-wrap">
                    <ul>
                      <li>
                        <span className="small-tit">Fact Hash</span>
                        <div className="key-area">
                          <p>{_hash}</p>
                          <a
                            href={`${process.env.REACT_APP_EXPLORER}/operation/${_hash}`}
                            target="_blank"
                          >
                            <button type="button">
                              <img src={COPY_IMG} alt="" />
                            </button>
                          </a>
                        </div>
                      </li>

                      <li>
                        <span className="small-tit">Receiver’s Address</span>
                        <div className="text-line">
                          {data
                            ? data
                            : _items.length > 0 && _items[0].receiver && _items[0].receiver}
                        </div>
                      </li>
                      <li>
                        <span className="small-tit">Transfer Information</span>
                        {_items.length > 0 &&
                          _items[0].amounts.map((amount: any, i: number) => (
                            <div key={i} className="two-box">
                              <div className="text-line">{amount.currency}</div>
                              <div className="text-line">{convertCoinAmount(amount.amount)}</div>
                            </div>
                          ))}
                      </li>
                      <ConfirmBtn>
                        <Link to="/wallet">Confirm</Link>
                      </ConfirmBtn>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SectionLayout>
      </div>
    </main>
  );
};

export default ResponsePage;

const SectionLayout = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    align-items: flex-start;
    .nav a {
      height: 64px;
      display: flex;
      align-items: center;
    }

    .cont-wrap {
      .cont-box {
        display: block;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: auto;
        padding: 0px 20px 32px;
      }
    }
    .tit {
      font-weight: 800;
      font-size: 18px;
      line-height: 34px !important;
    }
    .completed h3 {
      margin-top: 16px;
      font-weight: 800;
      font-size: 32px;
      line-height: 44px;
      color: #3de0e0;
    }
    .btn-type01 {
      width: 100%;
    }

    .completed .two-box {
      display: flex;
      margin-top: 12px;
      div:nth-child(1) {
        flex: 3;
        margin-bottom: 0px;
      }
      div:nth-child(2) {
        flex: 5;
        margin-bottom: 0px;
      }
    }
    .transfer-wrap .multiSign-info {
      display: flex;
      div {
        flex: 1;
      }
    }
    .key-area button {
      right: 20px;
    }
    .key-area p {
      height: auto;
      width: 100%;
      padding-right: 32px;
      box-sizing: border-box;
    }
    .completed h3 + .transfer-wrap {
      margin-top: 30px;
    }
  }
`;

export const ConfirmBtn = styled.li`
  margin-top: 44px;
  a {
    margin: 44px 2px 0;
    font-weight: 700;
    padding: 16px 0;
    border-radius: 90px;
    background-color: #4ae6e6;
    color: #fff;
    font-size: 14px;
    display: inline-block;
    width: 380px;
    text-align: center;
    &:hover {
      background-color: #3ad4d4 !important;
      opacity: 1 !important;
    }
  }

  @media (max-width: 1024px) {
    a {
      margin-top: 8px;
      width: 100%;
    }
  }
`;
