import History from '../lib/History';
import Account from '../lib/Account';
export function setHistoryUtil(data, me) {
  console.log(data, me, 'me');
  if (!data) {
    return;
  }
  const histories = data._embedded.map((x) => new History(x._embedded, me));
  const splitHistories = [];

  for (let i = 0; i < histories.length; i++) {
    const _i = histories[i];
    if (!_i.items) {
      continue;
    }
    for (let j = 0; j < _i.items.length; j++) {
      const _j = _i.items[j];
      for (let z = 0; z < _j.amounts.length; z++) {
        const _z = _j.amounts[z];
        splitHistories.push({
          hash: _i.factHash,
          confirmation: _i.inState ? 'SUCCESS' : 'FAIL',
          direction: me === _i.sender ? 'SEND' : 'RECEIVE',
          confirmedAt: _i.confirmedAt,
          target: me === _i.sender ? _j.receiver : _i.sender,
          currency: _z.currency,
          amount: _z.amount,
        });
      }
    }
  }
  console.log(splitHistories);
  return splitHistories;
}

export function accountInfoUtil(address, privateKey, publicKey, data) {
  const account = new Account(address, privateKey, publicKey, data);

  return {
    account: {
      address: account.address,
      accountType: account.accountType,
      publicKey: account.publicKey,
      publicKeys: account.publicKeys,
      balances: account.balances,
      privateKey: account.privateKey,
      restoreKey: undefined,
    },
  };
}
