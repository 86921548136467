import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
// @ts-ignore
import { Generator } from 'mitumc';
import {
  accountInfoAtom,
  amountsAtoms,
  networkAtom,
  networkTypeAtom,
  operationAtom,
  selectCoinAtom,
  setResultAtom,
  tokenFeeAtom,
} from '../../../store/atoms';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useInput from '../../../hooks/useInput';
import {
  isAddressValid,
  isAmountValid,
  isCurrencyValid,
  isDuplicate,
  isInLimit,
  isWeightsValidToThres,
} from '../../../lib/Vadation';
import { useIsLogin } from '../../../hooks/useIsLogis';
import { OPER_CREATE_ACCOUNT, OPER_TRANSFER } from '../../../constant';
import download from '../../../lib/Url';
import useModal from '../../../hooks/useModal';

import SideBar from '../../layout/Sidebar';
import { AlignCenterStyle } from '../../../styles/common';
import { Link, useHistory } from 'react-router-dom';
import CLEAR_BUTTON from '../../../assets/images/ico_clear.png';
import AccountConfirmModal from '../../Modal/createAccount';
import styled from 'styled-components';
import {
  broadcast,
  checkMaintainInfo,
  getAccountInformation,
  getPubAccounts,
  isCheckTempUser,
} from '../../../service/account';

import {
  addRestDotNumber,
  calcAcceptableFeeToken,
  compareCoin,
  convertCoinAmount,
  convertToOriginalAmount,
  isHaveSameCoin,
  parsePrice,
  selectImageFunction,
} from '../../../utils/helper';
import { ArticleWrapper } from '../../../styles/responsive';
import { useTranslation } from 'react-i18next';
import { ButtonHover } from '../../Button/BasicHover';
import Header from '../../layout/Header';
import { useIsMobile } from '../../../hooks/useIsMobile';

const SubmitBtn = styled.button<{ background: boolean }>`
  background: ${(props) => (props.disabled ? '#ced4da' : null)};
  &:hover {
    background-color: #3ad4d4 !important;
    opacity: 1 !important;
  }
`;

const TransferPage = () => {
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [operation, setOperation] = useRecoilState(operationAtom);
  const [amountsArr, setAmountsArr] = useRecoilState(amountsAtoms);
  const [currency, onChangeCurrency, setCurrency] = useInput('');
  const [amount, onChangeAmount, setAmount] = useInput('');
  const [amounts, setAmounts] = useState<{ currency: string; amount: string }[]>([]);
  const [targetAddress, onChangeTargetAddress, setTargetAddress] = useInput('');
  const network = useRecoilValue(networkAtom);
  const [visibleLogo, setVisibleLogo] = useState('');
  const selectCoin = useRecoilValue(selectCoinAtom);
  const [currentCoin, setCurrentCoin] = useState<{ currency: string; amount: string } | null>(null);
  const [visibleText, setVisibleText] = useState('none');
  const [setResult, setSetResult] = useRecoilState(setResultAtom);
  const [tokenFee, setTokenFee] = useState<{ currency: string; fee: string }[]>([]);
  const networkType = useRecoilValue(networkTypeAtom);
  const networkState = useRecoilValue(networkAtom);
  const [privateKey, onChangePrivateKey, setPrivateKey] = useInput('');
  const history = useHistory();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const isMulti = accountInfo.accountType === 'multi';

  const { ModalPortal, openModal, closeModal } = useModal();
  const notify = () => toast.error('응답을 받지 못했습니다.(네트워크 에러)');

  const createAccount = async (publicKey: string, threshold: string) => {
    const keys = [{ key: publicKey, weight: '100' }];

    if (!isWeightsValidToThres(['100'], threshold)) {
      alert(`작업을 생성할 수 없습니다.`);
      return;
    }

    console.log(123123123);

    const checkTempLogin = await isCheckTempUser(publicKey, networkType);
    if (checkTempLogin._embedded && checkTempLogin._embedded.length === 3) {
      alert(t(`info_text_17`));
      return;
    }

    try {
      const generator = new Generator(process.env.REACT_APP_NETWORK_ID).currency;

      const generatorKeys = generator.keys(
        keys.map((k, i) => generator.key(k.key, parseInt(k.weight))),
        parseInt(threshold)
      );

      const generatorAmounts = generator.amounts(
        amounts.map((a) => generator.amount(a.currency, convertToOriginalAmount(a.amount)))
      );

      const createAccountsItem = generator.getCreateAccountsItem(generatorKeys, generatorAmounts);

      const createAccountsFact = generator.getCreateAccountsFact(accountInfo.address, [
        createAccountsItem,
      ]);
      const gn = new Generator(process.env.REACT_APP_NETWORK_ID);
      const createAccounts = gn.getOperation(createAccountsFact, '');
      createAccounts.addSign(accountInfo.privateKey);
      // const createAccountsFact = generator.createCreateAccountsFact(accountInfo.address, [
      //   generator.createCreateAccountsItem(generatorKeys, generatorAmounts),
      // ]);
      // const createAccounts = generator.createOperation(createAccountsFact, '');
      // createAccounts.addSign(accountInfo.privateKey);

      const created = createAccounts.dict();
      console.log(created, 'created');
      setOperation({
        ...operation,
        json: created,
        operation: OPER_CREATE_ACCOUNT,
        download: download(created),
        filename: created.hash,
      });

      openModal();
    } catch (err) {
      console.log(err);
      notify();
      alert(`작업을 생성할 수 없습니다 :(' '입력하신 작업 내용을 확인해 주세요.'`);
    }
  };

  const isMaintain = async () => {
    const result = await checkMaintainInfo();
    const curr = new Date().valueOf();
    const isMaintainTime =
      curr <= new Date(result.end_time).valueOf() && new Date(result.start_time).valueOf()
        ? true
        : false;

    return isMaintainTime;
  };

  const onClickHandler = async () => {
    try {
      const isKillSwitchOn = await isMaintain();
      if (isKillSwitchOn) {
        alert('점검 시간입니다');
        window.location.href = '/';
        return;
      }
      const checkAddress = targetAddress.split('?');
      if (
        checkAddress[1] &&
        checkAddress[1].includes('threshold') &&
        checkAddress[1].includes('weight')
      ) {
        const regex = /[^0-9]/g;
        const threshold = checkAddress[1].split('&')[0].replace(regex, '');
        createAccount(checkAddress[0], threshold);
        return;
      }
      transferHandler();
    } catch (err) {
      console.error(err);
      notify();
    }
  };

  const transferHandler = () => {
    if (!isAddressValid(targetAddress.trim())) {
      alert(`작업을 생성할 수 없습니다 :(', receiver address 형식이 올바르지 않습니다.`);
      return;
    }

    if (accountInfo.address === targetAddress.trim()) {
      alert(`작업을 생성할 수 없습니다 :(', receiver address 와 현재 접속한 계정이 같습니다.`);
      return;
    }

    try {
      const generator = new Generator(process.env.REACT_APP_NETWORK_ID).currency;
      console.log(process.env.REACT_APP_NETWORK_ID, generator, 'process.env.REACT_APP_NETWORK_ID');
      const genAmounts = generator.amounts(
        amounts.map((a) => generator.amount(a.currency, convertToOriginalAmount(a.amount)))
      );

      const transfersFact = generator.getTransfersFact(accountInfo.address, [
        generator.getTransfersItem(targetAddress.trim(), genAmounts),
      ]);
      const gn = new Generator(process.env.REACT_APP_NETWORK_ID);

      const transfers = gn.getOperation(transfersFact, '');
      transfers.addSign(accountInfo.privateKey);
      const created = transfers.dict();
      setOperation({
        ...operation,
        json: created,
        operation: OPER_TRANSFER,
        download: download(created),
        filename: created.hash,
      });
      setAmountsArr(amounts);
      openModal();
    } catch (err) {
      console.log(err);
      alert(`작업을 생성할 수 없습니다 :(', '입력하신 작업 내용을 확인해 주세요.`);
    }
  };

  const onMultiSig = async () => {
    try {
      const isKillSwitchOn = await isMaintain();
      if (isKillSwitchOn) {
        alert('점검 시간입니다');
        window.location.href = '/';
        return;
      }
      const checkAddress = targetAddress.split('?');

      if (
        checkAddress[1] &&
        checkAddress[1].includes('threshold') &&
        checkAddress[1].includes('weight')
      ) {
        const regex = /[^0-9]/g;
        const threshold = checkAddress[1].split('&')[0].replace(regex, '');
        createAccount(checkAddress[0], threshold);
        return;
      }

      if (accountInfo.address === targetAddress.trim()) {
        alert(`작업을 생성할 수 없습니다 :(', receiver address 와 현재 접속한 계정이 같습니다.`);
        return;
      }

      if (!isAddressValid(targetAddress)) {
        alert(`작업을 생성할 수 없습니다 :(', 'receiver address 형식이 올바르지 않습니다`);
        return;
      }

      if (!isInLimit(amounts, parseInt(process.env.REACT_APP_LIMIT_AMOUNTS_IN_ITEM as string))) {
        alert(
          `작업을 생성할 수 없습니다 :('어마운트의 개수가 ${process.env.REACT_APP_LIMIT_AMOUNTS_IN_ITEM}개를 초과하였습니다.`
        );
        return;
      }

      const generator = new Generator(network.networkId).currency;
      const account = accountInfo;

      const amountResult = generator.amounts(
        amounts.map((x) => generator.amount(x.currency, convertToOriginalAmount(x.amount)))
      );

      const transfersFact = generator.getTransfersFact(account.address, [
        generator.getTransfersItem(targetAddress.trim(), amountResult),
      ]);
      const gn = new Generator(network.networkId);
      const transfers = gn.getOperation(transfersFact, '');
      transfers.addSign(accountInfo.privateKey);
      const created = transfers.dict();
      setOperation({
        json: created,
        operation: OPER_TRANSFER,
        download: download(created),
        filename: created.hash,
      });

      openModal();
    } catch (err) {
      console.log(err);
      alert(`작업을 생성할 수 없습니다 :(', '입력하신 작업 내용을 확인해 주세요.`);
    }
  };

  const onAddAmount = async () => {
    if (!targetAddress) {
      alert('주소를 먼저 입력해주세요.');
      return;
    }

    if (amounts.length === 10) {
      alert('전송가능한 토큰 종류는 최대 10개입니다.');
      return;
    }
    if (!(currentCoin!.currency && amount)) {
      return;
    }
    if (
      !isCurrencyValid(
        currentCoin!.currency.trim(),
        accountInfo.balances.map((b) => b.currency)
      )
    ) {
      alert(`어마운트를 추가할 수 없습니다 :(', '잘못된 currency id입니다.`);
      return;
    }

    if (!isAmountValid(amount.trim())) {
      alert(`어마운트를 추가할 수 없습니다 :(', '잘못된 currency amount입니다.`);
      return;
    }

    if (
      isDuplicate(
        currentCoin!.currency.trim(),
        amounts.map((x) => x.currency)
      )
    ) {
      alert(`어마운트를 추가할 수 없습니다 :(', '이미 리스트에 중복된 currency id가 존재합니다.`);
      return;
    }

    if (!compareCoin(currentCoin!.amount, convertToOriginalAmount(amount))) {
      alert('현재 보유량이 부족합니다.');
      return;
    }

    const [isCheckFee, fee, isMinimumAmount, minimumAmount] = await calcAcceptableFeeToken(
      currentCoin!.amount,
      amount,
      currentCoin!.currency
    );

    const checkAddress = targetAddress.split('?');
    if (checkAddress[1] === 'threshold=100&weight=100') {
      if (!isMinimumAmount) {
        alert(`코인 전송 최소수량은 ${minimumAmount} 입니다.`);
        return;
      }
    }

    if (!isCheckFee) {
      alert(`수수료가 부족합니다, 수수료: ${fee}`);
      return;
    }
    if (!isHaveSameCoin(tokenFee, currentCoin!.currency)) {
      setTokenFee([{ currency: currentCoin!.currency, fee: fee as string }, ...tokenFee]);
    }

    setAmounts((prevState) => [
      ...prevState,
      { currency: currentCoin!.currency.trim(), amount: amount.trim() },
    ]);
    setCurrency('');
    setAmount('');
  };

  useEffect(() => {
    setCurrentCoin(selectCoin);
  }, [JSON.stringify(selectCoin)]);

  const onMouseOver = () => {
    setVisibleText('block');
  };

  const onMouseLeave = () => {
    setVisibleText('none');
  };

  const onChangeCurrentCoin = (coin: { currency: string; amount: string }) => () => {
    setCurrentCoin(coin);
    setVisibleText('none');
  };

  const onRemoveAmount = (cur: string) => () => {
    const filteredAmounts = amounts.filter((amount) => {
      return amount.currency !== cur;
    });
    setAmounts(filteredAmounts);
  };

  const toggleDropdown = () => {
    setVisibleText(visibleText === 'block' ? 'none' : 'block');
  };
  useIsLogin();
  // useMaintainHook();

  return (
    <main>
      <div id="contents" style={{ height: '100vh', ...AlignCenterStyle }}>
        <SectionLayout className="operation-transfer">
          <div className="cont-wrap transfer">
            <div className="middle-inner">
              <div className="cont-box sub-box">
                {!isMobile && (
                  <SideBar
                    visible={visibleLogo}
                    setVisible={setVisibleLogo}
                    isLogin={true}
                    activeLogo={0}
                  />
                )}
                <div className="cont-body">
                  <Link to={`/wallet`}>
                    <strong className="tit back">
                      Transfer {accountInfo.accountType === 'multi' ? <em>Multi</em> : null}
                    </strong>
                  </Link>
                  <div className="transfer-container">
                    <div className="transfer-wrap sub-transfer-wrap">
                      <fieldset>
                        <ul className="input-box">
                          <div>
                            <li>
                              <label>Receiver’s Address</label>
                              <span>
                                <input
                                  type="text"
                                  placeholder="받을 사람의 지갑 주소를 정확히 입력해주세요."
                                  value={targetAddress}
                                  onChange={onChangeTargetAddress}
                                  style={{ outline: 'none' }}
                                />
                              </span>
                            </li>
                          </div>

                          <li>
                            <label>Asset</label>
                            <div
                              className="select-box body-type"
                              onMouseOver={onMouseOver}
                              onMouseLeave={onMouseLeave}
                              onClick={toggleDropdown}
                            >
                              <button
                                className={
                                  visibleText === 'none' ? 'transfer-btn' : 'on transfer-btn'
                                }
                                style={{
                                  padding: '0px 40px 0 20px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <img
                                    src={selectImageFunction(
                                      currentCoin ? currentCoin.currency : ''
                                    )}
                                    alt=""
                                    style={{ marginRight: '8px' }}
                                  />
                                  {currentCoin && currentCoin.currency}
                                </div>
                                <em>{currentCoin && convertCoinAmount(currentCoin.amount)}</em>
                              </button>
                              <div style={{ display: visibleText }}>
                                <ul>
                                  {accountInfo.balances.map((b, i) => (
                                    <li
                                      onClick={onChangeCurrentCoin(b)}
                                      key={i}
                                      style={{
                                        padding: '12px 40px 12px 12px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignContent: 'center',
                                      }}
                                    >
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                          src={selectImageFunction(b.currency)}
                                          alt=""
                                          style={{
                                            marginRight: '16px',
                                            width: '24px',
                                            height: '24px',
                                          }}
                                        />
                                        <a style={{ cursor: 'pointer' }}>{b.currency}</a>
                                      </div>
                                      <em>{convertCoinAmount(b.amount)}</em>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </li>
                          <li className="amount">
                            <label>Amount</label>
                            <div className="flex">
                              <span>
                                <input
                                  type="text"
                                  placeholder="이체 수량을 정확히 입력해주세요."
                                  value={amount}
                                  onChange={onChangeAmount}
                                  style={{ outline: 'none' }}
                                />
                                {/* <em>{currentCoin && currentCoin.currency}</em> */}
                              </span>
                              <ButtonHover type="button" className="add" onClick={onAddAmount}>
                                Add
                              </ButtonHover>
                            </div>
                          </li>
                          <li className="list">
                            <label>Transfer List</label>
                            {amounts.map((amount, i) => (
                              <div className={'clear'} key={i}>
                                <span className="input01">
                                  <input
                                    type="text"
                                    placeholder="Token Name"
                                    readOnly
                                    style={{ outline: 'none' }}
                                    value={amount.currency}
                                  />
                                </span>
                                <span className="input02">
                                  <input
                                    type="text"
                                    placeholder="Amount"
                                    style={{ outline: 'none' }}
                                    value={addRestDotNumber(amount.amount)}
                                  />
                                </span>
                                <button
                                  type="button"
                                  className="ico-clear"
                                  style={{ display: 'block' }}
                                  onClick={onRemoveAmount(amount.currency)}
                                >
                                  <img src={CLEAR_BUTTON} alt="" className="close-img" />
                                </button>
                              </div>
                            ))}
                          </li>
                        </ul>
                      </fieldset>
                    </div>
                  </div>
                  {isMulti ? (
                    <SubmitBtn
                      disabled={!(targetAddress && amounts.length > 0)}
                      background={!(targetAddress && amounts.length > 0)}
                      className="btn-type01"
                      onClick={onMultiSig}
                    >
                      <span>서명하기</span>
                    </SubmitBtn>
                  ) : (
                    <SubmitBtn
                      className="btn-type01 transfer-submit-btn"
                      disabled={!(targetAddress && amounts.length > 0)}
                      background={!(targetAddress && amounts.length > 0)}
                      onClick={() => {
                        onClickHandler();
                      }}
                    >
                      {t(`info_text_40`)}
                    </SubmitBtn>
                  )}
                </div>
              </div>
            </div>
          </div>
        </SectionLayout>
      </div>
      <ModalPortal>
        <AccountConfirmModal
          isClose={closeModal}
          address={targetAddress}
          amounts={amounts}
          feeArr={tokenFee}
        />
      </ModalPortal>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ backgroundColor: '#000', color: '#fff', fontWeight: 'bold' }}
      />
    </main>
  );
};

export default TransferPage;

const SectionLayout = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .transfer-wrap .select-box em {
    font-weight: 600;
    position: initial;
  }

  .transfer-wrap {
    .select-box > div ul {
      padding: 12px 0px;
    }
    .select-box > div ul li {
      padding: 12px 0px 12px;
      margin: 0px;
    }
    .select-box > div ul li:hover {
      background-color: #f5f5f5;
    }
  }

  @media (max-width: 1024px) {
    align-items: flex-start;
    width: 100%;

    .cont-wrap {
      .cont-box {
        display: block;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: 100%;
        box-sizing: border-box;
        padding: 8px 20px 32px;
      }
    }
    .tit {
      font-weight: 800;
      font-size: 18px;
      line-height: 34px;
      color: #23262f;
      margin-top: 0px;
    }
    .transfer-wrap {
      margin-top: 0px;

      .clear {
        display: flex;
        gap: 8px;

        .input01 {
          flex: 3;
        }
        .input02 {
          flex: 5;
          margin: 0px;
        }
        button {
          position: relative;
          margin-top: 0px;
          flex: 1;
        }
      }
    }
    .btn-type01 {
      width: 100%;
      margin-top: 40px;
    }
    .select-box {
      height: 72px;
    }
    .input-box {
      span {
        margin-top: 0px;
        box-sizing: border-box;
      }
      .flex {
        display: flex;
      }
    }
    .transfer-wrap {
      .select-box button {
        height: 72px;
      }

      .amount {
        span input {
          width: 100%;
        }
      }

      .select-box {
        > div ul {
          padding: 0px;
        }
        > div ul li {
          padding: 12px;
          margin: 0px;
          align-items: center;
          &:first-child {
            border-radius: 12px 12px 0px 0px;
          }
          &:last-child {
            border-radius: 0px 0px 12px 12px;
          }

          em {
            top: 12px;
            right: 20px;
          }
        }
      }

      .amount span input {
        font-size: 12px;
      }
    }
    .transfer-wrap .ico-clear {
    }
  }
`;
