import { VFC } from 'react';
import classNames from 'classnames';
import Copy from 'copy-to-clipboard';

import { BalanceHistory } from '../../types/account';

import COPY_IMG from '../../assets/images/ico_copy.png';
import { convertCoinAmount } from '../../utils/helper';

interface Props {
  history: BalanceHistory[];
}

const TransactionHistory: VFC<Props> = ({ history }) => {
  const onCopy = (text: string) => () => {
    Copy(text);
    alert('copied');
  };

  const statusObjFunc = (status: string) => {
    switch (status) {
      case 'SUCCESS':
        return 'green';
      case 'PENDING':
        return 'yellow';
      case 'FAIL':
        return 'red';
    }

    console.log(history, '-------------------------');
  };

  return (
    <>
      {history.map((his, i) => (
        <div className="line-box">
          <ul>
            <li>
              <strong>Status</strong>
              <div>
                <em className={classNames('status-type', statusObjFunc(his.confirmation))}>
                  {his.confirmation}
                </em>
              </div>
            </li>
            <li>
              <strong>Type</strong>
              <div>{his.direction}</div>
            </li>
            <li>
              <strong>Timestamp</strong>
              <div>{his.confirmedAt}</div>
            </li>
            <li>
              <strong>To</strong>
              <div className="txt-copy">
                <p>{his.target}</p>
                <button type="button" className="copy" onClick={onCopy(his.target)}>
                  <img src={COPY_IMG} alt="copy img" />
                </button>
              </div>
            </li>
            <li>
              <strong>Symbol</strong>
              <div>{his.currency}</div>
            </li>
            <li>
              <strong>Amount</strong>
              <div>{convertCoinAmount(his.amount).toLocaleString()}</div>
            </li>
            <li>
              <strong>Fact hash</strong>
              <div className="txt-copy">
                <a href={`${process.env.REACT_APP_EXPLORER}/operation/${his.hash}`} target="_blank">
                  {his.hash}
                </a>
                <button type="button" className="copy" onClick={onCopy(his.hash)}>
                  <img src={COPY_IMG} alt="copy img" />
                </button>
              </div>
            </li>
          </ul>
        </div>
      ))}
    </>
  );
};

export default TransactionHistory;
