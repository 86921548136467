import React, { SetStateAction, useEffect, useState, VFC } from 'react';
import { Link } from 'react-router-dom';
import LogoList from '../../sidebar/LogoList';
// import LOGO from '../../../assets/images/logo_side.png';
import NEW_LOGO from '../../../assets/images/protoconwallet-logo.svg';
import SETTING from '../../../assets/images/ico_side-setting.png';
import LOGOUT from '../../../assets/images/ico_side-logout.png';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: string;
  setVisible: React.Dispatch<SetStateAction<string>>;
  isLogin: boolean;
  activeLogo: number;
  bottomLogo?: number;
}

const SideBarContainer = styled.div`
  //@media (max-width: 670px) {
  //  display: none;
  //}
`;

const SideBar: VFC<Props> = ({ visible, setVisible, isLogin, activeLogo, bottomLogo = 1000 }) => {
  const [visibleLogo, setVisibleLogo] = useState('');
  const onLogout = (text: string) => () => {
    if (text === `${t(`info_text_5`)}`) {
      window.location.reload();
    }
  };
  const { t } = useTranslation();
  const BottomSideLogo = [
    { text: `${t(`info_text_4`)}`, img: SETTING, redirect: '/setting' },
    { text: `${t(`info_text_5`)}`, img: LOGOUT },
  ];

  useEffect(() => {}, []);

  return (
    <SideBarContainer className="side">
      <Link to="/" className="logo">
        <img src={NEW_LOGO} alt="" />
      </Link>
      <ul className="top">
        <LogoList
          visible={visible}
          setVisible={setVisible}
          defaultOn={activeLogo}
          isLogin={isLogin}
        />
      </ul>
      <div className="bottom" style={{ display: 'block' }}>
        <ul>
          {BottomSideLogo.map((l, i) => {
            return (
              <li
                key={i}
                onMouseOver={() => {
                  setVisibleLogo(l.text);
                }}
                onMouseLeave={() => {
                  setVisibleLogo('');
                }}
                onClick={onLogout(l.text)}
              >
                {l.text === `${t(`info_text_5`)}` && !isLogin ? null : l.redirect ? (
                  <Link to={l.redirect} style={{ opacity: i === bottomLogo ? 1 : 0.7 }}>
                    <img src={l.img} alt="" />
                  </Link>
                ) : (
                  <a style={{ cursor: 'pointer' }}>
                    <img src={l.img} alt="" />
                  </a>
                )}

                <div
                  className="bubble"
                  style={{
                    display: visibleLogo === l.text ? 'block' : 'none',
                    // opacity: l.text === visibleLogo ? 1 : 0.7,
                  }}
                >
                  {l.text}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </SideBarContainer>
  );
};

export default SideBar;
