import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Copy from 'copy-to-clipboard';
// @ts-ignore
import { getKeypair, Generator, getNewKeypair } from 'mitumc';

import { version, key } from '../../../text/hint.json';

import SideBar from '../../../components/layout/Sidebar';
import EYE_IMG from '../../../assets/images/ico_eye.png';
import EYE_FILL_IMG from '../../../assets/images/ico_eye2.png';
import COPY_IMG from '../../../assets/images/ico_copy.png';
import { routes } from '../../../constant';
import styled from 'styled-components';
import LoginErrorModal from '../../../components/Modal/LoginError';
import NetworkErrorModal from '../../../components/Modal/NetworkError';
import useModal from '../../../hooks/useModal';
import { loginHandler } from '../../../utils/onLogin';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  accountInfoAtom,
  historyAtom,
  isLoginAtom,
  isTempLoginAtoms,
  networkAtom,
  networkTypeAtom,
} from '../../../store/atoms';
import ConfirmLoginModal from '../../../components/Modal/ConfirmLogin';
import { useMaintainHook } from '../../../hooks/useIsMaintainHook';
import { ArticleWrapper } from '../../../styles/responsive';
import { useTranslation } from 'react-i18next';
import {
  BasicDarkHoverBtn,
  BasicHoverBtn,
  BasicHoverLink,
  BasicHoverLinkText,
} from '../../../components/Button/BasicHover';
import { useIsMobile } from '../../../hooks/useIsMobile';
import BACK_IMG from '../../../assets/images/ico_back.png';
import { TextAreaWrapper } from '../../../styles/common';

const CreateMainPrivateKeyPage = () => {
  const { t } = useTranslation();
  const [visibleLogo, setVisibleLogo] = useState('');
  const [privKey, setPrivKey] = useState('');
  const [passwordTypePrivKey, setPasswordTypePrivKey] = useState('');
  const [inputType, setInputType] = useState('password');
  const networkType = useRecoilValue(networkTypeAtom);
  const [isTempUser, setIsTempUser] = useRecoilState(isTempLoginAtoms);
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [history, setHistory] = useRecoilState(historyAtom);
  const networkState = useRecoilValue(networkAtom);
  const isMobile = useIsMobile();

  const router = useHistory();

  const { ModalPortal, openModal, closeModal } = useModal();
  const {
    ModalPortal: LoginPortal,
    openModal: loginOpenModal,
    closeModal: loginCloseModal,
  } = useModal();

  const {
    ModalPortal: ConfirmPortal,
    openModal: confirmOpenModal,
    closeModal: confirmCloseModal,
  } = useModal();

  const { CREATE_ADDRESS, CREATE_ADDRESS_ETHER } = routes;

  const etherGenerateKey = () => {
    // const keypair = getKeypair('ether');
    let keypair: any;
    let privateKey: any;
    try {
      keypair = getNewKeypair();
      privateKey = keypair.getPrivateKey();
    } catch (err) {
      console.error(`error occur`, err);
    } finally {
      while (!keypair || !privateKey) {
        keypair = getNewKeypair();
        privateKey = keypair.getPrivateKey();
      }
    }
    // const keypair = getNewKeypair();
    //
    // let privateKey = keypair.getPrivateKey();

    // privateKey += key.ether.priv;
    // publicKey += key.ether.pub;

    // privateKey += '-' + version;
    // publicKey += '-' + version;
    setPasswordTypePrivKey('*'.repeat(privateKey.length));
    setPrivKey(privateKey);
  };

  const onCopyPrivateKey = () => {
    console.log('123123');
    Copy(privKey);
    alert('copied');
  };

  const onChangeInputType = () => {
    setInputType((prevState) => {
      return prevState === 'password' ? 'text' : 'password';
    });
  };

  useEffect(() => {
    etherGenerateKey();
  }, []);

  useMaintainHook();

  const goPage = (path: string) => () => {
    router.push(path);
  };

  return (
    <main>
      <div id="contents">
        <ArticleWrapper>
          <SectionLayout>
            <div className="cont-wrap">
              <div className="middle-inner">
                <div className="cont-box">
                  {!isMobile && (
                    <SideBar
                      visible={visibleLogo}
                      setVisible={setVisibleLogo}
                      isLogin={false}
                      activeLogo={1}
                    />
                  )}
                  <div className="cont-body keypair">
                    <div className="header">
                      <strong
                        onClick={goPage(CREATE_ADDRESS)}
                        className={`tit comment ${isMobile ? 'back' : ''}`}
                      >
                        {t(`home_one_text_3`)}
                      </strong>
                    </div>
                    <div style={{ marginTop: isMobile ? '' : '60px' }}>
                      <p>{t(`section_two_text_1`)}</p>
                    </div>
                    <div className="readonly-wrap">
                      <strong className="sub-tit">Private Key</strong>
                      <div className="key-area">
                        <TextAreaWrapper
                          className="priv-textarea"
                          readOnly={true}
                          value={inputType === 'password' ? passwordTypePrivKey : privKey}
                        />
                        <span className="btn-box" style={{ top: '16px', position: 'initial' }}>
                          <button type="button" className="copy" style={{ zIndex: 2 }}>
                            <img src={COPY_IMG} alt="" onClick={onCopyPrivateKey} />
                          </button>
                          {inputType === 'password' ? (
                            <button
                              type="button"
                              className="eye"
                              onClick={onChangeInputType}
                              style={{ zIndex: 1 }}
                            >
                              <img src={EYE_FILL_IMG} alt="" style={{ position: 'relative' }} />
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="eye"
                              onClick={onChangeInputType}
                              style={{ zIndex: 1 }}
                            >
                              <img src={EYE_IMG} alt="" style={{ position: 'relative' }} />
                            </button>
                          )}
                        </span>
                      </div>
                    </div>
                    <BasicHoverBtn
                      onClick={confirmOpenModal}
                      className="btn-type01 wallet-open"
                      style={{ cursor: 'pointer' }}
                    >
                      {t(`section_three_text_1`)}
                    </BasicHoverBtn>
                    <BasicDarkHoverBtn
                      className="btn-type01 navy wallet-open"
                      onClick={etherGenerateKey}
                    >
                      {t(`section_three_text_2`)}
                    </BasicDarkHoverBtn>
                    <BasicHoverLinkText to={CREATE_ADDRESS_ETHER} className="btn-key-go">
                      {t(`section_two_text_2`)}
                    </BasicHoverLinkText>
                  </div>
                </div>
              </div>
            </div>
          </SectionLayout>
        </ArticleWrapper>
      </div>
      <ModalPortal>
        <LoginErrorModal closeModal={closeModal} />
      </ModalPortal>

      <LoginPortal>
        <NetworkErrorModal closeModal={loginCloseModal} />
      </LoginPortal>

      <ConfirmPortal>
        <ConfirmLoginModal
          privateKey={privKey}
          openModal={openModal}
          networkType={networkType}
          setIsTempUser={setIsTempUser}
          setIsLogin={setIsLogin}
          router={router}
          networkState={networkState}
          setAccountInfo={setAccountInfo}
          setHistory={setHistory}
          confirmCloseModal={confirmCloseModal}
        />
      </ConfirmPortal>
    </main>
  );
};

export default CreateMainPrivateKeyPage;

export const SectionLayout = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .keypair button {
    right: 30px;
  }
  .keypair button:nth-child(2) {
    top: 40px;
  }
  .keypair p {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #777e90;
  }

  @media (max-width: 1024px) {
    align-items: flex-start;

    .cont-wrap {
      .cont-box {
        display: block;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: auto;
        padding: 0px 20px 32px;
      }
    }

    .header {
      padding-bottom: 16px;
      height: 64px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      gap: 4px;
      .btn-back {
        cursor: pointer;
      }
      .tit {
        padding: 6px 36px 0px;
        font-weight: 800;
        font-size: 18px;
        line-height: 20px;
      }
    }

    .keypair {
      .btn-type01 {
        margin: 16px auto 0;
        width: auto;
        max-width: 600px;
      }
      .readonly-wrap {
        margin-top: 32px;
      }
      .btn-box {
        right: 22px;
        position: absolute;
      }
      button {
        position: relative;
        right: 0px;
        padding-bottom: 8px;
        width: 20px;
      }
      button:nth-child(2) {
        top: 0px;
      }
    }
    .create-address-comment {
      white-space: pre-line;
      text-align: center;
    }
  }
`;
