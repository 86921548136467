import CreateAccount from '../../components/Operation/createAccount';
import useReactRouter from 'use-react-router';
import { useIsLogin } from '../../hooks/useIsLogis';
import TransferPage from '../../components/Operation/Transfer';
import { useMaintainHook } from '../../hooks/useIsMaintainHook';

const OperationPage = () => {
  const {
    location: { pathname },
  } = useReactRouter();
  const path = pathname.split('/')[2] as 'create' | 'transfer';
  const renderObj: { create: JSX.Element; update: JSX.Element; transfer: JSX.Element } = {
    create: <CreateAccount />,
    update: <CreateAccount />,
    transfer: <TransferPage />,
  };

  // useIsLogin(); // login 여부 확인
  useMaintainHook();

  return <div>{renderObj[path]}</div>;
};

export default OperationPage;
