import Loader from 'react-loader-spinner';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  accountInfoAtom,
  networkAtom,
  networkTypeAtom,
  operationAtom,
  setResultAtom,
} from '../../store/atoms';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useIsLogin } from '../../hooks/useIsLogis';
import { useMaintainHook } from '../../hooks/useIsMaintainHook';
import { routes } from '../../constant';
import { toast, ToastContainer } from 'react-toastify';

const LoadingPage = () => {
  const router = useHistory();
  const [operationState, setOperation] = useRecoilState(operationAtom);
  const [operationResult, setOperationResult] = useRecoilState(setResultAtom);
  const network = useRecoilValue(networkAtom);
  const networkType = useRecoilValue(networkTypeAtom);
  const accountInfo = useRecoilValue(accountInfoAtom);

  const { data, res, isBroadcast } = operationResult;

  const [counter, setCounter] = useState(0);
  const { MULTI_SIG_RESPONSE } = routes;

  const notify = (msg: string) => {
    toast.error(msg);
    setTimeout(() => {
      router.goBack();
    }, 3100);
  };

  const getResult = async (_hash: any, _res: any, _isBroadcast: any) => {
    if (!_isBroadcast) {
      alert('에러가 발생했습니다.');
      router.push('/wallet');
      return;
    }
    try {
      const result = await axios.get(network.networkSearchFact + data);
      if (result.data._embedded.in_state) {
        setOperationResult({
          isBroadcast: true,
          isStateIn: true,
          res: _res,
          status: 200,
          data: _hash,
        });
        router.push(MULTI_SIG_RESPONSE);
      } else {
        notify(result.data._embedded.reason.msg);
        setOperationResult({
          isBroadcast: true,
          isStateIn: false,
          res: { title: result.data._embedded.reason.msg },
          status: 400,
          data: _hash,
        });
        // router.push(MULTI_SIG_RESPONSE);
      }
    } catch (err) {
      console.log(err, 'loading');
      notify(err.response.data);
      // setOperationResult({
      //   isBroadcast: true,
      //   isStateIn: false,
      //   res: err.response.data,
      //   status: 400,
      //   data: _hash,
      // });
      // router.push(MULTI_SIG_RESPONSE);
    }
  };

  const getResponse = () => {
    setTimeout(
      (hs, rs, broad) => {
        getResult(hs, rs, broad);
      },
      5000,
      data,
      res,
      isBroadcast
    );
  };

  useEffect(() => {
    getResponse();
  }, []);
  useIsLogin();
  useMaintainHook();
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100vh',
          textAlign: 'center',
        }}
      >
        <div style={{ marginBottom: '30px' }}>
          <Loader type="Bars" color="#32d1d1" height={100} width={100} />
        </div>

        <h3 style={{ fontSize: '20px' }}>잠시만 기다려 주세요.</h3>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: '#000',
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '16px',
        }}
      />
    </>
  );
};

export default LoadingPage;
