import { COIN_SERVER_API, SERVER_API } from '../index';
import {
  AccountHistoryResBody,
  AccountInfoResBody,
  PublicAccountRes,
  PublicEmbedded,
} from '../../types/account';
import { isOperation } from '../../lib/Vadation';
import axios from 'axios';

const domain = '/account';
const subdomain = '/builder/send';

export const getAccountInformation = async (account: string, type: 'main' | 'test') => {
  return await COIN_SERVER_API(type)
    .get<AccountInfoResBody>(`${domain}/${account}`)
    .then((resp) => resp.data);
};

export const getAccountHistory = async (account: string, type: 'main' | 'test') => {
  return await COIN_SERVER_API(type)
    .get<AccountHistoryResBody>(`${domain}/${account}/operations?reverse=1`)
    .then((resp) => resp.data);
};

export const broadcast = async (operation: any, type: 'main' | 'test') => {
  if (!isOperation(operation)) {
    return undefined;
  }
  return await COIN_SERVER_API(type)
    .post(subdomain, operation)
    .then((resp) => resp);
};

export const getPubAccounts = async (
  key: string,
  networkAccount: string,
  type: 'main' | 'test'
) => {
  console.log(networkAccount, 'network');
  return await COIN_SERVER_API(type)
    .get<PublicAccountRes>(`${networkAccount}${key}`)
    .then((resp) => resp.data);
};

export const isCheckTempUser = async (publicKey: string, type: 'main' | 'test') => {
  return await COIN_SERVER_API(type)
    .get<{ hint: string; _embedded: any }>(
      `${process.env.REACT_APP_API_PUBLIC_ACCOUNTS}${publicKey}`
    )
    .then((resp) => resp.data);
};

export const checkMaintainInfo = async () => {
  return await axios
    .get<{ start_time: string; end_time: string; message: { ko: string; en: string } }>(
      process.env.REACT_APP_MAINTAIN + '?' + Math.random()
    )
    .then((resp) => resp.data);
};

export const checkFeeAmount = async (type: 'main' | 'test', currency: string) => {
  return await COIN_SERVER_API(type)
    .get<any>(`/currency/${currency}`)
    .then((resp) => resp.data);
};

export const isCompletedSignOperation = async (type: 'main' | 'test', hash: string) => {
  return await COIN_SERVER_API(type)
    .get<{ _embedded: { in_state: boolean } }>(`${process.env.REACT_APP_API_SEARCH_FACT}${hash}`)
    .then((resp) => resp.data);
};

export const getGenesisAccountInfo = async (type: 'main' | 'test', address: string) => {
  return await COIN_SERVER_API(type)
    .get<PublicEmbedded>(`/account/${address}`)
    .then((resp) => resp.data);
};
