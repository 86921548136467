import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import Copy from 'copy-to-clipboard';

import SideBar from '../../../../components/layout/Sidebar';
import EYE_IMG from '../../../../assets/images/ico_eye.png';
import EYE_FILL_IMG from '../../../../assets/images/ico_eye2.png';
import { routes } from '../../../../constant';
import {
  accountInfoAtom,
  etherPrivateKeyAtom,
  historyAtom,
  isLoginAtom,
  isTempLoginAtoms,
  networkAtom,
  networkTypeAtom,
} from '../../../../store/atoms';
import COPY_IMG from '../../../../assets/images/ico_copy.png';
import LoginErrorModal from '../../../../components/Modal/LoginError';
import NetworkErrorModal from '../../../../components/Modal/NetworkError';
import useModal from '../../../../hooks/useModal';
import { loginHandler } from '../../../../utils/onLogin';
import ConfirmLoginModal from '../../../../components/Modal/ConfirmLogin';
import { ArticleWrapper } from '../../../../styles/responsive';
import { useMaintainHook } from '../../../../hooks/useIsMaintainHook';
import { useTranslation } from 'react-i18next';
import Header from '../../../../components/layout/Header';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { TextAreaWrapper } from '../../../../styles/common';
import styled from 'styled-components';

const CreateAddressEtherCompletePage = () => {
  const [visibleLogo, setVisibleLogo] = useState('');
  const [inputType, setInputType] = useState('password');
  const [passwordTypePrivKey, setPasswordTypePrivKey] = useState('');
  const { t } = useTranslation();
  const networkType = useRecoilValue(networkTypeAtom);
  const [isTempUser, setIsTempUser] = useRecoilState(isTempLoginAtoms);
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [history, setHistory] = useRecoilState(historyAtom);
  const networkState = useRecoilValue(networkAtom);
  const isMobile = useIsMobile();

  const etherPrivate = useRecoilValue(etherPrivateKeyAtom);

  const router = useHistory();

  const { ModalPortal, openModal, closeModal } = useModal();
  const {
    ModalPortal: LoginPortal,
    openModal: loginOpenModal,
    closeModal: loginCloseModal,
  } = useModal();

  const {
    ModalPortal: ConfirmPortal,
    openModal: confirmOpenModal,
    closeModal: confirmCloseModal,
  } = useModal();

  const { CREATE_MAIN_ADDRESS } = routes;

  const goPage = () => {
    router.push(CREATE_MAIN_ADDRESS);
  };

  const onChangeInputType = () => {
    setInputType((prevState) => {
      return prevState === 'password' ? 'text' : 'password';
    });
  };

  const onCopyPrivateKey = () => {
    Copy(etherPrivate);
    alert('copied');
  };

  useEffect(() => {
    setPasswordTypePrivKey('*'.repeat(etherPrivate.length));
  }, [etherPrivate]);
  useMaintainHook();

  return (
    <main>
      <div id="contents">
        <ArticleWrapper>
          <SectionLayout>
            <div className="cont-wrap">
              <div className="middle-inner">
                <div className="cont-box">
                  {!isMobile && (
                    <SideBar
                      visible={visibleLogo}
                      setVisible={setVisibleLogo}
                      isLogin={false}
                      activeLogo={1}
                    />
                  )}
                  <div className="cont-body keypair keypair04">
                    <div className="header">
                      <strong className="tit back comment" onClick={goPage}>
                        {t(`home_one_text_3`)}
                      </strong>
                    </div>

                    <div>
                      <p className="keypair-comment">{t(`section_two_text_3`)}</p>
                    </div>
                    <div className="readonly-wrap">
                      <strong className="sub-tit">Private Key</strong>
                      <div className="key-area">
                        <TextAreaWrapper
                          readOnly={true}
                          value={inputType === 'password' ? passwordTypePrivKey : etherPrivate}
                          className="priv-ether-textarea"
                        />
                        <span className="btn-box">
                          <button type="button" className="copy">
                            <img
                              src={COPY_IMG}
                              alt=""
                              onClick={onCopyPrivateKey}
                              style={{ maxWidth: 'none' }}
                            />
                          </button>

                          <button type="button" className="eye" onClick={onChangeInputType}>
                            <img
                              src={inputType === 'password' ? EYE_FILL_IMG : EYE_IMG}
                              alt=""
                              style={{ maxWidth: 'none' }}
                            />
                          </button>
                        </span>
                      </div>
                    </div>
                    <a
                      onClick={confirmOpenModal}
                      className="btn-type01 wallet-open"
                      style={{ cursor: 'pointer' }}
                    >
                      {t(`section_three_text_1`)}
                    </a>
                    <Link to={CREATE_MAIN_ADDRESS} className="btn-key-go">
                      {t(`section_two_text_5`)}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SectionLayout>
        </ArticleWrapper>
      </div>

      <ModalPortal>
        <LoginErrorModal closeModal={closeModal} />
      </ModalPortal>

      <LoginPortal>
        <NetworkErrorModal closeModal={loginCloseModal} />
      </LoginPortal>

      <ConfirmPortal>
        <ConfirmLoginModal
          privateKey={etherPrivate}
          openModal={openModal}
          networkType={networkType}
          setIsTempUser={setIsTempUser}
          setIsLogin={setIsLogin}
          router={router}
          networkState={networkState}
          setAccountInfo={setAccountInfo}
          setHistory={setHistory}
          confirmCloseModal={confirmCloseModal}
        />
      </ConfirmPortal>
    </main>
  );
};

export default CreateAddressEtherCompletePage;

export const SectionLayout = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    .cont-wrap {
      .cont-box {
        display: block;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: auto;
        padding: 0px 20px 32px;
      }
    }
    .tit {
      color: #3de0e0;
      text-align: center;
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
      white-space: pre-line;
    }

    .keypair {
      .tit + div {
        margin-top: 24px;
      }
      .tit + div p {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #777e90;
      }

      .btn-type01 {
        margin: 16px 0px;
        width: 100%;
      }
    }
    .create-address-comment {
      white-space: pre-line;
      text-align: center;
    }
  }
`;
