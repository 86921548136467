import { useRecoilState, useRecoilValue } from 'recoil';
import moment from 'moment';
import COPY from 'copy-to-clipboard';

import { routes } from '../../constant';
import {
  accountInfoAtom,
  balanceHistoryAtom,
  historyAtom,
  isTempLoginAtoms,
  networkTypeAtom,
} from '../../store/atoms';
import { AlignCenterStyle } from '../../styles/common';
import { Link, useHistory } from 'react-router-dom';
import SideBar from '../../components/layout/Sidebar';
import { useEffect, useState } from 'react';
import TransactionHistory from '../../components/Transaction/History';
import { useIsLogin } from '../../hooks/useIsLogis';
import { getAccountHistory } from '../../service/account';
import { setHistoryUtil } from '../../utils/manufactureStore';
import { BalanceHistory } from '../../types/account';
import { useMaintainHook } from '../../hooks/useIsMaintainHook';
import { ArticleWrapper } from '../../styles/responsive';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../hooks/useIsMobile';
import Header from '../../components/layout/Header';
import styled from 'styled-components';

const TransactionPage = () => {
  const accountInfo = useRecoilValue(accountInfoAtom);
  const historyInfo = useRecoilValue(historyAtom);
  const [visibleLogo, setVisibleLogo] = useState('');
  const isTempUser = useRecoilValue(isTempLoginAtoms);
  const [history, setHistory] = useRecoilState(balanceHistoryAtom);
  const { t } = useTranslation();
  const networkType = useRecoilValue(networkTypeAtom);
  const isMobile = useIsMobile();

  useEffect(() => {
    bootstrap();
  }, []);

  const bootstrap = async () => {
    const data = await getAccountHistory(accountInfo.address, networkType);
    const result: any = setHistoryUtil(data, accountInfo.address);
    setHistory(result);
  };
  useIsLogin();

  useMaintainHook();

  return (
    <main>
      {isMobile && <Header />}
      <div id="contents">
        <SectionLayout>
          <div className="cont-wrap">
            <div className="middle-inner">
              <div className="cont-box sub-box history-box">
                {!isMobile && (
                  <SideBar
                    visible={visibleLogo}
                    setVisible={setVisibleLogo}
                    isLogin={true}
                    activeLogo={1}
                  />
                )}
                <div className="cont-body history history-wrapper">
                  {!isMobile && (
                    <Link to={isTempUser.isLogin ? `/wallet` : `/wallet`}>
                      <strong className="tit back res-tit">Transfer</strong>
                    </Link>
                  )}
                  <div className="history-wrap">
                    <strong className={`${isMobile ? 'tit' : 'sub-tit'}`}>History</strong>
                    {history.length > 0 ? (
                      <TransactionHistory history={history} />
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '300px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        {t(`info_text_28`)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SectionLayout>
      </div>
    </main>
  );
};
const SectionLayout = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 1024px) {
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    .cont-wrap {
      .cont-box {
        display: block;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: 100%;
        padding: 0px 20px 32px;
        height: 100%;
        overflow-y: auto;
      }
    }

    .tit {
      padding-top: 28px;
    }
    .tit + div {
      margin-top: 24px;
    }
    .tit + div p {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #777e90;
    }

    .btn-type01 {
      margin: 40px auto 0;
      width: auto;
      max-width: 600px;
    }

    .create-address-comment {
      white-space: pre-line;
      text-align: center;
    }
    .txt-copy {
      word-break: break-all;
    }
    .history-wrap .line-box {
      padding: 0px 16px 20px;
    }
    .history-wrap .line-box li {
      margin-top: 12px;
      display: flex;
      align-items: center;
    }
    .history-wrap {
      min-height: 100%;
    }
    .middle-inner {
      height: 100%;
    }
  }
`;

export default TransactionPage;
