import { isPrivateKeyValid } from '../lib/Vadation';
import {
  AccountHistoryResBody,
  AccountInfo,
  AccountInfoResBody,
  BalanceHistory,
} from '../types/account';
import {
  getAccountHistory,
  getAccountInformation,
  getPubAccounts,
  isCheckTempUser,
} from '../service/account';
// @ts-ignore
import { toKeypair, getKeypairFromPrivateKey } from 'mitumc';
import { SetterOrUpdater } from 'recoil';
import { accountInfoUtil, setHistoryUtil } from './manufactureStore';
import { Network } from '../types/operation';

export const loginHandler =
  (
    privateKey: string,
    openModal: any,
    networkType: 'main' | 'test',
    setIsTempUser: SetterOrUpdater<{ isLogin: boolean; publicKey: string }>,
    setIsLogin: SetterOrUpdater<boolean>,
    router: any,
    networkState: Network,
    setAccountInfo: SetterOrUpdater<AccountInfo>,
    setHistory: SetterOrUpdater<BalanceHistory[]>
  ) =>
  async () => {
    if (!isPrivateKeyValid(privateKey)) {
      openModal();
      return;
    }
    let accountHistory: AccountHistoryResBody;
    let accountInfo: AccountInfoResBody;
    let pubkey;
    let accountInfoResult: { account: AccountInfo };

    try {
      pubkey = getKeypairFromPrivateKey(privateKey).getPublicKey();
      const checkTempLogin = await isCheckTempUser(pubkey, networkType);

      if (checkTempLogin._embedded === null) {
        setIsTempUser({
          isLogin: true,
          publicKey: `${pubkey}?threshold=100&weight=100`,
        });
        setIsLogin(true);
        router.push(`/wallet`);
        return;
      }
    } catch (err) {
      openModal();
      return;
    }

    try {
      const pubAccounts = await getPubAccounts(
        pubkey,
        networkState.networkPubAccounts,
        networkType
      );
      if (!pubAccounts._embedded) {
        openModal();
        return;
      }

      const pubResult = await pubAccounts._embedded.map((x) => {
        return x._embedded.address;
      });

      const account = await getAccountInformation(pubResult[0], networkType);
      accountHistory = await getAccountHistory(account._embedded.address, networkType);
      accountInfoResult = accountInfoUtil(account._embedded.address, privateKey, pubkey, account);
      const historyResult = setHistoryUtil(accountHistory, account._embedded.address);
      setAccountInfo(accountInfoResult!.account);

      setHistory(historyResult!);
      setIsLogin(true);

      router.push(`/wallet`);
    } catch (err) {
      openModal();
      return;
    }
  };
