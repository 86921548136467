import SideBar from '../../../components/layout/Sidebar';
import { useState } from 'react';
import ConfirmMultiSigModal from '../../../components/Modal/ConfirmMultiSig';
import useModal from '../../../hooks/useModal';
import { useIsLogin } from '../../../hooks/useIsLogis';
import { useMaintainHook } from '../../../hooks/useIsMaintainHook';
import { useTranslation } from 'react-i18next';
import { BasicDarkHoverBtn, BasicHoverBtn } from '../../../components/Button/BasicHover';
import { useIsMobile } from '../../../hooks/useIsMobile';
import Header from '../../../components/layout/Header';
import styled from 'styled-components';

const MultiSigPage = () => {
  const [visibleLogo, setVisibleLogo] = useState('');
  const { ModalPortal, openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  useIsLogin();
  useMaintainHook();

  return (
    <main>
      {isMobile && <Header />}
      <div id="contents">
        <SectionLayout className="create-address">
          <div className="cont-wrap">
            <div className="middle-inner">
              <div className="cont-box">
                {' '}
                {!isMobile && (
                  <SideBar
                    visible={visibleLogo}
                    setVisible={setVisibleLogo}
                    isLogin={true}
                    activeLogo={2}
                  />
                )}
                <div className="cont-body keypair key-main">
                  <strong className="tit">Multisig</strong>
                  <div>
                    <p>{t(`section_five_text_3`)}</p>
                    <p>{t(`section_six_text_1`)}</p>
                  </div>
                  <BasicHoverBtn
                    className="btn-type01"
                    onClick={openModal}
                    style={{ cursor: 'pointer' }}
                  >
                    {t(`info_text_20`)}
                  </BasicHoverBtn>
                </div>
              </div>
            </div>
          </div>
        </SectionLayout>
      </div>
      <ModalPortal>
        <ConfirmMultiSigModal closeModal={closeModal} />
      </ModalPortal>
    </main>
  );
};

export default MultiSigPage;

const SectionLayout = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    padding-top: 120px;
    align-items: flex-start;
    .cont-wrap {
      .cont-box {
        display: block;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: auto;
        padding: 0px 20px 32px;
      }
    }
    .tit {
      font-weight: 800;
      font-size: 18px;
      line-height: 20px;
      color: #23262f;
    }
    .keypair .tit + div {
      margin-top: 24px;
    }
    .keypair.key-main .btn-type01 {
      margin-top: 40px;
      width: 100%;
    }
  }
`;
