import { FC, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';

import LOGO from '../../../assets/images/logo.png';
import CLOSE_BTN from '../../../assets/images/btn_menu-close.png';
import NAV01 from '../../../assets/images/ico_nav01.png';
import NAV01_FILL from '../../../assets/images/ico_nav01-curr.png';
import NAV02 from '../../../assets/images/ico_nav02.png';
import NAV02_FILL from '../../../assets/images/ico_nav02-curr.png';
import NAV03 from '../../../assets/images/ico_nav03.png';
import NAV03_FILL from '../../../assets/images/ico_nav03-curr.png';
import NAV04 from '../../../assets/images/ico_nav04.png';
import NAV04_FILL from '../../../assets/images/ico_nav04-curr.png';
import NAV05 from '../../../assets/images/ico_nav05.png';
import NAV05_FILL from '../../../assets/images/ico_nav05-curr.png';

import NAV06 from '../../../assets/images/ico_nav06.png';
import NAV06_FILL from '../../../assets/images/ico_nav06-curr.png';

import SETTING from '../../../assets/images/ico_side-setting.png';
import LOGOUT from '../../../assets/images/ico_side-logout.png';
// import { ReactComponent as NEW_LOGO } from '../../../assets/images/test.svg';
import { ReactComponent as NEW_LOGO } from '../../../assets/images/protocon_logo.svg';

import { useRecoilValue } from 'recoil';
import { accountInfoAtom, isLoginAtom } from '../../../store/atoms';
import { routes } from '../../../constant';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const HeaderContainer = styled.div<{ click: boolean }>`
  .mobile-nav {
    right: ${(props) => (props.click ? '0px' : '-100%')};
    transition: all 0.25s;
  }

  @media (max-width: 670px) {
    header {
      display: block;
    }
    .header-inner {
      padding: 16px 0;
    }
    .mobile-nav {
      box-shadow: none;
      -webkit-box-shadow: none;
      -ms-box-shadow: none;
    }
  }
`;

const GnbList = styled.li<{ background: boolean; image: string }>`
  .gnb li.nav01 a {
    background: ${(props) => `url${props.image} 0 50% no-repeat`};
    background: red;
  }
`;

const Header: FC = ({ children }) => {
  const accountInfo = useRecoilValue(accountInfoAtom);
  const { t } = useTranslation();
  const location = useLocation();

  const { CREATE_MULTI_SIG_ADDRESS, SIGN_OPERATION_LANDING, TRANSACTION } = routes;
  const isTempLoginMobileGnbList = [
    {
      text: `${t(`info_text_1`)}`,
      className: 'nav01',
      redirect: '/wallet',
      image: NAV01,
      fillImage: NAV01_FILL,
    },
    // {
    //   text: `${t(`home_one_text_3`)}`,
    //   className: 'nav03',
    //   redirect: '/transaction',
    //   image: NAV03,
    //   fillImage: NAV03_FILL,
    // },
    {
      text: `${t(`info_text_4`)}`,
      className: 'nav05',
      redirect: '/setting',
      image: NAV05,
      fillImage: NAV05_FILL,
    },
    {
      text: `${t(`info_text_5`)}`,
      className: 'nav04',
      redirect: '/logout',
      image: NAV04,
      fillImage: NAV04_FILL,
    },
  ];

  const isLoginMobileGnbList = [
    isTempLoginMobileGnbList[0],
    {
      text: `${t(`info_text_3`)}`,
      className: 'nav03',
      redirect: TRANSACTION,
      image: NAV02,
      fillImage: NAV03_FILL,
    },
    {
      text: `${t(`MULTI_SIG_text`)}`,
      className: 'nav02',
      redirect:
        accountInfo.accountType === 'multi' ? SIGN_OPERATION_LANDING : CREATE_MULTI_SIG_ADDRESS,
      image: NAV02,
      fillImage: NAV03_FILL,
    },

    ...isTempLoginMobileGnbList.slice(1, isTempLoginMobileGnbList.length),
  ];

  const isNotLoginMobileGnbList = [
    {
      text: `${t(`info_text_46`)}`,
      className: 'nav01',
      redirect: '/login',
      image: NAV01,
      fillImage: NAV01_FILL,
    },
    {
      text: `${t(`home_one_text_3`)}`,
      className: 'nav06',
      redirect: '/create-address',
      image: NAV06,
      fillImage: NAV06_FILL,
    },
    {
      text: `${t(`info_text_4`)}`,
      className: 'nav05',
      redirect: '/setting',
      image: NAV05,
      fillImage: NAV05_FILL,
    },
  ];

  const [isVisibleBtn, setIsVisibleBtn] = useState(false);
  const [currentLangSelect, setCurrentLangSelect] = useState('ENG');

  const isLogin = useRecoilValue(isLoginAtom);

  const gnbList = isLogin ? isLoginMobileGnbList : isNotLoginMobileGnbList;

  const pathname = useLocation().pathname.split('/');

  const path = pathname[pathname.length - 1];

  const onClickHeaderHandler = (text: string) => () => {
    if (text === `${t(`info_text_5`)}`) {
      window.location.href = '/login';
      return;
    }
    setIsVisibleBtn((prevState) => !prevState);
  };

  return (
    <>
      <HeaderContainer click={isVisibleBtn}>
        {/*<header>*/}
        {/*  <div className="header-inner">*/}
        {/*    <h1>*/}
        {/*      <Link to="/" style={{ display: 'flex' }}>*/}
        {/*        /!*<img src={LOGO} alt="CRYPWORLD Wallet logo" />*!/*/}
        {/*        <NEW_LOGO />*/}
        {/*      </Link>*/}
        {/*    </h1>*/}
        {/*  </div>*/}
        {/*</header>*/}
        <h1 className="h-logo">
          <a href="#">logo</a>
        </h1>

        <button className="btn-menu" onClick={onClickHeaderHandler('')}>
          button
        </button>
        <div className="mobile-nav">
          <a href="#" className="close">
            <img src={CLOSE_BTN} alt="" onClick={onClickHeaderHandler('')} />
          </a>
          <div className="gnb-list" style={{ display: 'block' }}>
            <ul className="gnb">
              {gnbList.map((item, i) => {
                return (
                  <GnbList
                    background={path === item.redirect.slice(1, item.redirect.length)}
                    image={
                      path.length > 15
                        ? item.fillImage
                        : path === item.redirect.slice(1, item.redirect.length)
                        ? item.fillImage
                        : item.image
                    }
                    className={
                      path === item.redirect.slice(1, item.redirect.length)
                        ? classNames(item.className, 'on')
                        : classNames(item.className)
                    }
                    key={i}
                  >
                    <Link to={item.redirect} onClick={onClickHeaderHandler(item.text)}>
                      {item.text}
                    </Link>
                  </GnbList>
                );
              })}
            </ul>
          </div>
          <span className="lang">
            <a
              className="eng"
              style={{
                cursor: 'pointer',
                opacity: i18n.language === 'en' ? 1 : 0.4,
              }}
              onClick={() => {
                setCurrentLangSelect('ENG');
                i18n.changeLanguage('en');
              }}
            >
              ENG
            </a>
            <a
              className="kor"
              style={{
                cursor: 'pointer',
                opacity: i18n.language === 'ko' ? 1 : 0.4,
              }}
              onClick={() => {
                setCurrentLangSelect('KOR');
                i18n.changeLanguage('ko');
              }}
            >
              KOR
            </a>
          </span>
        </div>
      </HeaderContainer>
    </>
  );
};

export default Header;
