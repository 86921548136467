import { useEffect, useState, VFC } from 'react';
import styled from 'styled-components';
import COPY_IMG from '../../assets/images/ico_copy.png';
import Copy from 'copy-to-clipboard';
import { useRecoilValue } from 'recoil';
import { accountInfoAtom } from '../../store/atoms';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  closeModal: () => void;
  addressLength: number;
}

const SectionContainer = styled.section`
  .title {
    height: 14px;
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    color: #141416;

    display: flex;
    align-items: center;

    color: #000000;
  }
  .address-box {
    background: #f4f5f6;
    border-radius: 5px;
    padding: 10px 13px 13px 15px;
    box-sizing: border-box;
    margin: 11px 0;
    overflow-y: hidden;
    .sub-address-box {
      display: flex;
      align-items: flex-start;
    }
    .temp-address {
      padding-right: 12px;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      width: 380px;
      color: #777e90;
      word-break: break-all;
      display: flex;
      align-items: center;
    }
    img {
      width: 26px;
      height: 18px;
    }
  }
  .alert-wrapper {
    margin-bottom: 45px;
    margin-top: 32px;
    > .alert-comment {
      //width: 303px;
      height: 40px;

      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;

      color: #fe506a;
    }
  }
`;

const AddTempAddressModal: VFC<Props> = ({ closeModal, addressLength }) => {
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [tempPublicKey, setTempPublicKey] = useState('');
  const onCopy = () => {
    Copy(tempPublicKey);
    alert('copied');
  };

  const { t } = useTranslation();

  useEffect(() => {
    setTempPublicKey(`${accountInfo.publicKey}?threshold=${100 - addressLength}&weight=100`);
  }, []);

  return (
    <SectionContainer className="popup" style={{ display: 'block' }}>
      <div className="pop-wrap" style={{ width: '360px' }}>
        <div>
          <strong className="title">ADDRESS</strong>
        </div>

        <div className="address-box">
          <div className="sub-address-box">
            <p className="temp-address">{tempPublicKey}</p>
            <button type="button" onClick={onCopy}>
              <img src={COPY_IMG} alt="" />
            </button>
          </div>
        </div>

        <div className="alert-wrapper">
          <p className="alert-comment">
            <Trans i18nKey="section_seven_text_10" />
          </p>
        </div>
        <button type="button" className="btn-type01" onClick={closeModal}>
          {t(`Ok_Text`)}
        </button>
      </div>
    </SectionContainer>
  );
};

export default AddTempAddressModal;
