import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { isLoginAtom } from '../store/atoms';
import { useHistory } from 'react-router-dom';

export const useIsLogin = () => {
  const isLogin = useRecoilValue(isLoginAtom);
  const history = useHistory();
  useEffect(() => {
    if (!isLogin) {
      history.push('/login');
    }
  }, []);
};
