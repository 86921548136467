import { useRecoilValue } from 'recoil';
import { accountInfoAtom, operationAtom } from '../../../store/atoms';
import styled from 'styled-components';
import SideBar from '../../../components/layout/Sidebar';
import { useEffect, useState } from 'react';
import { checkSignOperation, convertCoinAmount } from '../../../utils/helper';
import useModal from '../../../hooks/useModal';
import ConfirmSignOperationModal from '../../../components/Modal/ConfirmSignOperaion';
import hint from '../../../text/hint.json';
import { useIsLogin } from '../../../hooks/useIsLogis';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../constant';
import { useTranslation } from 'react-i18next';
import { BasicHoverBtn } from '../../../components/Button/BasicHover';
import { useIsMobile } from '../../../hooks/useIsMobile';

const SignOperationTransactionPage = () => {
  const operation = useRecoilValue(operationAtom);
  const [visible, setVisible] = useState('');
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [_items, setItems] = useState<any>([]);
  const [_hash, setHash] = useState('');
  const { t } = useTranslation();
  const { SIGN_OPERATION_COMPLETE, SIGN_OPERATION_PROCESS, MULTI_LOADING } = routes;
  const isMobile = useIsMobile();

  const isMySignatureIncluded = () => {
    let isCheck = false;
    if (operation.json) {
      const { json } = operation;

      for (const f of json.fact_signs) {
        if (f.signer === accountInfo.publicKey) {
          isCheck = true;
          break;
        }
      }

      if (isCheck) {
        if (checkSignOperation(json.fact_signs, accountInfo.publicKeys)) {
          router.push(SIGN_OPERATION_COMPLETE);
          return;
        } else {
          router.push(SIGN_OPERATION_PROCESS);
          return;
        }
      } else {
        openModal();
      }
    }
  };

  const { ModalPortal, openModal, closeModal } = useModal();
  useIsLogin();
  const router = useHistory();
  if (!accountInfo.address) {
    router.push('/login');
  }

  useEffect(() => {
    if (operation.json) {
      const { download } = operation;
      const {
        json: {
          fact: { items },
        },
      } = operation;

      const {
        json: {
          fact: { hash },
        },
      } = operation;

      setItems(items);
      setHash(hash);
    }
  }, []);

  const goPage = () => {
    router.push(MULTI_LOADING);
  };

  return (
    <main>
      <div id="contents">
        <SectionLayout>
          <div className="cont-wrap transfer completed">
            <div className="middle-inner">
              <div className="cont-box">
                {!isMobile && (
                  <SideBar
                    visible={visible}
                    setVisible={setVisible}
                    isLogin={true}
                    activeLogo={2}
                  />
                )}
                <div className="cont-body">
                  <a onClick={goPage}>
                    <strong className={`tit ${isMobile ? 'back' : ''}`}>
                      Multisig <em> Sign Operation</em>
                    </strong>
                  </a>

                  <div className="transfer-wrap">
                    <strong className="guide-text">{t(`info_text_27`)}</strong>
                    <ul>
                      <li>
                        <span className="small-tit">Multisig Wallet Address</span>
                        <div className="text-line">{accountInfo.address}</div>
                      </li>
                      <li>
                        <span className="small-tit">Receiver’s Address</span>
                        <div className="text-line">
                          {_items.length > 0 && _items[0].receiver
                            ? _items[0].receiver
                            : _items.map((x: any) => {
                                if (x.keys) {
                                  return (
                                    x.keys.hash +
                                    '~' +
                                    hint.address +
                                    '-' +
                                    process.env.REACT_APP_VERSION
                                  );
                                }
                              })}
                        </div>
                      </li>
                      <li>
                        <span className="small-tit">Transfer List</span>
                        {_items.length > 0 &&
                          _items[0].amounts.map((item: any, i: number) => (
                            <div key={i} className="two-box">
                              <div className="text-line">{item.currency}</div>
                              <div className="text-line">{convertCoinAmount(item.amount)}</div>
                            </div>
                          ))}
                      </li>
                    </ul>
                    <div className="btn-area">
                      <BasicHoverBtn
                        onClick={isMySignatureIncluded}
                        className="btn-type01"
                        style={{ cursor: 'pointer' }}
                      >
                        {t(`info_text_41`)}
                      </BasicHoverBtn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalPortal>
            <ConfirmSignOperationModal closeModal={closeModal} />
          </ModalPortal>
        </SectionLayout>
      </div>
    </main>
  );
};

export default SignOperationTransactionPage;

const SectionLayout = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    padding-top: 12px;
    align-items: flex-start;

    .cont-wrap {
      .cont-box {
        display: block;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: auto;
        padding: 0px 20px 32px;
      }
    }
    .tit {
      font-weight: 800;
      font-size: 18px;
      line-height: 34px !important;
    }
    .completed h3 {
      margin-top: 32px;
      font-weight: 800;
      font-size: 32px;
      line-height: 44px;
      color: #3de0e0;
    }

    .transfer-wrap {
      margin-top: 28px;
    }
    .completed .two-box {
      display: flex;
      div:nth-child(1) {
        flex: 3;
        margin-bottom: 0px;
      }
      div:nth-child(2) {
        flex: 5;
        margin-bottom: 0px;
      }
    }
    .transfer-wrap li {
      margin-top: 28px;
    }
    .btn-area {
      border: none;
      padding-top: 0px;
      margin-top: 40px;
    }
    .btn-type01 {
      width: 100%;
    }
  }
`;
