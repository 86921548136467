import SideBar from '../../../components/layout/Sidebar';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  accountInfoAtom,
  networkTypeAtom,
  operationAtom,
  setResultAtom,
} from '../../../store/atoms';
import OPEN_NEW_IMG from '../../../assets/images/ico_open-new.png';
import COPY from 'copy-to-clipboard';
import {
  convertCoinAmount,
  getAccountType,
  IsItPossibleToSendToken,
  onSend,
} from '../../../utils/helper';
import CLEAR_IMG from '../../../assets/images/ico_checkmark.png';
import RED_IMG from '../../../assets/images/ico_clear-red.png';
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useIsLogin } from '../../../hooks/useIsLogis';
import { useMaintainHook } from '../../../hooks/useIsMaintainHook';
import hint from '../../../text/hint.json';
import { getAccountInformation } from '../../../service/account';
import { KeysChild } from '../../../types/account';
import { useTranslation } from 'react-i18next';
import { BasicHoverBtn } from '../../../components/Button/BasicHover';
import { useIsMobile } from '../../../hooks/useIsMobile';
import styled from 'styled-components';
import { routes } from '../../../constant';

const SignOperationCompletePage = () => {
  const [visible, setVisible] = useState('');
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [operation, setOperation] = useRecoilState(operationAtom);
  const [operationResultState, setOperationResultState] = useRecoilState(setResultAtom);
  const [signer, setSigner] = useState<Array<string>>([]);
  const router = useHistory();
  const [_items, setItems] = useState<any>([]);
  const [_hash, setHash] = useState('');
  const [keys, setKeys] = useState<KeysChild[]>([]);
  const networkType = useRecoilValue(networkTypeAtom);
  const [threshold, setThreshold] = useState(0);
  const { SIGN_OPERATION_PROCESS } = routes;
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const onCopy = (content: string) => () => {
    COPY(content);
    alert('copied');
  };

  const notify = (err: string) => () => toast.error(err);

  useEffect(() => {
    if (operation.json) {
      const signerResult: Array<string> = [];
      operation.json.fact_signs.forEach((s: any) => {
        signerResult.push(s.signer);
      });
      setSigner(signerResult);
    }
  }, []);

  useEffect(() => {
    if (operation.json) {
      const { download } = operation;
      const {
        json: {
          fact: { items },
        },
      } = operation;

      const {
        json: {
          fact: { hash },
        },
      } = operation;

      setItems(items);
      setHash(hash);
    }
    bootstrap();
  }, []);

  const bootstrap = async () => {
    if (operation.json) {
      const data = await getAccountInformation(operation.json.fact.sender, networkType);
      setKeys(data._embedded.keys.keys);
      setThreshold(data._embedded.keys.threshold);
    }
  };

  useIsLogin();
  useMaintainHook();
  const goPage = () => {
    router.push(SIGN_OPERATION_PROCESS);
  };
  return (
    <main>
      <div id="contents">
        <SectionLayout>
          <div className="cont-wrap transfer completed">
            <div className="middle-inner">
              <div className="cont-box">
                {!isMobile && (
                  <SideBar
                    visible={visible}
                    setVisible={setVisible}
                    isLogin={true}
                    activeLogo={2}
                  />
                )}

                <div className="cont-body">
                  <div className="nav">
                    <a onClick={goPage}>
                      <strong className={`tit ${isMobile ? 'back' : ''}`}>
                        Multisig <em>Sign Operation</em>
                      </strong>
                    </a>
                  </div>
                  <h3>Processing</h3>

                  <div className="transfer-wrap">
                    <ul>
                      <li>
                        <span className="small-tit">Fact Hash</span>
                        <div className="key-area">
                          <p>{operation.json && operation.json.hash}</p>
                          <button type="button" className="btn-box">
                            <a
                              href={`${process.env.REACT_APP_EXPLORER}/operation/${_hash}`}
                              target="_blank"
                            >
                              <img src={OPEN_NEW_IMG} alt="" />
                            </a>
                          </button>
                        </div>
                      </li>
                      <li>
                        <span className="small-tit">Receiver’s Address</span>
                        <div className="text-line">
                          {_items.length > 0 && _items[0].receiver
                            ? _items[0].receiver
                            : _items.length > 0 &&
                              _items.map(
                                (x: any) =>
                                  x.keys.hash +
                                  '~' +
                                  hint.address +
                                  '-' +
                                  process.env.REACT_APP_VERSION
                              )}
                        </div>
                      </li>
                      <li>
                        <span className="small-tit">Transfer Information</span>
                        {_items.length > 0 &&
                          _items[0].amounts.map((item: any, i: number) => (
                            <div key={i} className="two-box">
                              <div className="text-line">{item.currency}</div>
                              <div className="text-line">{convertCoinAmount(item.amount)}</div>
                            </div>
                          ))}
                      </li>
                      <li>
                        <span className="small-tit">Multi-Sign Information</span>
                        <div className="sign-info">
                          <span>Threshold : {threshold > 0 && threshold}</span>
                          <div className="multiSign-info">
                            <div>
                              <strong>Participants</strong>
                              <ul>
                                {accountInfo.publicKeys.map((p) => (
                                  <li key={p.key}>
                                    {p.key === accountInfo.publicKey ? 'Me' : 'Participant'}
                                  </li>
                                ))}
                              </ul>
                            </div>

                            <div>
                              <strong>Weight</strong>
                              <ul>
                                {keys.map((k) => (
                                  <li key={k.key}>{k.weight}</li>
                                ))}
                              </ul>
                            </div>
                            <div>
                              <strong>Signature</strong>
                              <ul>
                                {accountInfo.publicKeys.map((p) => (
                                  <li key={p.key}>
                                    <img
                                      src={signer.includes(p.key) ? CLEAR_IMG : RED_IMG}
                                      alt=""
                                    />
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div>
                      <div>
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#777E90',
                            paddingLeft: '3px',
                            marginTop: '9px',
                          }}
                        >
                          {t(`info_text_25`)}
                        </p>
                      </div>

                      <BasicHoverBtn
                        className="btn-type01"
                        style={{ marginTop: '27px' }}
                        onClick={() => {
                          onSend(
                            operation.json,
                            operation.operation,
                            setOperationResultState,
                            notify,
                            router
                          );
                        }}
                      >
                        <span>{t(`info_text_42`)}</span>
                      </BasicHoverBtn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SectionLayout>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastStyle={{ backgroundColor: '#000', color: '#fff', fontWeight: 'bold' }}
        />
      </div>
    </main>
  );
};

export default SignOperationCompletePage;

const SectionLayout = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .key-area p {
    min-height: 24px;
    height: auto;
  }

  @media (max-width: 1024px) {
    align-items: flex-start;
    .nav a {
      height: 64px;
      display: flex;
      align-items: center;
    }

    .cont-wrap {
      .cont-box {
        display: block;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: auto;
        padding: 0px 20px 32px;
      }
    }
    .tit {
      font-weight: 800;
      font-size: 18px;
      line-height: 34px !important;
    }
    .completed h3 {
      margin-top: 16px;
      font-weight: 800;
      font-size: 32px;
      line-height: 44px;
      color: #3de0e0;
    }
    .btn-type01 {
      width: 100%;
    }

    .completed .two-box {
      display: flex;
      margin-bottom: 8px;
      div:nth-child(1) {
        flex: 3;
        margin-bottom: 0px;
      }
      div:nth-child(2) {
        flex: 5;
        margin-bottom: 0px;
      }
    }
    .transfer-wrap .multiSign-info {
      display: flex;
      div {
        flex: 1;
      }
    }
  }
`;
