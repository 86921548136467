import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
// @ts-ignore
import { Generator } from 'mitumc';

import { accountInfoAtom, operationAtom } from '../../../store/atoms';
import useInput from '../../../hooks/useInput';
import {
  isAmountValid,
  isCurrencyValid,
  isDuplicate,
  isPublicKeyValid,
  isThresholdValid,
  isWeightsValidToThres,
  isWeightValid,
} from '../../../lib/Vadation';
import { OPER_CREATE_ACCOUNT } from '../../../constant';
import useModal from '../../../hooks/useModal';
import download from '../../../lib/Url';
import CreateAccountConfirmModal from '../../Modal/createAccount';
import { useHistory } from 'react-router-dom';

const CreateAccount = () => {
  const history = useHistory();
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [keys, setKeys] = useState<{ key: string; weight: string }[]>([]);
  const [amounts, setAmounts] = useState<{ currency: string; amount: string }[]>([]);
  const [publicKey, onChangePublicKey, setPublicKey] = useInput('');
  const [threshold, onChangeThreshold, setThreshold] = useInput('');
  const [weight, onChangeWeight, setWeight] = useInput('');
  const [currency, onChangeCurrency, setCurrency] = useInput('');
  const [amount, onChangeAmount, setAmount] = useInput('');
  const [operation, setOperation] = useRecoilState(operationAtom);
  const { ModalPortal, openModal, closeModal } = useModal();

  const onSubmit = async () => {
    console.log(keys, amounts);
    console.log(keys.map((x) => x.weight));
    if (
      !isWeightsValidToThres(
        keys.map((x) => x.weight),
        threshold
      )
    ) {
      alert(`작업을 생성할 수 없습니다 :(', '모든 weight들의 합은 threshold 이상이어야 합니다.`);
      return;
    }

    try {
      const generator = new Generator.currency(process.env.REACT_APP_NETWORK_ID);
      const generatorKeys = generator.createKeys(
        keys.map((k, i) => generator.formatKey(k.key, parseInt(k.weight))),
        parseInt(threshold)
      );

      console.log(generatorKeys, '1234');

      const generatorAmounts = generator.createAmounts(
        amounts.map((a) => generator.formatAmount(parseInt(a.amount), a.currency))
      );

      console.log(generatorAmounts, 'generate amounts', generatorAmounts[0]);

      const createAccountsFact = generator.createCreateAccountsFact(accountInfo.address, [
        generator.createCreateAccountsItem(generatorKeys, generatorAmounts),
      ]);
      const createAccounts = generator.createOperation(createAccountsFact, '');
      createAccounts.addSign(accountInfo.privateKey);

      const created = createAccounts.dict();
      console.log(created);

      setOperation({
        ...operation,
        json: created,
        operation: OPER_CREATE_ACCOUNT,
        download: download(created),
        filename: created.hash,
      });
      openModal();
    } catch (err) {
      console.log(err);
      alert(`작업을 생성할 수 없습니다 :(' '입력하신 작업 내용을 확인해 주세요.'`);
    }
  };

  const onAddAmount = () => {
    if (
      !isCurrencyValid(
        currency.trim(),
        accountInfo.balances.map((x) => x.currency)
      )
    ) {
      alert(`어마운트를 추가할 수 없습니다 :(', '잘못된 currency id입니다.`);
      return;
    }

    if (!isAmountValid(amount.trim())) {
      alert(`어마운트를 추가할 수 없습니다 :(', '잘못된 currency amount입니다.`);
      return;
    }

    if (
      isDuplicate(
        currency.trim(),
        amounts.map((x) => x.currency)
      )
    ) {
      alert(`어마운트를 추가할 수 없습니다 :(', '이미 리스트에 중복된 currency id가 존재합니다.`);
      return;
    }

    setAmounts((prevState) => [...prevState, { currency, amount }]);
    setAmount('');
    setCurrency('');
  };

  const onAdd = () => {
    if (!isThresholdValid(threshold.trim())) {
      alert(`키를 추가할 수 없습니다 :( 잘못된 public key입니다.`);
    }

    if (!isPublicKeyValid(publicKey.trim())) {
      alert(`키를 추가할 수 없습니다 :( 잘못된 public key입니다.`);
      return;
    }

    if (!isWeightValid(weight.trim())) {
      alert(`키를 추가할 수 없습니다 :( '잘못된 weight입니다.`);
      return;
    }

    if (
      isDuplicate(
        publicKey.trim(),
        keys.map((x) => x.key)
      )
    ) {
      alert(`키를 추가할 수 없습니다 :(', '이미 리스트에 중복된 키가 존재합니다.`);
      return;
    }

    setKeys((prevState) => [...prevState, { key: publicKey.trim(), weight: weight.trim() }]);
    setPublicKey('');
    setWeight('');
  };
  return (
    <div style={{ position: 'relative', top: '200px' }}>
      <div>
        <h2>Create Account</h2>
      </div>

      <div>
        <h3>Current Balance List</h3>
        <ul>
          {accountInfo.balances.map((b, i) => (
            <li key={i}>
              <span>{b.currency}</span>
              <span> </span>
              <span>{b.amount}</span>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h2>Keys Lists</h2>
        <ul>
          {keys.map((k, i) => (
            <li key={i}>
              <span>{k.key}</span>
              <span> </span>
              <span>{k.weight}</span>
            </li>
          ))}
        </ul>

        <div>
          <input type="text" placeholder="pubkey" value={publicKey} onChange={onChangePublicKey} />
          <input type="text" placeholder={'weight'} value={weight} onChange={onChangeWeight} />
          <input type="text" placeholder={'thres'} value={threshold} onChange={onChangeThreshold} />

          <button onClick={onAdd}>add</button>
        </div>

        <div>
          <h2>amount List</h2>
          <ul>
            {amounts.map((a, i) => (
              <li key={i}>
                <span>{a.currency}</span>
                <span> </span>
                <span>{a.amount}</span>
              </li>
            ))}
          </ul>

          <div>
            <input
              type="text"
              placeholder="currency"
              value={currency}
              onChange={onChangeCurrency}
            />
            <input type="text" placeholder="amount" value={amount} onChange={onChangeAmount} />
            <button onClick={onAddAmount}>add</button>
          </div>
        </div>

        <div>
          <button
            disabled={amounts.length < 1 || keys.length < 1 || threshold === ''}
            onClick={onSubmit}
          >
            CREATE
          </button>

          <button onClick={onSubmit}>open</button>
        </div>
      </div>
      <ModalPortal>{/*<CreateAccountConfirmModal isClose={closeModal} />*/}</ModalPortal>
    </div>
  );
};

export default CreateAccount;
