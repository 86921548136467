import { useEffect, useState } from 'react';
import SideBar from '../../../../components/layout/Sidebar';
import { OPER_TRANSFER, OPER_UPDATE_KEY, routes } from '../../../../constant';
import { useHistory } from 'react-router-dom';
import useInput from '../../../../hooks/useInput';
import styled from 'styled-components';
import DROPDOWN_IMG from '../../../../assets/images/ico_dropdown-combo.png';
// import Select from 'react-select';

import {
  isCurrencyValid,
  isDuplicate,
  isInLimit,
  isPublicKeyValid,
  isThresholdValid,
  isWeightsValidToThres,
  isWeightValid,
} from '../../../../lib/Vadation';
import ParticipantsBox from '../../../../components/ParticipantsBox';
import useModal from '../../../../hooks/useModal';
import CreateMultiSigConfirmationModal from '../../../../components/Modal/CreateMultisig';
import { compareCoin, convertCoinAmount } from '../../../../utils/helper';
import { useRecoilState, useRecoilValue } from 'recoil';
// @ts-ignore
import { Generator } from 'mitumc';
import { accountInfoAtom, operationAtom } from '../../../../store/atoms';
import download from '../../../../lib/Url';
import { useIsLogin } from '../../../../hooks/useIsLogis';
import { useMaintainHook } from '../../../../hooks/useIsMaintainHook';
import CLEAR_IMG from '../../../../assets/images/ico_clear.png';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { BasicHoverBtn, ButtonHover } from '../../../../components/Button/BasicHover';
import { useIsMobile } from '../../../../hooks/useIsMobile';

const CreateMultiSigPage = () => {
  const { MULTI_LOADING } = routes;
  const accountInfo = useRecoilValue(accountInfoAtom);
  const { t } = useTranslation();
  const [visibleLogo, setVisibleLogo] = useState('');
  const [thres, onChangeThres, setThres] = useInput('');
  const [weight, onChangeWeight, setWeight] = useInput('');
  const [publicKey, onChangePublicKey, setPublicKey] = useInput('');
  const [thresholdErrorMessage, setThresErrorMessage] = useState('');
  const [participants, setParticipants] = useState<ParticipantsProps[]>([]);
  const { ModalPortal, openModal, closeModal } = useModal();
  const [visibleText, setVisibleText] = useState('none');
  const [feeBalances, setFeeBalances] = useState<{ currency: string; amount: string }[]>([]);
  const [visibleFeeSelect, setVisibleFeeSelect] = useState('none');
  const [feeCurrentCoin, setFeeCurrentCoin] = useState('');
  const [operation, setOperation] = useRecoilState(operationAtom);
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
  const isMobile = useIsMobile();
  const router = useHistory();
  const { WALLET } = routes;
  const goPage = () => {
    router.push(WALLET);
  };

  const isDisable = participants.length && thres && feeCurrentCoin;

  const onAddKey = () => {
    const splitPublicKey = publicKey.split('?');
    const pubKey = splitPublicKey.length === 2 ? splitPublicKey[0] : publicKey;
    console.log(pubKey);
    if (!thres) {
      alert('threshold를 먼저 입력해주세요');
      return;
    }

    if (!isPublicKeyValid(pubKey.trim())) {
      alert(`키를 추가할 수 없습니다 :(', '잘못된 public key입니다.`);
      return;
    }

    if (!isWeightValid(weight.trim())) {
      alert(`키를 추가할 수 없습니다 :(', '잘못된 weight입니다.`);
      return;
    }

    if (
      isDuplicate(
        pubKey.trim(),
        participants.map((x) => x.key)
      )
    ) {
      alert(`키를 추가할 수 없습니다 :(', '이미 리스트에 중복된 키가 존재합니다.`);
      return;
    }

    if (
      !isInLimit(participants, parseInt(process.env.REACT_APP_LIMIT_KEYS_IN_KEYS as string) - 1)
    ) {
      alert(
        `키를 추가할 수 없습니다 :(', 키는 ${process.env.REACT_APP_LIMIT_KEYS_IN_KEYS}개까지 추가할 수 있습니다.`
      );
      return;
    }

    setParticipants((prevState) => [...prevState, { key: pubKey.trim(), weight }]);
    setPublicKey('');
    setWeight('');
  };

  const onMouseOver = () => {
    setVisibleFeeSelect('block');
  };

  const onMouseLeave = () => {
    setVisibleFeeSelect('none');
  };

  const onClickFeeSelectButton = () => {
    setVisibleFeeSelect(visibleFeeSelect === 'block' ? 'none' : 'block');
  };

  const validationThreshold = () => {
    if (thres && (parseInt(thres) < 0 || parseInt(thres) > 100)) {
      setThresErrorMessage('THRESHOLD 값은 0~100 사이로 설정해 주세요.');
      return;
    }
    setThresErrorMessage('');
  };

  const onChangeCurrentCoin = (coin: string) => {
    setFeeCurrentCoin(coin);
    setVisibleFeeSelect('none');
  };

  const onCreate = () => {
    if (!feeCurrentCoin) {
      alert('생성 수수료를 선택해주세요.');
      return;
    }
    if (
      !isCurrencyValid(
        feeCurrentCoin && feeCurrentCoin.trim(),
        feeBalances.map((x) => x.currency)
      )
    ) {
      alert("작업을 생성할 수 없습니다 :(', '잘못된 currency id입니다");
      return;
    }

    if (
      !isWeightsValidToThres(
        participants.map((x) => x.weight),
        thres.trim()
      )
    ) {
      alert("'작업을 생성할 수 없습니다 :(', '모든 weight들의 합은 threshold 이상이어야 합니다.'");
      return;
    }

    if (
      !isInLimit(participants, parseInt(process.env.REACT_APP_LIMIT_KEYS_IN_KEYS as string) - 1)
    ) {
      alert(
        `키를 추가할 수 없습니다 :(', 키는 ${process.env.REACT_APP_LIMIT_KEYS_IN_KEYS}개까지 추가할 수 있습니다.`
      );
      return;
    }

    try {
      const generator = new Generator(process.env.REACT_APP_NETWORK_ID).currency;
      // const account = this.props.account;
      const keys = generator.keys(
        participants.map((k, i) => generator.key(k.key, parseInt(k.weight))),
        parseInt(thres)
      );
      const keyUpdaterFact = generator.getKeyUpdaterFact(
        accountInfo.address,
        feeCurrentCoin && feeCurrentCoin.trim(),
        keys
      );
      const gn = new Generator(process.env.REACT_APP_NETWORK_ID);
      const keyUpdater = gn.getOperation(keyUpdaterFact, '');
      keyUpdater.addSign(accountInfo.privateKey);

      const created = keyUpdater.dict();

      console.log(created, 'created');

      setOperation({
        ...operation,
        json: created,
        operation: OPER_UPDATE_KEY,
        download: download(created),
        filename: created.hash,
      });
      openModal();
      // router.push(MULTI_LOADING);
    } catch (e) {
      console.log(e);
      alert(`작업을 생성할 수 없습니다 :(', '입력하신 작업 내용을 확인해 주세요.`);
    }
  };

  const onRemoveAmount = (cur: string) => {
    const filteredAmounts = participants.filter((amount) => {
      return amount.key !== cur;
    });
    setParticipants(filteredAmounts);
  };

  useEffect(() => {
    setPublicKey(accountInfo.publicKey);
    const balArr = [];
    const optionArr: { value: string; label: string }[] = [];
    for (const bal of accountInfo.balances) {
      if (compareCoin(bal.amount, '0')) {
        balArr.push({ currency: bal.currency, amount: bal.amount });
        optionArr.push({ value: bal.currency, label: bal.currency });
      }
    }
    setFeeBalances(balArr);
    for (const bal of accountInfo.balances) {
      if (compareCoin(bal.amount, '0')) {
        // setFeeCurrentCoin(bal.currency);
      }
    }
    setOptions(optionArr);
  }, []);
  useIsLogin();
  useMaintainHook();
  return (
    <main>
      <div id="contents">
        <SectionLayout>
          <div className="cont-wrap transfer">
            <div className="middle-inner">
              <div className="cont-box">
                {!isMobile && (
                  <SideBar
                    visible={visibleLogo}
                    setVisible={setVisibleLogo}
                    isLogin={true}
                    activeLogo={2}
                  />
                )}

                <div className="cont-body">
                  <a onClick={goPage}>
                    <strong className="tit back">{t(`info_text_20`)}</strong>
                  </a>
                  <div className="transfer-wrap multisig-wrap">
                    <fieldset>
                      <ul className="input-box">
                        <li>
                          <strong className="text-black">Threshold</strong>
                          <span>
                            <input
                              type="number"
                              value={thres}
                              onChange={onChangeThres}
                              onBlur={validationThreshold}
                              style={{ outline: 'none' }}
                            />
                          </span>
                          {thresholdErrorMessage ? (
                            <ErrorMessage>{thresholdErrorMessage}</ErrorMessage>
                          ) : null}
                        </li>
                        <li className="list table-list">
                          <strong className="text-black">Participants</strong>
                          <div className="table-th">
                            <label className="th01">Public Key</label>
                            <label className="th02">Weight</label>
                            <div style={{ width: '40px' }}></div>
                          </div>
                          <ul>
                            <li>
                              <span className="input01">
                                <input
                                  type="text"
                                  placeholder="퍼블릭키를 입력해주세요."
                                  value={publicKey}
                                  onChange={onChangePublicKey}
                                  style={{ outline: 'none' }}
                                />
                              </span>
                              <span className="input02">
                                <input
                                  type="text"
                                  value={weight}
                                  onChange={onChangeWeight}
                                  style={{ outline: 'none' }}
                                />
                              </span>
                              <div style={{ width: '40px' }}></div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </fieldset>

                    <ButtonHover type="button" className="btn-add" onClick={onAddKey}>
                      <span>Add</span>
                    </ButtonHover>
                    <div className="transfer-wrap multisig-wrap">
                      <fieldset>
                        <ul className="input-box">
                          <li className="list table-list">
                            <strong
                              className="text-black"
                              style={{ marginBottom: '0px', marginTop: '8px' }}
                            >
                              Participants List
                            </strong>
                            <div className="table-th">
                              <label className="th01">Public Key</label>
                              <label className="th02">Weight</label>
                              <div style={{ width: '40px' }}></div>
                            </div>

                            <ul>
                              {participants.map((p) => (
                                <ParticipantsBox
                                  key={p.key}
                                  publicKey={p.key}
                                  weight={p.weight}
                                  onRemoveAmount={() => onRemoveAmount(p.key)}
                                />
                              ))}
                            </ul>
                          </li>
                        </ul>
                      </fieldset>
                    </div>

                    {/*<strong className="fee">Service Fee : 00.00000000 CWC</strong>*/}
                  </div>

                  <div>
                    <div className="transfer-container">
                      <div className="transfer-wrap sub-transfer-wrap">
                        <fieldset>
                          <ul className="input-box">
                            <li>
                              <strong
                                className="text-black"
                                style={{
                                  display: 'block',
                                  marginBottom: '8px',
                                  fontWeight: 600,
                                  fontSize: '14px',
                                }}
                              >
                                {t(`section_seven_text_4`)}
                              </strong>
                              {/* react-select 디자인 적용이 어려워 직접 구현 (22.10.12 구민정)
                              <SelectContainer
                                className="select-coin"
                                onChange={(e: any) => {
                                  setFeeCurrentCoin(e.value);
                                }}
                                options={options}
                              /> */}
                              <div
                                style={{ position: 'relative' }}
                                onMouseLeave={onMouseLeave}
                                onMouseOver={onMouseOver}
                              >
                                <div className="fee-select-box">
                                  <button className="select-btn" onClick={onClickFeeSelectButton}>
                                    <div>
                                      {feeCurrentCoin == '' ? (
                                        <div style={{ color: '#B1B5C3' }}>Select</div>
                                      ) : (
                                        feeCurrentCoin
                                      )}
                                    </div>
                                    <button type="button" className="dropdown">
                                      <img src={DROPDOWN_IMG} alt="" style={{ maxWidth: 'none' }} />
                                    </button>
                                  </button>
                                </div>
                                <div style={{ height: '20px' }}>
                                  <div
                                    className="fee-dropdown"
                                    style={{ display: visibleFeeSelect }}
                                  >
                                    {options.map((option) => (
                                      <div
                                        className="dropdown-item"
                                        key={option.value}
                                        onClick={() => onChangeCurrentCoin(option.label)}
                                      >
                                        {option.label}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                  <BasicHoverBtn
                    onClick={onCreate}
                    className={classNames({ 'btn-type01': true, disabled: !isDisable })}
                    style={{ cursor: 'pointer' }}
                  >
                    {t(`info_text_20`)}
                  </BasicHoverBtn>
                  <ModalPortal>
                    <CreateMultiSigConfirmationModal
                      closeHandler={closeModal}
                      onClickHandler={onCreate}
                    />
                  </ModalPortal>
                </div>
              </div>
            </div>
          </div>
        </SectionLayout>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ backgroundColor: '#000', color: '#fff', fontWeight: 'bold' }}
      />
    </main>
  );
};

export default CreateMultiSigPage;

const SectionLayout = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .fee-select-box {
    box-sizing: border-box;
    padding: 8px 16px;
    width: 100%;
    border: 2px solid #e6e8ec;
    border-radius: 8px;
    .select-btn {
      width: 100%;
      font-weight: 800;
      font-size: 12px;
      color: #777e90;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }

  .fee-dropdown {
    top: 0px;
    margin-top: 52px;
    border: 2px solid #e6e8ec;
    border-radius: 8px;
    align-items: center;
    cursor: pointer;
    background-color: white;
    position: absolute;
    width: 100%;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    outline: 0px !important;
  }

  .dropdown-item {
    padding: 12px 16px;
    font-weight: 800;
    font-size: 12px;
    line-height: 12px;
    color: #141416;
    &:hover {
      background: #f5f5f5;
    }
  }

  .multisig-wrap {
    margin-top: 0px;
    .text-black {
      font-weight: 800;
    }
    /* 테이블 라벨 */
    .table-list .table-th {
      display: flex;
      justify-content: space-between;
      label {
        display: flex;
        font-weight: 800;
        margin-right: 0px;
      }
      label:nth-child(2) {
        width: 60px;
      }
    }
    /* 테이블 inputs */
    .table-list .input02 {
      width: 60px;
      padding-left: 4px;
      padding-right: 4px;
    }
    .table-list .input01 {
      flex: 1;
    }
  }

  .btn-type01.disabled {
    background-color: #ced4da;
  }
  .input02 input {
    text-align: center;
  }
  .input01 input:focus-visible,
  .input02 input:focus-visible {
    outline: none;
    border-width: 0px;
  }
  .react-select__control {
    background-color: red;
  }

  @media (max-width: 1024px) {
    padding-top: 12px;
    align-items: flex-start;

    .cont-wrap {
      .cont-box {
        display: block;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: auto;
        padding: 0px 20px 32px;
      }
    }
    .tit {
      font-weight: 800;
      font-size: 18px;
      line-height: 34px !important;
    }

    .transfer-wrap.sub-transfer-wrap {
      margin-top: 16px;
    }

    .btn-add {
      border-radius: 23px;
    }
    .btn-add span {
      font-weight: 700;
    }
    .btn-type01 {
      width: 100%;
      margin-top: 20px;
    }

    .select-coin {
      .input-box span {
      }
    }
  }
`;

// const SelectContainer = styled(Select)`
//   /* .input-box span {
//     background-color: red;
//   } */
//   > span {
//     display: none;
//   }
//   > div {
//     border: 2px solid #e6e8ec;
//     border-radius: 8px;
//     padding: 2px;
//   }
//   > div:hover {
//     background-color: white;
//   }
// `;

const ErrorMessage = styled.div`
  width: 286px;
  height: 18px;
  margin: 8px 98px 0 0;
  font-family: 'NanumSquareRound';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #ff5069;
`;

interface ParticipantsProps {
  key: string;
  weight: string;
}
