import SideBar from '../../../components/layout/Sidebar';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  accountInfoAtom,
  networkTypeAtom,
  operationAtom,
  setResultAtom,
} from '../../../store/atoms';
import { convertCoinAmount, IsItPossibleToSendToken, onSend } from '../../../utils/helper';
import CLEAR_IMG from '../../../assets/images/ico_checkmark.png';
import RED_IMG from '../../../assets/images/ico_clear-red.png';
import hint from '../../../text/hint.json';
import { useIsLogin } from '../../../hooks/useIsLogis';
import { useMaintainHook } from '../../../hooks/useIsMaintainHook';
import { getAccountInformation } from '../../../service/account';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { KeysChild } from '../../../types/account';
import { useTranslation } from 'react-i18next';
import { BasicDarkHoverBtn, BasicHoverBtn } from '../../../components/Button/BasicHover';
import { useIsMobile } from '../../../hooks/useIsMobile';
import styled from 'styled-components';
import { routes } from '../../../constant';

const SignOperationProcessPage = () => {
  const [visible, setVisible] = useState('');
  const accountInfo = useRecoilValue(accountInfoAtom);
  const operation = useRecoilValue(operationAtom);
  const [signer, setSigner] = useState<Array<string>>([]);
  const [_items, setItems] = useState<any>([]);
  const [_hash, setHash] = useState('');
  const networkType = useRecoilValue(networkTypeAtom);
  const [isVisibleBtn, setIsVisibleBtn] = useState(false);
  const [operationResultState, setOperationResultState] = useRecoilState(setResultAtom);
  const [keys, setKeys] = useState<KeysChild[]>([]);
  const [threshold, setThreshold] = useState(0);
  const isMobile = useIsMobile();
  const { SIGN_OPERATION_TRANSFER, WALLET } = routes;

  const notify = (err: string) => () => toast.error(err);
  const router = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    if (operation.json) {
      const signerResult: Array<string> = [];
      operation.json.fact_signs.forEach((s: any) => {
        signerResult.push(s.signer);
      });
      setSigner(signerResult);
    }
  }, []);

  useEffect(() => {
    if (operation.json) {
      const { download } = operation;
      const {
        json: {
          fact: { items },
        },
      } = operation;

      const {
        json: {
          fact: { hash },
        },
      } = operation;

      setItems(items);
      setHash(hash);
    }
  }, []);

  useEffect(() => {
    bootstrap();
  }, []);

  const bootstrap = async () => {
    if (operation.json) {
      const data = await getAccountInformation(operation.json.fact.sender, networkType);
      setKeys(data._embedded.keys.keys);
      setThreshold(data._embedded.keys.threshold);
      const isVisible = IsItPossibleToSendToken(
        data._embedded.keys.keys,
        operation.json.fact_signs,
        data._embedded.keys.threshold
      );

      setIsVisibleBtn(isVisible);
    }
  };

  const goPage = () => {
    router.push(SIGN_OPERATION_TRANSFER);
  };
  // useIsLogin();
  useMaintainHook();

  return (
    <main>
      <div id="contents">
        <SectionLayout>
          <div className="cont-wrap transfer completed">
            <div className="middle-inner">
              <div className="cont-box">
                {!isMobile && (
                  <SideBar
                    visible={visible}
                    setVisible={setVisible}
                    isLogin={true}
                    activeLogo={2}
                  />
                )}
                <div className="cont-body">
                  <a onClick={goPage}>
                    <strong className="tit back">
                      Multisig <em>Sign Operation</em>
                    </strong>
                  </a>
                  <h3>Processing</h3>
                  <div className="transfer-wrap">
                    <ul>
                      <li>
                        <span className="small-tit">Multisig Address</span>
                        <div className="text-line">{accountInfo.address}</div>
                      </li>
                      <li>
                        <span className="small-tit">Receiver’s Address</span>
                        <div className="text-line">
                          {_items.length > 0 && _items[0].receiver
                            ? _items[0].receiver
                            : _items.length > 0 &&
                              _items.map(
                                (x: any) =>
                                  x.keys.hash +
                                  '~' +
                                  hint.address +
                                  '-' +
                                  process.env.REACT_APP_VERSION
                              )}
                        </div>
                      </li>
                      <li>
                        <span className="small-tit">Transfer Information</span>
                        {_items.length > 0 &&
                          _items[0].amounts.map((item: any, i: number) => (
                            <div key={i} className="two-box">
                              <div className="text-line">{item.currency}</div>
                              <div className="text-line">{convertCoinAmount(item.amount)}</div>
                            </div>
                          ))}
                      </li>
                      <li>
                        <span className="small-tit">Multi-Sign Information</span>
                        <div className="sign-info">
                          <span>Threshold : {threshold > 0 && threshold}</span>
                          <div className="multiSign-info">
                            <div>
                              <strong>Participants</strong>
                              <ul>
                                {accountInfo.publicKeys.map((p) => (
                                  <li key={p.key}>
                                    {p.key === accountInfo.publicKey ? 'Me' : 'Participant'}
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div>
                              <strong>Weight</strong>
                              <ul>
                                {keys.map((k) => (
                                  <li key={k.key}>{k.weight}</li>
                                ))}
                              </ul>
                            </div>
                            <div>
                              <strong>Signature</strong>
                              <ul>
                                {accountInfo.publicKeys.map((p) => (
                                  <li key={p.key}>
                                    <img
                                      src={signer.includes(p.key) ? CLEAR_IMG : RED_IMG}
                                      alt=""
                                    />
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div>
                      <div>
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#777E90',
                            padding: '0 3px',
                            marginTop: '9px',
                          }}
                        >
                          {t(`info_text_26`)}
                        </p>
                      </div>

                      {isVisibleBtn && (
                        <div>
                          <p
                            style={{
                              fontSize: '12px',
                              color: '#777E90',
                              paddingLeft: '3px',
                              marginTop: '9px',
                            }}
                          >
                            {t(`info_text_25`)}
                          </p>
                        </div>
                      )}

                      <BasicHoverBtn
                        href={operation.download}
                        download={`${operation.filename}.json`}
                        target="_blank"
                        rel="noreferrer"
                        className="btn-type01"
                        style={{ marginTop: '40px' }}
                      >
                        <span className="ico-down">{t(`info_text_24`)}</span>
                      </BasicHoverBtn>

                      {isVisibleBtn ? (
                        <BasicHoverBtn
                          className="btn-type01"
                          style={{ marginTop: '40px' }}
                          onClick={() => {
                            onSend(
                              operation.json,
                              operation.operation,
                              setOperationResultState,
                              notify,
                              router
                            );
                          }}
                        >
                          <span>{t(`info_text_42`)}</span>
                        </BasicHoverBtn>
                      ) : (
                        <BasicHoverBtn
                          onClick={() => {
                            router.push(WALLET);
                          }}
                          className="btn-type01"
                          style={{ marginTop: '12px' }}
                        >
                          {t(`Ok_Text`)}
                        </BasicHoverBtn>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SectionLayout>
      </div>
    </main>
  );
};

export default SignOperationProcessPage;

const SectionLayout = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    padding-top: 12px;
    align-items: flex-start;

    .cont-wrap {
      .cont-box {
        display: block;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: auto;
        padding: 0px 20px 32px;
      }
    }
    .tit {
      font-weight: 800;
      font-size: 18px;
      line-height: 34px !important;
    }
    .completed h3 {
      margin-top: 32px;
      font-weight: 800;
      font-size: 32px;
      line-height: 44px;
      color: #3de0e0;
    }
    .btn-type01 {
      width: 100%;
    }

    .completed .two-box {
      display: flex;
      padding-bottom: 8px;
      div:nth-child(1) {
        flex: 3;
        margin-bottom: 0px;
      }
      div:nth-child(2) {
        flex: 5;
        margin-bottom: 0px;
      }
    }
    .transfer-wrap .multiSign-info {
      display: flex;
      div {
        flex: 1;
      }
    }
  }
`;
