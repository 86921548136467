import { Link } from 'react-router-dom';
import SIDE_LOGO from '../../assets/images/logo_side.png';
import SideBar from '../../components/layout/Sidebar';
import { useState } from 'react';
import { routes } from '../../constant';
import styled from 'styled-components';
import { useMaintainHook } from '../../hooks/useIsMaintainHook';
import { ArticleWrapper } from '../../styles/responsive';
import { useTranslation } from 'react-i18next';
import { BasicHoverLink, BasicHoverLinkText } from '../../components/Button/BasicHover';
import Header from '../../components/layout/Header';
import { useIsMobile } from '../../hooks/useIsMobile';

const CreateAddressPage = () => {
  const [visibleLogo, setVisibleLogo] = useState('');
  const { CREATE_MAIN_ADDRESS, CREATE_ADDRESS_ETHER } = routes;
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  useMaintainHook();
  return (
    <main>
      {isMobile && <Header />}

      <div id="contents">
        <ArticleWrapper className="res-body">
          <SectionLayout className="create-address">
            <div className="cont-wrap ">
              <div className="middle-inner">
                <div className="cont-box">
                  {!isMobile && (
                    <SideBar
                      visible={visibleLogo}
                      setVisible={setVisibleLogo}
                      isLogin={false}
                      activeLogo={1}
                    />
                  )}

                  <div className="cont-body keypair key-main create-address-main-tit">
                    <strong className="tit">{t(`home_one_text_3`)}</strong>
                    <div>
                      <p className="create-address-comment">{t(`section_two_text_1`)}</p>
                    </div>
                    <BasicHoverLink to={CREATE_MAIN_ADDRESS} className="btn-type01 wallet-open">
                      {t(`home_one_text_3`)}
                    </BasicHoverLink>
                    <BasicHoverLinkText
                      to={CREATE_ADDRESS_ETHER}
                      className="btn-key-go wallet-open"
                    >
                      {t(`section_two_text_2`)}
                    </BasicHoverLinkText>
                  </div>
                </div>
              </div>
            </div>
          </SectionLayout>
        </ArticleWrapper>
      </div>
    </main>
  );
};

export default CreateAddressPage;

const SectionLayout = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    .cont-wrap {
      .cont-box {
        display: block;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: auto;
        padding: 0px 20px 32px;
       
      }
    }
    .tit {
      color: #3de0e0;
      text-align: center;
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
    }

    .keypair {
      .tit + div {
        margin-top: 24px;
      }
      .tit + div p {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #777e90;
      }

      .btn-type01 {
        margin: 40px auto 0;
        width: auto;
        max-width: 600px;
      }
    }
    .create-address-comment {
      white-space: pre-line;
      text-align: center;
    } 
  }
`;
