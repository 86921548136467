import SideBar from '../../../components/layout/Sidebar';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { accountInfoAtom } from '../../../store/atoms';
import { Link } from 'react-router-dom';

const PersonalTermsPage = () => {
  const [isVisible, setIsVisible] = useState('');
  const accountInfo = useRecoilValue(accountInfoAtom);

  return (
    <main>
      <div id="contents">
        <article>
          <section>
            <div className="cont-wrap transfer">
              <div className="middle-inner">
                <div className="cont-box">
                  <SideBar
                    visible={isVisible}
                    setVisible={setIsVisible}
                    isLogin={accountInfo.address.length > 0}
                    activeLogo={1000}
                  />
                  <div className="cont-body">
                    <Link to="/login">
                      <strong className="tit">개인정보처리방침</strong>
                    </Link>
                    <div className="rules-wrap">
                      <div className="rules-box">
                        <strong className="rules-tit">
                          이 개인정보처리방침은 2022 년 1 월 1 일부터 효력이 발생합니다.
                        </strong>
                        <p>
                          본 개인정보처리방침에는 프로토콘(이하 “회사")의 서비스 이용자의 데이터
                          정보 처리에 대한 내용을 담고 있습니다. 본 개인정보처리방침은 Protocon
                          Wallet 서비스(이하 ‘서비스’)에 적용되며, 웹 사이트를 방문하거나 당사
                          서비스를 사용함으로써 귀하의 개인정보가 본 개인정보처리방침에 설명된 대로
                          처리된다는 데 동의합니다.
                        </p>
                        <p>
                          회사는 본 개인정보처리방침을 통하여 이용자의 데이터 정보가 어떠한 용도와
                          방식으로 처리가 되는지에 대하여 설명합니다. 회사는 개인정보처리방침에서
                          고지한 내용 외에는 정보를 수집하거나 이용, 공유, 저장하지 않습니다.
                        </p>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">개인정보 수집</strong>
                        <ul>
                          <li>
                            • 회사는 서비스를 통해 이용자의 개인정보를 수집하거나 이용하지 않습니다.
                          </li>
                          <li>
                            • 단,개인을식별할수없는쿠키및로그등의기타기술을통해서비스사용에대한
                            정보를 자동으로 수집할 수 있습니다. 이를 수집하는 목적은 서비스의 최적화
                            및 원활한 기능을 제공하기 위함입니다.
                          </li>
                          <li>
                            • 자세한 내용은 아래의 ‘구글 웹 로그 분석', ‘쿠키 정책'을 참고하십시오.
                          </li>
                        </ul>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">개인정보 공유</strong>
                        <p>회사는 개인정보를 이용하거나 제 3 자에게 제공하지 않습니다.</p>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">정보 보안</strong>
                        <ul>
                          <li>
                            • 서비스에서 설정한 정보는 이용자가 직접 보호하고 관리하여야 합니다.
                          </li>
                          <li>
                            • 회사는 이용자의 잘못된 관리로 정보가 변경되거나 손실된 경우에는 책임을
                            지지않습니다.
                          </li>
                        </ul>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">개인정보처리방침 변경</strong>
                        <ul>
                          <li>• 회사는 본 개인정보처리방침을 수시로 개정할 수 있습니다.</li>
                          <li>• 개정된 개인정보처리방침은 업데이트된 개정일과 함께 게시됩니다.</li>
                          <li>
                            • 개인정보처리방침의 큰 변경이 있는 경우에는 사전에 이용자에게
                            고지합니다.
                          </li>
                          <li>
                            • 개정이 유효하게 된 이후 서비스를 계속 사용하는 경우, 회사는 이용자가
                            변경된 사항을 읽고 이해하였다고 간주합니다.
                          </li>
                        </ul>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">문의하기</strong>
                        <ul>
                          <li>
                            • 본 개인정보처리방침에 대해 문의사항 또는 요청사항이 있으신 경우{' '}
                            <a href="mailto:support@protocon.io">support@Protocon.io</a> 로
                            연락주시기 바랍니다.
                          </li>
                          <li>
                            • 이용자의 메시지는 회사의 개인정보보호팀의 해당 담당자에게 전달됩니다.
                          </li>
                        </ul>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">구글 웹 로그 분석</strong>
                        <p>
                          회사는 이용자에게 더 나은 서비스 제공하기 위해 구글 웹 로그 분석 서비스를
                          사용할 수 있습니다.
                        </p>
                        <ul>
                          <li>• 서비스및품질개선을위해로그분석이사용됩니다.</li>
                          <li>• 통계작성및학술연구등의목적으로사용될수있습니다.</li>
                          <li>• 다른 구글 데이터와 병합 또는 연결시키지 않습니다.</li>
                          <li>
                            • 이용자는{' '}
                            <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">
                              Google 웹 로그 분석 차단 브라우저 부가 기능
                            </a>
                            을 설치하여 Google 웹 로그 분석 쿠키 사용에 관한 선택을 할 수 있습니다.
                          </li>
                        </ul>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">쿠키 정책</strong>
                        <p>본 정책에서는 회사의 쿠키 정책에 대한 자세한 정보를 제공합니다.</p>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">쿠키란 무엇인가요?</strong>
                        <p>
                          쿠키란 이용자의 컴퓨터 또는 모바일 기기로 보내는 작은 텍스트 파일입니다.
                          일반적으로 쿠키는 이용자의 기기를 식별하거나 서비스 및 웹사이트의 접속을
                          관리하는 데 사용되며, 다른 기기에서 귀하의 이용자의 계정에 대한 접근
                          시도를 탐지하는 데 이용될 수도 있습니다. 또한 쿠키는 서비스의 콘텐츠를
                          쉽게 공유하고, 개인화된 광고 등 맞춤형 정보를 제공하기 위해 활용되기도
                          합니다.
                        </p>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">서비스에서 쿠키를 사용하나요?</strong>
                        <p>
                          네. 회사는 세션 쿠키와 영구 쿠키를 모두 사용합니다. 마케팅 협력 업체,
                          계열사, 분석 또는 서비스 제공자는 최적의 서비스를 제공하기 위해서 쿠키, 웹
                          비콘, 픽셀 또는 기타 기술을 사용합니다. 쿠키로 수집된 정보와 본 쿠키
                          정책에 준하여 수집된 기타 정보는 서로 결합되어 사용될 수도 있습니다.
                        </p>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">
                          서비스에서 쿠키를 사용하는 이유는 무엇인가요?
                        </strong>
                        <p>회사는 다음과 같은 여러 목적으로 쿠키를 사용합니다.</p>
                        <ul>
                          <li>
                            • 인증 : 회사의 서비스에 로그인하면 접속 시간을 확인함으로써 서비스의
                            접속을 관리하고 개인화된 경험과 기능을 제공하기 위해 쿠키를 사용합니다.
                          </li>
                          <li>
                            • 보안 : 회사는 쿠키를 사용하여 보안 기능을 활성화하고 악의적인 활동을
                            탐지하여 서비스를 안전하게 관리하기 위해 쿠키를 사용합니다.
                          </li>
                          <li>
                            • 성능 : 서비스를 개선하고 방문 패턴에 대한 이해를 도모하고자 쿠키를
                            사용합니다.
                          </li>
                          <li>
                            • 광고 & 마케팅 : 제 3자 소셜 미디어 웹사이트를 결합하고, 개인에
                            최적화된 맞춤형 광고를 제공하기 위해 쿠키를 사용합니다.
                          </li>
                          <li>
                            • 성능, 분석 및 연구 : 쿠키를 사용하여 서비스의 성과를 파악할 수
                            있습니다. 귀하의 행동 패턴과 조회 활동을 다수의 웹사이트 및 플랫폼에서
                            장기적으로 관찰함으로써 서비스의 방문자에 대해 더 잘 이해하고 이를
                            바탕으로 서비스를 개선시키기 위해 쿠키를 사용합니다.
                          </li>
                        </ul>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">
                          쿠키를 수집하지 못하게 거부하고 싶다면 어떻게 하면 되나요?
                        </strong>
                        <p>
                          이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 웹 브라우저의 옵션
                          설정을 통해 쿠키를 허용하거나, 쿠키가 젇장될 때마다 확인을 거치거나, 모든
                          쿠키의 저장을 거부할 수 있습니다.
                        </p>
                        <p>브라우저 별 자세한 쿠키 설정 방법은 아래를 참고하시기 바랍니다.</p>
                        <ul>
                          <li>• Chrome: “비밀 모드”</li>
                          <li>• Internet Explorer: “보호 모드”</li>
                          <li>• Safari: “사생활 보호 기능”</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </article>
      </div>
    </main>
  );
};

export default PersonalTermsPage;
