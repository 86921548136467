import styled from 'styled-components';

export const ArticleWrapper = styled.article`
  //box-shadow: none;
  //.key-area {
  //  display: flex;
  //  padding-right: 27px;
  //  > button {
  //    height: 30px;
  //  }
  //  > p {
  //    overflow-y: hidden;
  //    width: 100%;
  //  }
  //}
  //@media (max-width: 670px) {
  //  .cont-box {
  //    width: 335px;
  //    justify-content: center;
  //    border: none;
  //  }
  //  .cont-body {
  //    padding: 0 0 0 15px;
  //  }
  //  .priv-textarea {
  //    max-width: 160px;
  //  }
  //  .sub-box {
  //    width: 460px;
  //  }
  //  .sub-body {
  //    padding: 0;
  //    width: 460px;
  //  }
  //
  //  .priv-ether-textarea {
  //    max-width: 150px;
  //  }
  //  .side {
  //    display: none;
  //  }
  //
  //  .comment {
  //    display: flex;
  //    justify-content: center;
  //    font-size: 0.82rem;
  //  }
  //
  //  .wallet-open {
  //    width: 15.5rem;
  //    font-size: 13px;
  //  }
  //
  //  .keypair-comment {
  //    font-size: 12px;
  //  }
  //  input {
  //    font-size: 11px;
  //  }
  //
  //  .wallet-open {
  //    width: 100%;
  //  }
  //
  //  .btn-box {
  //    right: -22px;
  //  }
  //
  //  .comment {
  //    //width: 135px;
  //    max-width: 200px;
  //    margin-right: 20px;
  //  }
  //
  //  .tit {
  //    width: 135px;
  //  }
  //
  //  .setting-item {
  //    display: block;
  //    .radio-box {
  //      max-width: 150px;
  //      margin-top: 20px;
  //    }
  //    .timer-box {
  //      max-width: 200px;
  //    }
  //  }
  //  .balance-item {
  //    display: block;
  //    //max-width: 200px;
  //    //width: 180px;
  //    //height: 65px;
  //  }
  //  .history-wrapper {
  //    width: 350px;
  //    padding: 0;
  //    padding-left: 10px;
  //  }
  //  .res-tit {
  //    font-size: 16px;
  //  }
  //  .res-header {
  //    font-size: 26px;
  //    margin-right: 95px;
  //  }
  //  .two-box {
  //    display: flex;
  //  }
  //  //.sub-transfer-wrap {
  //  //  width: 250px;
  //  //}
  //  .transfer-container {
  //    //width: 250px;
  //    //display: flex;
  //    //margin-right: 20px;
  //    //justify-content: center;
  //    //position: relative;
  //  }
  //  .transfer-back {
  //    position: relative;
  //  }
  //  .transfer-btn {
  //    font-size: 12px;
  //  }
  //
  //  .amount {
  //    em {
  //      font-size: 11px;
  //    }
  //  }
  //  .transfer-submit-btn {
  //    width: 350px;
  //    //position: relative;
  //    //right: 20px;
  //  }
  //  .close-img {
  //    position: relative;
  //    left: 8px;
  //  }
  //  .transfer-wrap {
  //    width: 350px;
  //    padding-left: 10px;
  //  }
  //  .history-box {
  //    display: block;
  //    padding: 0;
  //  }
  //
  //  .create-address-main-tit {
  //    margin: 0;
  //    padding: 0;
  //    width: 100%;
  //  }
  //  .create-address-box {
  //    margin: 0;
  //    padding: 20px;
  //    display: block;
  //  }
  //  .create-address-comment {
  //    max-width: none;
  //    width: 100%;
  //  }
  //  .login-comment {
  //    font-size: 17px;
  //  }
  //  .login-wrap {
  //    position: relative;
  //    top: -80px;
  //  }
  //  .first-visit {
  //    margin-top: 50px;
  //  }
  //}
`;
