import { useEffect, useState, VFC } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { accountInfoAtom, historyAtom, networkTypeAtom } from '../../store/atoms';
import { changeAccountType, getAccountType } from '../../utils/helper';
import { getAccountInformation } from '../../service/account';

interface Props {
  address: string;
  visibleText: string;
}

const AccountLists: VFC<Props> = ({ address, visibleText }) => {
  // const accountInfo = useRecoilValue(accountInfoAtom);
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [history, setHistory] = useRecoilState(historyAtom);
  const networkType = useRecoilValue(networkTypeAtom);
  const [type, setType] = useState('');
  const [participants, setParticipants] = useState(0);

  const onClickAddress = (addr: string) => () => {
    changeAccountType(
      setHistory,
      addr,
      networkType,
      accountInfo.publicKey,
      accountInfo.privateKey,
      accountInfo,
      setAccountInfo
    );
  };

  const getParticipants = async (address: string) => {
    const result = await getAccountInformation(address, networkType);
    return result._embedded.keys.keys.length;
  };

  useEffect(() => {
    bootstrap();
  }, [visibleText]);

  const bootstrap = async () => {
    const result = await getAccountType(
      address,
      accountInfo.publicKey,
      accountInfo.privateKey,
      networkType
    );
    console.log(type, address, 'asdasdasd');
    setType(result);
    if (result === 'multi') {
      await getParticipants(address).then(setParticipants);
    }
  };

  return (
    <li style={{ cursor: 'pointer' }} onClick={onClickAddress(address)}>
      <a>
        <strong>
          Address{' '}
          <em>
            {type} {type === 'multi' && <i>{participants} participants</i>}
          </em>
        </strong>

        <p>{address}</p>
      </a>
    </li>
  );
};

export default AccountLists;
