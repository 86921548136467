import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useEffect, useState, Suspense } from 'react';
import { RecoilRoot, useRecoilState, useRecoilValue } from 'recoil';
import { toKeypair } from 'mitumc';
import { useHistory, useLocation } from 'react-router-dom';
import { PublicRouter } from './common/router';
import Header from './components/layout/Header';
import './App.css';
import './responsive.css';
import { excludeHeaderPage } from './constant';
import IdleTimer from './lib/IdleTimer';
import {
  accountInfoAtom,
  maintainInfoAtom,
  networkTypeAtom,
  settingTimerAtom,
} from './store/atoms';
import IdleTimerContainer from './components/IdleTimerContainer';
import styled from 'styled-components';
import React from 'react';
import { Offline, Online } from 'react-detect-offline';
import useModal from './hooks/useModal';
import NetworkErrorModal from './components/Modal/NetworkError';
import { checkMaintainInfo, getAccountHistory } from './service/account';
import { setHistoryUtil } from './utils/manufactureStore';

function App() {
  const [isTimeout, setIsTimeout] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  const localstorageExpiredTime = parseInt(localStorage.getItem('_expiredTime'), 10);

  const history = useHistory();

  const { ModalPortal, openModal, closeModal } = useModal();

  const logoutTimer = localstorageExpiredTime
    ? localstorageExpiredTime
    : parseInt(process.env.REACT_APP_LOGOUT_TIMER, 10);
  let timer;

  const location = useLocation();
  const excludeHeader = excludeHeaderPage.includes(location.pathname);

  const onLogout = () => {
    alert('자동 잠금 시간이 만료되었습니다.');
    window.location.href = '/login';
  };
  console.log(excludeHeader, 'header hjeaheahje');
  return (
    <RecoilRoot>
      {!excludeHeader ? (
        <>
          {location.pathname === '/login' && <Header />}
          <IdleTimerContainer>
            <PublicRouter />
          </IdleTimerContainer>
        </>
      ) : (
        <IdleTimerContainer>
          <PublicRouter />
        </IdleTimerContainer>
      )}

      <div id="modal" />
      <Online>
        <ModalPortal>
          <NetworkErrorModal closeModal={closeModal} />
        </ModalPortal>
      </Online>
    </RecoilRoot>
  );
}

export default App;
