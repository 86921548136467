import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
// @ts-ignore
import { toKeypair, getKeypairFromPrivateKey } from 'mitumc';

import {
  accountInfoAtom,
  historyAtom,
  isLoginAtom,
  isTempLoginAtoms,
  maintainInfoAtom,
  networkAtom,
  networkTypeAtom,
} from '../../store/atoms';
import { routes } from '../../constant';

// import { ReactComponent as LOGO } from '../../assets/images/test.svg';
import { ReactComponent as NEW_LOGO } from '../../assets/images/protocon_logo.svg';
import { ReactComponent as MainWallet } from '../../assets/images/test2.svg';
import MAIN_WALLET from '../../assets/images/img_main-wallet.png';
import LOCK_IMG from '../../assets/images/ico_lock.png';
import EYE_IMG from '../../assets/images/ico_eye.png';
import useInput from '../../hooks/useInput';
import { isPrivateKeyValid } from '../../lib/Vadation';
import useModal from '../../hooks/useModal';
import LoginErrorModal from '../../components/Modal/LoginError';
import NetworkErrorModal from '../../components/Modal/NetworkError';
import { AccountHistoryResBody, AccountInfo, AccountInfoResBody } from '../../types/account';
import {
  getAccountHistory,
  getAccountInformation,
  getGenesisAccountInfo,
  getPubAccounts,
  isCheckTempUser,
} from '../../service/account';
import { accountInfoUtil, setHistoryUtil } from '../../utils/manufactureStore';
import styled from 'styled-components';
import { useMaintainHook } from '../../hooks/useIsMaintainHook';
import { convertCoinAmount } from '../../utils/helper';
import { Trans, useTranslation } from 'react-i18next';

const ArticleWrapper = styled.article`
  padding: 0;
  .side-img {
    display: flex;
    align-items: stretch;
  }
  .main-info {
    padding-top: 150px;
  }
  .key-area {
    //> button {
    //  display: flex;
    //  align-items: center;
    //}
  }

  @media (max-width: 920px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .main-info {
      padding-top: 0;
    }
    .side-img {
      display: none;
    }
  }
  @media (max-width: 670px) {
    display: flex;
    justify-content: center;
    align-items: center;

    .input-box {
      width: 21.875rem;
    }

    .main-info {
      padding-top: 0;
    }

    .btn-type01 {
      width: 21.875rem;
    }
    .side-img {
      display: none;
    }
  }
`;

const HomePage = () => {
  // const networkStore = useRecoilValue(networkAtom);
  const router = useHistory();
  const { t } = useTranslation();

  const { CREATE_ADDRESS } = routes;
  const networkState = useRecoilValue(networkAtom);
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [history, setHistory] = useRecoilState(historyAtom);
  const [isTempUser, setIsTempUser] = useRecoilState(isTempLoginAtoms);
  const networkType = useRecoilValue(networkTypeAtom);

  const [maintainInfo, setMaintainInfo] = useRecoilState(maintainInfoAtom);

  const {
    maintain,
    msg: { ko },
  } = maintainInfo;

  const [privateInput, onChangePrivateInput, setPrivateInput] = useInput('');
  const [inputType, setInputType] = useState('password');
  const { ModalPortal, openModal, closeModal } = useModal();
  const {
    ModalPortal: LoginPortal,
    openModal: loginOpenModal,
    closeModal: loginCloseModal,
  } = useModal();

  const onChangeInputType = () => {
    setInputType((prevState) => {
      if (prevState === 'password') {
        return 'text';
      }
      return 'password';
    });
  };

  const onLogin = async () => {
    if (privateInput === '') {
      openModal();
      return;
    }
    const privateKey = privateInput.trim();

    if (!isPrivateKeyValid(privateKey)) {
      openModal();
      return;
    }

    let accountHistory: AccountHistoryResBody;
    let accountInfo: AccountInfoResBody;
    let pubkey;
    let accountInfoResult: { account: AccountInfo };

    try {
      pubkey = getKeypairFromPrivateKey(privateKey).getPublicKey();
      const checkTempLogin = await isCheckTempUser(pubkey, networkType);

      if (checkTempLogin._embedded === null) {
        setIsTempUser({
          isLogin: true,
          publicKey: `${pubkey}?threshold=100&weight=100`,
        });
        setIsLogin(true);
        router.push(`/wallet`);
        return;
      }
    } catch (err) {
      openModal();
      return;
    }

    try {
      const pubAccounts = await getPubAccounts(
        pubkey,
        networkState.networkPubAccounts,
        networkType
      );

      if (!pubAccounts._embedded) {
        openModal();
        return;
      }

      const pubResult = await pubAccounts._embedded.map((x) => {
        return x._embedded.address;
      });

      const account = await getAccountInformation(pubResult[0], networkType);
      accountHistory = await getAccountHistory(account._embedded.address, networkType);
      accountInfoResult = accountInfoUtil(account._embedded.address, privateKey, pubkey, account);

      const historyResult = setHistoryUtil(accountHistory, account._embedded.address);
      const { account: accountInfo } = accountInfoResult!;

      // setAccountInfo(accountInfoResult!.account);
      setAccountInfo({
        address: accountInfo.address,
        accountType: accountInfo.accountType,
        balances: accountInfo.balances,
        privateKey: accountInfo.privateKey,
        publicKey: pubkey,
        publicKeys: accountInfo.publicKeys,
        // restoreKey: accountInfo.restoreKey
      });

      setHistory(historyResult!);
      setIsLogin(true);

      router.push(`/wallet`);
    } catch (err) {
      try {
        if (err.response.status === 404) {
          const pubAccounts = await getPubAccounts(
            pubkey,
            networkState.networkPubAccounts,
            networkType
          );

          if (!pubAccounts._embedded) {
            openModal();
            return;
          }

          const pubResult = await pubAccounts._embedded.map((x) => {
            return x._embedded.address;
          });

          const account = await getGenesisAccountInfo(networkType, pubResult[0]);
          // accountHistory = await getAccountHistory(account._embedded.address, networkType);
          accountInfoResult = accountInfoUtil(
            account._embedded.address,
            privateKey,
            pubkey,
            account
          );

          // const historyResult = setHistoryUtil(accountHistory, account._embedded.address);
          const { account: accountInfo } = accountInfoResult!;

          // setAccountInfo(accountInfoResult!.account);
          setAccountInfo({
            address: accountInfo.address,
            accountType: accountInfo.accountType,
            balances: accountInfo.balances,
            privateKey: accountInfo.privateKey,
            publicKey: pubkey,
            publicKeys: accountInfo.publicKeys,
            // restoreKey: accountInfo.restoreKey
          });

          // setHistory(historyResult!);
          setIsLogin(true);
          router.push(`/wallet`);
          return;
        }
      } catch (err) {
        openModal();
        return;
      }
      openModal();
    }
  };

  useEffect(() => {
    router.replace('/login');
    return;

    if (isTempUser.isLogin) {
      router.push(`/wallet`);
    }
    if (accountInfo.address) {
      router.push(`/wallet`);
    }
  }, []);

  useMaintainHook();

  return (
    <div>
      <main className="nav-open">
        <div id="contents" className="dim" style={{ minHeight: '0px' }}>
          <ArticleWrapper>
            <section className="main">
              <div className="side-img">
                <span>
                  <NEW_LOGO />
                  {/*<img src={LOGO} alt="CRYPWORLD Wallet logo" />*/}
                </span>
                <figure style={{ top: '15%' }}>
                  <img src={MAIN_WALLET} alt="" />
                </figure>
              </div>
              <div
                className="main-info"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div className="middle-inner">
                  <strong>{t(`home_one_text_1`)}</strong>
                  <p>
                    <Trans i18nKey="home_one_text_2" />
                  </p>
                  {!maintain ? (
                    <div className="input-box">
                      <span className="input-password">
                        <input
                          type={inputType}
                          placeholder={t(`info_text_2`)}
                          style={{ outline: 'none' }}
                          value={privateInput}
                          onChange={onChangePrivateInput}
                        />
                        {inputType === 'password' ? (
                          <i className="ico-input ico-lock" onClick={onChangeInputType}>
                            <img src={LOCK_IMG} alt="" />
                          </i>
                        ) : (
                          <button
                            type="button"
                            className="ico-input ico-eye"
                            style={{ display: 'block' }}
                            onClick={onChangeInputType}
                          >
                            <img src={EYE_IMG} alt="" />
                          </button>
                        )}
                      </span>
                    </div>
                  ) : (
                    <div style={{ marginTop: '30px' }}>
                      <div dangerouslySetInnerHTML={{ __html: ko }} />
                    </div>
                  )}
                  {!maintain ? (
                    <span className="btn-type01" onClick={onLogin}>
                      {t(`info_text_1`)}
                    </span>
                  ) : null}
                  {!maintain ? (
                    <span className="first-visit">
                      {t(`home_one_text_4`)} <Link to={CREATE_ADDRESS}>{t(`home_one_text_3`)}</Link>
                    </span>
                  ) : null}
                </div>
              </div>
            </section>
          </ArticleWrapper>
        </div>

        <ModalPortal>
          <LoginErrorModal closeModal={closeModal} />
        </ModalPortal>

        <LoginPortal>
          <NetworkErrorModal closeModal={loginCloseModal} />
        </LoginPortal>
      </main>
    </div>
  );
};

export default HomePage;
