import { useRecoilState, useRecoilValue } from 'recoil';
import { isLoginAtom, maintainInfoAtom } from '../store/atoms';
import { useHistory, useLocation } from 'react-router-dom';
import { checkMaintainInfo } from '../service/account';
import { useEffect } from 'react';

export const useMaintainHook = () => {
  const [maintainInfo, setMaintainInfo] = useRecoilState(maintainInfoAtom);
  const isLogin = useRecoilValue(isLoginAtom);
  const { maintain } = maintainInfo;
  const history = useHistory();
  const location = useLocation().pathname;
  let timer: any;
  useEffect(() => {
    const path = history.location.pathname.split('/');
    if (path.includes('transfer')) {
      return;
    }
    timer = setInterval(() => {
      bootstrap();
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const bootstrap = async () => {
    const result = await checkMaintainInfo();
    const curr = new Date().valueOf();
    const isMaintainTime =
      curr <= new Date(result.end_time).valueOf() && new Date(result.start_time).valueOf()
        ? true
        : false;

    setMaintainInfo({
      start: new Date(result.start_time).valueOf(),
      end: new Date(result.end_time).valueOf(),
      maintain: isMaintainTime,
      msg: {
        ko: result.message.ko,
        en: result.message.en,
      },
    });
    if (isMaintainTime && isLogin) {
      window.location.href = '/';
    }

    if (isMaintainTime && !isLogin) {
      history.push('/');
    }
  };

  useEffect(() => {
    if (maintain && location !== '/') {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    bootstrap();
  }, []);
};
