import { atom, atomFamily } from 'recoil';
import { AccountInfo, BalanceHistory, SignOperation } from '../types/account';
import { Network, Operation, OperationResult } from '../types/operation';

export const accountInfoAtom = atom<AccountInfo>({
  key: 'accountInfo',
  default: {
    address: '',
    accountType: '',
    privateKey: '',
    publicKey: '',
    balances: [],
    publicKeys: [],
    // restoreKey: undefined || '',
  },
});

export const isLoginAtom = atom<boolean>({
  key: 'isLogin',
  default: false,
});

export const settingTimerAtom = atom<number>({
  key: 'settingTimer',
  default: parseInt(process.env.REACT_APP_LOGOUT_TIMER as string, 10),
});

export const historyAtom = atom<BalanceHistory[]>({
  key: 'balanceHistory',
  default: [],
});

export const operationAtom = atom<Operation>({
  key: 'setOperation',
  default: {
    download: null,
    filename: '',
    operation: '',
    json: null,
  },
});

export const currentOperationTypeAtom = atom<string>({
  key: 'currentOperationType',
  default: '',
});

export const bufferOperationAtom = atom<SignOperation[]>({
  key: 'bufferOperation',
  default: [],
});

export const setResultAtom = atom<OperationResult>({
  key: 'setResult',
  default: {
    isBroadcast: false,
    isStateIn: false,
    res: null,
    status: null,
    data: null,
  },
});

export const prevOperationAtom = atomFamily({
  key: 'prevSetOperation',
  default: {
    data: [],
    download: null,
    filename: '',
    isBroadcast: false,
    isStateIn: false,
    operation: '',
    json: null,
    res: null,
    status: 400,
  },
});

export const networkAtom = atom<Network>({
  key: 'network',
  default: {
    network: process.env.REACT_APP_API_URL as string,
    networkAccount: process.env.REACT_APP_API_ACCOUNT as string,
    networkBroadcast: process.env.REACT_APP_API_BROADCAST as string,
    networkSearchFact: process.env.REACT_APP_API_SEARCH_FACT as string,
    networkPubAccounts: process.env.REACT_APP_API_PUBLIC_ACCOUNTS as string,
    networkId: process.env.REACT_APP_NETWORK_ID as string,
  },
});

export const networkTypeAtom = atom<'main' | 'test'>({
  key: 'networkType',
  default: process.env.REACT_APP_NETWORK_TYPE as 'main' | 'test',
});

export const selectCoinAtom = atom<{ currency: string; amount: string }>({
  key: 'selectCoin',
  default: {
    currency: '',
    amount: '',
  },
});

export const balanceHistoryAtom = atom<BalanceHistory[]>({
  key: 'balanceHistory',
  default: [],
});

interface AmountProps {
  currency: string;
  amount: string;
}

export const amountsAtoms = atom<AmountProps[]>({
  key: 'amountProps',
  default: [],
});

export const isTempLoginAtoms = atom<{ isLogin: boolean; publicKey: string }>({
  key: 'tempLogin',
  default: {
    isLogin: false,
    publicKey: '',
  },
});

export const etherPrivateKeyAtom = atom<string>({
  key: 'etherPrivateKey',
  default: '',
});

interface MaintainInfoProps {
  start: number;
  end: number;
  maintain: boolean;
  msg: {
    ko: string;
    en: string;
  };
}

export const maintainInfoAtom = atom<MaintainInfoProps>({
  key: 'maintainInfo',
  default: {
    start: 0,
    end: 0,
    maintain: false,
    msg: {
      ko: '',
      en: '',
    },
  },
});

export const tokenFeeAtom = atom<{ currency: string; fee: string }[]>({
  key: 'tokenFee',
  default: [],
});

interface AccountList {
  error: boolean;
  next: string;
  address: Array<string>;
}

export const accountListAtom = atom<AccountList>({
  key: 'accountList',
  default: {
    error: false,
    next: '',
    address: [],
  },
});

export const isNewTransactionAtom = atom<boolean>({
  key: 'isNewTransactionAtom',
  default: false,
});

export const CurrentLangAtom = atom({
  key: 'currentLanguage',
  default: 'ENG',
});
