import SideBar from '../../../components/layout/Sidebar';
import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { read } from 'fs';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  accountInfoAtom,
  bufferOperationAtom,
  currentOperationTypeAtom,
  networkAtom,
  networkTypeAtom,
  operationAtom,
} from '../../../store/atoms';
import { isDuplicate, isItemsInLimit, isOperation } from '../../../lib/Vadation';
import { getOperationFromType } from '../../../lib/Parse';
import download from '../../../lib/Url';
// @ts-ignore
import mitumc from 'mitumc';
// @ts-ignore
import { Signer } from 'mitumc';
import { useIsLogin } from '../../../hooks/useIsLogis';
import { useMaintainHook } from '../../../hooks/useIsMaintainHook';
import useModal from '../../../hooks/useModal';
import { Link, useHistory } from 'react-router-dom';
import { routes } from '../../../constant';
import ConfirmSignOperationModal from '../../../components/Modal/ConfirmSignOperaion';
import ImportJSONModal from '../../../components/Modal/ImpportJsonModal';
import CLEAR_BUTTON from '../../../assets/images/ico_clear.png';
import { SignOperation } from '../../../types/account';
import { isCompletedSignsImportFile, isValidationImportJsonFile } from '../../../utils/helper';
import { Trans, useTranslation } from 'react-i18next';
import { BasicHoverLabel } from '../../../components/Button/BasicHover';
import styled from 'styled-components';
import { useIsMobile } from '../../../hooks/useIsMobile';
import Header from '../../../components/layout/Header';

const SignOperationLandingPage = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState('');
  const [operationState, setOperationState] = useRecoilState(operationAtom);
  const op = useRecoilValue(operationAtom);
  const { json: jsonOperation } = operationState;
  const accountInfo = useRecoilValue(accountInfoAtom);
  const network = useRecoilValue(networkAtom);
  const [fileName, setFileName] = useState<Array<string>>([]);
  const { ModalPortal, openModal, closeModal } = useModal();
  const { SIGN_OPERATION_TRANSFER, SIGN_OPERATION_LOADING } = routes;
  const router = useHistory();
  const [bufferOperation, setBufferOperation] = useRecoilState(bufferOperationAtom);
  const inputOpenImageRef = useRef<HTMLInputElement>(null);
  const [currentOperationType, setCurrentOperationType] = useRecoilState(currentOperationTypeAtom);
  const isMobile = useIsMobile();

  const networkType = useRecoilValue(networkTypeAtom);
  const importJsonFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const json = jsonOperation;
      try {
        const parsed: SignOperation = JSON.parse(reader.result as string);

        if (!isOperation(parsed)) {
          alert(t(`info_text_18`));
          return;
        } else {
          const operation = getOperationFromType(parsed._hint);
          setCurrentOperationType(operation);
          setFileName((prevState) => [...prevState, file.name]);
          setBufferOperation([...bufferOperation, parsed]);
        }
      } catch (err) {
        if (json) {
          const operation = getOperationFromType(json._hint);
          setCurrentOperationType(operation);
          setBufferOperation([...bufferOperation, json]);
        }
        t(`info_text_19`);
      }
    };

    reader.readAsText(file, 'utf-8');
  };

  const handleImageClick = () => {
    if (inputOpenImageRef && inputOpenImageRef.current) {
      inputOpenImageRef.current.click();
    }
  };

  const onRemoveAmount = (cur: string) => () => {
    const filteredAmounts = fileName.filter((f) => {
      return f !== cur;
    });
    setFileName(filteredAmounts);
    const fileNameSplit = cur.split('.');
    const filteredBuffer = bufferOperation.filter((f: any) => {
      return f.hash !== fileNameSplit[0];
    });

    setBufferOperation(filteredBuffer);
  };

  useEffect(() => {
    setOperationState({
      json: null,
      filename: '',
      operation: '',
      download: null,
    });
    setBufferOperation([]);
  }, []);
  useIsLogin();
  useMaintainHook();

  return (
    <main>
      {isMobile && <Header />}
      <div id="contents">
        <SectionLayout>
          <div className="cont-wrap">
            <div className="middle-inner">
              <div className="cont-box">
                {!isMobile && (
                  <SideBar
                    visible={visible}
                    setVisible={setVisible}
                    isLogin={true}
                    activeLogo={2}
                  />
                )}
                <div className="cont-body keypair sign-operation" style={{ paddingRight: '22px' }}>
                  <div className="tit">
                    Multisig <em> Sign Operation</em>
                  </div>
                  <div>
                    <ul>
                      {/*<li className="sign-operation-comment">{t(`info_text_19`)}</li>*/}
                      <li className="sign-operation-comment">
                        <PWrapper>
                          <Trans i18nKey="section_seven_text_7" />
                        </PWrapper>
                      </li>
                      <li className="sign-operation-comment">
                        <PWrapperSub>
                          <Trans i18nKey="section_seven_text_8" />
                        </PWrapperSub>
                      </li>

                      <li className="sign-operation-comment">
                        <PWrapper>{t(`section_seven_text_9`)}</PWrapper>
                      </li>
                    </ul>

                    {fileName.length > 0 ? (
                      <fieldset>
                        <div style={{ marginTop: '30px' }}>
                          <ul>
                            <li className="list">
                              <label
                                style={{
                                  marginBottom: '10px',
                                  color: '#b1b5c3',
                                  fontWeight: '700',
                                }}
                              >
                                FileName
                              </label>
                              {fileName.map((f) => (
                                <div className="clear">
                                  <span
                                    className="input01"
                                    style={{ display: 'block', width: '100%' }}
                                  >
                                    <input
                                      className="file-name"
                                      type="text"
                                      placeholder="Token Name"
                                      readOnly
                                      value={f}
                                    />
                                  </span>
                                  <button
                                    type="button"
                                    className="ico-clear"
                                    style={{ display: 'block' }}
                                    onClick={onRemoveAmount(f)}
                                  >
                                    <img src={CLEAR_BUTTON} alt="" className="close-img" />
                                  </button>
                                </div>
                              ))}
                            </li>
                          </ul>
                        </div>
                      </fieldset>
                    ) : null}
                  </div>
                  <div
                    className="btn-file"
                    style={fileName.length > 0 ? { marginTop: '20px' } : {}}
                  >
                    <BasicHoverLabel onClick={handleImageClick}>
                      {fileName.length > 0 ? t(`info_text_12`) : t(`info_text_13`)}
                    </BasicHoverLabel>
                    {fileName.length > 0 ? (
                      <a
                        onClick={async () => {
                          const [isCheck, errMessage] = await isValidationImportJsonFile(
                            network,
                            networkType,
                            bufferOperation
                          );

                          if (isCheck) {
                            alert(errMessage);
                            return;
                          }

                          router.push(SIGN_OPERATION_LOADING);
                        }}
                        style={{ color: '#fff' }}
                      >
                        <BasicHoverLabel style={{ marginTop: '20px' }}>
                          {t(`info_text_11`)}
                        </BasicHoverLabel>
                      </a>
                    ) : null}
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      ref={inputOpenImageRef}
                      onChange={importJsonFile}
                      onClick={(e) => {
                        let ele = e.target as any;
                        ele.value = null;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SectionLayout>
      </div>
    </main>
  );
};

export default SignOperationLandingPage;

const SectionLayout = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-file {
    width: 380px;
  }
  .file-name {
    outline: none;
    margin: 10px 10px 10px 0px;
    padding: 10px;
    border: 1px solid rgb(230, 232, 236);
    border-radius: 12px;
    width: 90%;
  }
  .clear {
    display: flex;
  }

  @media (max-width: 1024px) {
    align-items: flex-start;
    padding-top: 90px;
    .cont-wrap {
      .cont-box {
        display: block;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: auto;
        padding: 0px 20px 32px;
      }
    }
    .title {
      font-weight: 800;
      font-size: 18px;
      line-height: 20px;
      color: #23262f;
      em {
        color: #3de0e0;
        font-weight: 600;
      }
    }

    .keypair {
      .tit + div {
        margin-top: 24px;
      }
      .tit + div p {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #777e90;
      }
    }
    .sign-operation .btn-file {
      margin-top: 40px;
    }

    .btn-file {
      width: 100%;
    }
    .clear {
      display: flex;
      gap: 10px;
    }
    textarea {
      flex: 1;
      height: 56px;
      outline: none;
      margin: 10px 10px 10px 0;
      padding: 16px;
      border: 1px solid #e6e8ec;
      border-radius: 12px;

      font-weight: 800;
      font-size: 12px;
      line-height: 12px;
      color: #141416;
    }
  }
`;

const PWrapper = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.29;
  color: #777e90;
  margin-top: 24px;
`;

const PWrapperSub = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.29;
  color: #777e90;
  margin-top: 20px;
  //white-space: nowrap;
`;
