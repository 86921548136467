import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// @ts-ignore
import { toKeypair, getKeypairFromSeed } from 'mitumc';

import SideBar from '../../../../components/layout/Sidebar';
import EYE_IMG from '../../../../assets/images/ico_eye.png';
import EYE_FILL_IMG from '../../../../assets/images/ico_eye2.png';
import { routes } from '../../../../constant';
import useInput from '../../../../hooks/useInput';
import LOCK_IMG from '../../../../assets/images/ico_lock.png';
import { useRecoilState } from 'recoil';
import { etherPrivateKeyAtom } from '../../../../store/atoms';
import styled from 'styled-components';
import { ArticleWrapper } from '../../../../styles/responsive';
import { useMaintainHook } from '../../../../hooks/useIsMaintainHook';
import { useTranslation } from 'react-i18next';
import Header from '../../../../components/layout/Header';
import { useIsMobile } from '../../../../hooks/useIsMobile';
const ButtonContainer = styled.button<{ background: boolean }>`
  background: ${(props) => (props.background ? '#dee2e6' : null)};
`;

const CreateAddressEtherMainPage = () => {
  const [visibleLogo, setVisibleLogo] = useState('');
  const [etherPrivate, onChangeEtherPrivate, setEtherPrivate] = useInput('');
  const [privateKey, onChangePrivateKey, setPrivateKey] = useInput('');
  const [publicKey, onChangePublicKey, setPublicKey] = useInput('');
  const [inputType, setInputType] = useState('password');
  const { CREATE_MAIN_ADDRESS, CREATE_ADDRESS_ETHER_DONE } = routes;
  const { t } = useTranslation();
  const [etherPrivateAtom, setEtherPrivateAtom] = useRecoilState(etherPrivateKeyAtom);
  const isMobile = useIsMobile();

  const history = useHistory();

  const goPage = (path: string) => () => {
    history.push(path);
  };

  const getPrivateKeyFromEther = () => {
    try {
      const etherPrivKey =
        etherPrivate.slice(0, 2) === '0x'
          ? etherPrivate.slice(2, etherPrivate.length)
          : etherPrivate;
      const keypair = getKeypairFromSeed(etherPrivKey.trim());
      setPublicKey(keypair.getPublicKey());
      setPrivateKey(keypair.getPrivateKey());
      setEtherPrivateAtom(keypair.getPrivateKey());
      // goPage(CREATE_ADDRESS_ETHER_DONE);
      history.push(CREATE_ADDRESS_ETHER_DONE);
    } catch (e) {
      alert(t(`info_text_23`));
    }
  };

  useMaintainHook();

  return (
    <main>
      {isMobile && <Header />}

      <ArticleWrapper>
        <SectionLayout>
          <div className="cont-wrap">
            <div className="middle-inner">
              <div className="cont-box">
                {!isMobile && (
                  <SideBar
                    visible={visibleLogo}
                    setVisible={setVisibleLogo}
                    isLogin={false}
                    activeLogo={1}
                  />
                )}
                <div className="cont-body keypair keypair03" style={{ cursor: 'pointer' }}>
                  <strong className="tit comment">{t(`section_two_text_2`)}</strong>

                  <div>
                    <p className="keypair-comment">{t(`section_two_text_3`)}</p>
                  </div>
                  <div className="input-box">
                    <strong className="sub-tit keypair-comment">ETH Private Key</strong>
                    <span className="input-password">
                      <input
                        type={inputType}
                        placeholder={t(`section_two_text_4`)}
                        value={etherPrivate}
                        onChange={onChangeEtherPrivate}
                        className=""
                        style={{
                          outline: 'none',
                        }}
                      />
                      {inputType === 'password' ? (
                        <i
                          className="ico-input ico-lock"
                          onClick={() => {
                            setInputType('text');
                          }}
                        >
                          <img src={LOCK_IMG} alt="" />
                        </i>
                      ) : (
                        <button
                          type="button"
                          style={{ display: 'block' }}
                          className="ico-input ico-eye"
                          onClick={() => {
                            setInputType('password');
                          }}
                        >
                          <img src={EYE_IMG} alt="" />
                        </button>
                      )}
                    </span>
                  </div>
                  <ButtonContainer
                    onClick={getPrivateKeyFromEther}
                    className="btn-type01 wallet-open"
                    background={etherPrivate.length === 0}
                    disabled={etherPrivate.length === 0}
                  >
                    {t(`home_one_text_3`)}
                  </ButtonContainer>
                  <Link to={CREATE_MAIN_ADDRESS} className="btn-key-go">
                    {t(`section_two_text_5`)}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SectionLayout>
      </ArticleWrapper>
    </main>
  );
};

export default CreateAddressEtherMainPage;

export const SectionLayout = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    .cont-wrap {
      .cont-box {
        display: block;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: auto;
        padding: 0px 20px 32px;
      }
    }
    .tit {
      color: #3de0e0;
      text-align: center;
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
      white-space: pre-line;
    }

    .keypair {
      .tit + div {
        margin-top: 24px;
      }
      .tit + div p {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #777e90;
      }

      .btn-type01 {
        margin: 16px 0px;
        width: 100%;
      }
    }
    .create-address-comment {
      white-space: pre-line;
      text-align: center;
    }
  }
`;
