import styled from 'styled-components';

export const BasicModalContainer = styled.div`
  width: 100%;
  max-width: 500px;
  height: 500px;
  background: #fff;
  z-index: 10;
`;

export const AlignCenterStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const basicPrimaryHoverStyle = {
  background: '#3ad4d4',
};

export const basicPrimaryDarkStyle = {
  background: '#21b8b8',
};

export const redHoverStyle = {
  background: '#e6485e',
};

export const fontHoverStyle = {
  background: '#2cb8b8',
};

export const TextAreaWrapper = styled.textarea`
  width: 100%;
  height: 48px;
  margin: 0 40px 0 0;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #777e90;
  background: #f4f5f6;
  outline: none;
  border: none;
  display: inline;
  resize: none;
`;
