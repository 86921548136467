import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { routes } from '../constant';
import LoginPage from '../pages/login';
import WalletPage from '../pages/wallet';
import OperationPage from '../pages/operation';
import ResponsePage from '../pages/response';
import KeyGeneratorPage from '../pages/key-generator';
import GetAddressPage from '../pages/get-address';
import HomePage from '../pages/home';
import Header from '../components/layout/Header';
import SettingPage from '../pages/setting';
import TransactionPage from '../pages/transection';
import CreateAddressPage from '../pages/create-address';
import CreateMainPrivateKeyPage from '../pages/create-address/main';
import CreateAddressEtherMainPage from '../pages/create-address/ether/main';
import CreateAddressEtherCompletePage from '../pages/create-address/ether/done';
import MultiSigPage from '../pages/create-address/multiSig';
import CreateMultiSigPage from '../pages/create-address/multiSig/create';
import LoadingPage from '../components/LoadingPage';
import MultiSigResponsePage from '../pages/response/multi-sig';
import SignOperationLandingPage from '../pages/sign-operation/landing';
import MultiSigTransferResponsePage from '../pages/response/multi-transfer-res';
import SignOperationTransactionPage from '../pages/sign-operation/transaction';
import SignOperationProcessPage from '../pages/sign-operation/process';
import SignOperationCompletePage from '../pages/sign-operation/complete';
import SignOperationLoadingPage from '../pages/sign-operation/loading';
import TermsPage from '../pages/setting/terms';
import PersonalTermsPage from '../pages/setting/personalTerms';

export const PublicRouter = () => {
  const {
    LOGIN,
    WALLET,
    OPERATION,
    RESPONSE,
    KEY_GENERATE,
    GET_ADDRESS,
    HOME,
    SETTING,
    TRANSACTION,
    CREATE_ADDRESS,
    CREATE_MAIN_ADDRESS,
    CREATE_ADDRESS_ETHER,
    CREATE_ADDRESS_ETHER_DONE,
    CREATE_MULTI_SIG_ADDRESS,
    CREATE_MULTI_SIG_WALLET,
    MULTI_LOADING,
    MULTI_SIG_RESPONSE,
    SIGN_OPERATION_LANDING,
    SIGN_OPERATION_TRANSFER,
    MULTI_SIG_TRANSFER_RESPONSE,
    SIGN_OPERATION_COMPLETE,
    SIGN_OPERATION_PROCESS,
    SIGN_OPERATION_LOADING,
    TERM,
    PERSONAL_TERMS,
  } = routes;
  return (
    <>
      <Switch>
        <Route exact path={HOME} component={HomePage} />
        <Route exact path={LOGIN} component={LoginPage} />
        <Route exact path={WALLET} component={WalletPage} />
        <Route exact path={OPERATION} component={OperationPage} />
        <Route exact path={RESPONSE} component={ResponsePage} />
        <Route exact path={KEY_GENERATE} component={KeyGeneratorPage} />
        {/*<Route exact path={GET_ADDRESS} component={GetAddressPage} />*/}
        <Route exact path={CREATE_ADDRESS} component={CreateAddressPage} />
        <Route exact path={CREATE_MAIN_ADDRESS} component={CreateMainPrivateKeyPage} />
        <Route exact path={CREATE_ADDRESS_ETHER} component={CreateAddressEtherMainPage} />
        <Route exact path={CREATE_ADDRESS_ETHER_DONE} component={CreateAddressEtherCompletePage} />
        <Route exact path={TRANSACTION} component={TransactionPage} />
        <Route exact path={SETTING} component={SettingPage} />
        <Route exact path={CREATE_MULTI_SIG_ADDRESS} component={MultiSigPage} />
        <Route exact path={CREATE_MULTI_SIG_WALLET} component={CreateMultiSigPage} />
        <Route exact path={MULTI_LOADING} component={LoadingPage} />
        <Route exact path={MULTI_SIG_RESPONSE} component={MultiSigResponsePage} />
        <Route exact path={SIGN_OPERATION_LANDING} component={SignOperationLandingPage} />
        <Route exact path={MULTI_SIG_TRANSFER_RESPONSE} component={MultiSigTransferResponsePage} />
        <Route exact path={SIGN_OPERATION_TRANSFER} component={SignOperationTransactionPage} />
        <Route exact path={SIGN_OPERATION_PROCESS} component={SignOperationProcessPage} />
        <Route exact path={SIGN_OPERATION_COMPLETE} component={SignOperationCompletePage} />
        <Route exact path={SIGN_OPERATION_LOADING} component={SignOperationLoadingPage} />
        <Route exact path={TERM} component={TermsPage} />
        <Route exact path={PERSONAL_TERMS} component={PersonalTermsPage} />
      </Switch>
    </>
  );
};
