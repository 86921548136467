import axios from 'axios';

export const SERVER_API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const COIN_SERVER_API = (type: 'main' | 'test') => {
  switch (type) {
    case 'main':
      return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });
      break;
    case 'test':
      return axios.create({
        baseURL: process.env.REACT_APP_SUB_API_URL,
      });
  }
};
