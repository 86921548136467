import { VFC } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../../constant';
import { Trans, useTranslation } from 'react-i18next';
import { ButtonHover, RedButtonHover } from "../Button/BasicHover";
interface Props {
  closeModal: () => void;
}

const ConfirmMultiSigModal: VFC<Props> = ({ closeModal }) => {
  const router = useHistory();
  const { t } = useTranslation();
  const { CREATE_MULTI_SIG_WALLET } = routes;
  const goPage = () => {
    router.push(CREATE_MULTI_SIG_WALLET);
  };

  return (
    <section className="popup" style={{ display: 'block' }}>
      <div className="pop-wrap text-c create-multisig">
        <strong
          className="pop-tit"
          style={{ fontSize: '18px', fontWeight: 600, color: '#23262f', fontFamily: 'OpenSans' }}
        >
          Create MULTISIG Wallet
        </strong>
        <span className="red">{t(`info_text_14`)}:</span>
        <p>
          {' '}
          <Trans i18nKey="section_six_text_2" />
        </p>
        <p>
          <Trans i18nKey="section_six_text_3" />{' '}
        </p>
        <p>
          <Trans i18nKey="section_seven_text_1" />{' '}
        </p>
        <div className="flex-between">
          <RedButtonHover
            type="button"
            className="btn-type01 small"
            style={{ backgroundColor: '#ff5069' }}
            onClick={closeModal}
          >
            {t(`section_seven_text_3`)}
          </RedButtonHover>
          <ButtonHover type="button" className="btn-type01 small" onClick={goPage}>
            {t(`section_seven_text_2`)}
          </ButtonHover>
        </div>
      </div>
    </section>
  );
};

export default ConfirmMultiSigModal;
