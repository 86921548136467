import { VFC } from 'react';
import CLEAR_IMG from '../../assets/images/ico_clear.png';
interface Props {
  publicKey: string;
  weight: string;
  onRemoveAmount: () => void;
}

const ParticipantsBox: VFC<Props> = ({ publicKey, weight, onRemoveAmount }) => {
  return (
    <li>
      <span className="input01">
        <input type="text" placeholder="퍼블릭키를 입력해주세요." value={publicKey} readOnly />
      </span>
      <span className="input02">
        <input type="text" placeholder="weight를 입력하세요." value={weight} readOnly />
      </span>
      <button
        type="button"
        className="ico-clear"
        style={{ position: 'relative', width: '40px' }}
        onClick={onRemoveAmount}
      >
        <img src={CLEAR_IMG} alt="" />
      </button>
    </li>
  );
};

export default ParticipantsBox;
