import SideBar from '../../../components/layout/Sidebar';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { accountInfoAtom } from '../../../store/atoms';
import { useIsLogin } from '../../../hooks/useIsLogis';
import { useMaintainHook } from '../../../hooks/useIsMaintainHook';
import { Link } from 'react-router-dom';

const TermsPage = () => {
  const [isVisible, setIsVisible] = useState('');

  const accountInfo = useRecoilValue(accountInfoAtom);

  return (
    <main>
      <div id="contents">
        <article>
          <section>
            <div className="cont-wrap transfer">
              <div className="middle-inner">
                <div className="cont-box">
                  <SideBar
                    visible={isVisible}
                    setVisible={setIsVisible}
                    isLogin={accountInfo.address.length > 0}
                    activeLogo={100}
                  />
                  <div className="cont-body">
                    <Link to="/login">
                      <strong className="tit">이용약관</strong>
                    </Link>
                    <div className="rules-wrap">
                      <div className="rules-box">
                        <strong className="rules-tit">제 1 조 (목적)</strong>
                        <p>
                          본 이용 약관은 서비스 이용자가 주식회사 프로토콘 (이하 “회사”)가 제공하는
                          모바일 웹 또는 앱, 브라우저 확장 프로그램 등 디지털 지갑 서비스 Protocon
                          Wallet (이하 “서비스”)을 이용함에 있어 회사와 회원 간의 권리•의무관계를
                          규율 함을 목적으로 합니다.
                        </p>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">제 2 조 (용어의 정의)</strong>
                        <ol>
                          <li>
                            1. "서비스"라 함은 단말기(PC, 모바일 기기 등)에 상관없이, 회사가
                            제공하는 디지털 지갑 서비스 Protocon Wallet 과 관련된 제반 서비스를
                            의미합니다.
                          </li>
                          <li>
                            2. "DApp"이라 함은 Protocon Network 에서 일련의 프로그램을 통해 실행되는
                            개별적이고 독립적인 서비스를 말합니다.
                          </li>
                          <li>
                            3. "트랜잭션"이라 함은 Protocon Network 의 토큰의 입금, 출금 전송 등의
                            작업을 수행하는 단위를 말합니다.
                          </li>
                          <li>
                            4. "토큰(Token)"이라 함은 Protocon Network 에서 발행된 형태의 디지털
                            자산을 말합니다.
                          </li>
                          <li>
                            5. “디지털 자산”이라 함은 서비스를 통해 전자적인 방법으로 가치의 저장
                            수단, 교환의 매개가 되는 것으로 코인 또는 토큰 등을 포함한 블록체인 상의
                            모든 데이터를 말합니다.
                          </li>
                          <li>
                            6. "회원"이라 함은 본 약관에 따라 회사와 이용계약을 체결하고, 회사가
                            제공하는 서비스를 이용하는 개인을 말합니다.
                          </li>
                          <li>
                            7. "지갑"이라 함은 회원의 계정과 연계된 디지털 자산을 확인 및 입출금하는
                            기능을 제공하고 계정 주소 통해 디지털 자산을 주고받을 수 있는 서비스를
                            말합니다.
                          </li>
                          <li>
                            8. “계정 주소”라 함은 서비스에서 회원간 디지털 자산의 변화를 기록하기
                            위해 Protocon Network 에 존재하는 고유한 식별 주소를 말합니다.
                          </li>
                          <li>
                            9. "임시 주소"라 함은 서비스에서 회원에게 발급한 고유한 식별 주소지만
                            Protocon Network 에는 존재하지 않는 주소를 말합니다.
                          </li>
                          <li>
                            10. "프라이빗 키(Private Key)"이라 함은 계정 주소가 보유한 토큰의 전송
                            허가 및 서명 등을 위해 사용되 고유한 문자와 숫자의 조합을 의미합니다.
                          </li>
                        </ol>
                        <p>
                          본 약관에서 사용하는 용어의 정의는 전항에서 정하는 것을 제외하고는 관계
                          법령 및 일반적인 상관례에 의합니다.
                        </p>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">제 3 조 (이용계약 체결)</strong>
                        <ol>
                          <li>
                            1. 이용계약의 성립 시기는 가입신청자가 약관에 동의한 후 서비스를
                            제공받아 설치한 시점으로 합니다.
                          </li>
                          <li>
                            2. 회사가 제공하는 서비스에 관한 이용계약 (“이용계약”)은 회원이 되고자
                            하는 자("가입신청자")가 약관의 내용에 대하여 동의를 한 다음 서비스를
                            제공받음으로써 체결됩니다.
                          </li>
                          <li>
                            3. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가
                            있는 경우에는 승낙을 유보할 수 있습니다.
                          </li>
                          <li>
                            4. 제 2 항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우,
                            회사는 원칙적으로 이를 가입신청자에게 알리도록 합니다.
                          </li>
                        </ol>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">제 4 조 (약관의 게시와 개정)</strong>
                        <ol>
                          <li>
                            1. 회사는이약관의내용을회원이쉽게알수있도록서비스초기화면에게시합니다.
                          </li>
                          <li>
                            2. 회사는 "약관의 규제에 관한 법률", "정보통신망 이용촉진 및
                            정보보호등에 관한 법률(이하 "정보통신망법")" 등 관련법을 위배하지 않는
                            범위에서 이 약관을 개정할 수 있습니다.
                          </li>
                          <li>
                            3. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
                            현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 7일 전부터
                            적용일자 전일까지 합리적인 방법으로 공지합니다. 다만, 회원에게 불리한
                            약관의 개정의 경우에는 최소한 30 일 이상의 사전 유예 기간을 두고
                            공지합니다. 이 경우 회사는 개정 전 내용과 개정 후 내용을 명확하게
                            비교하여 회원이 알기 쉽도록 표시합니다.
                          </li>
                          <li>
                            4. 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을
                            적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만,
                            기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사가 이용계약을
                            해지할 수 있습니다.
                          </li>
                        </ol>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">제 5 조 (약관 외 준칙)</strong>
                        <p>
                          본 약관에서 정하지 아니한 사항은 대한민국의 관련 법령 또는 회사가 정한
                          서비스의 개별 이용약관과 일반적인 상관례에 의합니다.
                        </p>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">제 6 조 (개인정보보호 의무)</strong>
                        <p>
                          회사는 "정보통신망법", “개인정보보호법” 등 관계 법령이 적용되는 경우
                          회원의 개인정보를 보호하기 위해 노력하며 해당 법을 준수할 것입니다.
                        </p>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">
                          제 7 조 ("회원"의 "프라이빗 키(Private Key)"의 관리에 대한 의무)
                        </strong>
                        <ol>
                          <li>
                            1. 회원의 프라이빗 키(Private Key) 등에 관한 관리책임은 회원에게 있으며,
                            이를 제 3 자가 이용하도록 하여서는 안 됩니다.
                          </li>
                          <li>
                            2. 회원은 프라이빗 키(Private Key)가 도용되거나 제 3 자가 사용하고
                            있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야
                            합니다.
                          </li>
                          <li>
                            3. 제2항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한
                            경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 고의나
                            과실이 없는 한 책임지지 않습니다.
                          </li>
                        </ol>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">제 8 조 ("회사"의 의무)</strong>
                        <ol>
                          <li>
                            1. 회사는 관련 법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지
                            않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여
                            노력합니다.
                          </li>
                          <li>
                            2. 회사는 서비스이용과 관련하여 발생하는 서비스 개선 및 이용자의 불만을
                            적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.
                          </li>
                          <li>
                            3. 회원이 제기한 의견이나 불만사항에 대해서는 전자우편 등을 통하여
                            회원에게 처리과정 및 결과를 전달할 수 있습니다.
                          </li>
                        </ol>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">제 9 조 ("회원"의 의무)</strong>
                        <ol>
                          <li>
                            1. 회원은 다음 행위를 하여서는 안 됩니다.
                            <ol>
                              <li>1. 신청또는변경시허위내용을등록하는행위</li>
                              <li>2. 타인의 정보 도용하는 행위</li>
                              <li>3. 회사가 게시한 정보의 변경하는 행위</li>
                              <li>
                                4. 회사가정한정보이외의정보(컴퓨터프로그램등)등의송신또는게시하는
                                행위
                              </li>
                              <li>
                                5. 회사와 기타 제3자의 저작권 등 지식재산권에 대한 침해하는 행위
                              </li>
                              <li>
                                6. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
                              </li>
                              <li>7. 범죄와 결부된다고 객관적으로 인정되는 행위</li>
                              <li>
                                8. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는
                                정보를 서비스에 공개 또는 게시하는 행위
                              </li>
                              <li>9. 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위</li>
                              <li>10. 기타 관계법령에 위배되거나 부당한 행위</li>
                            </ol>
                          </li>
                          <li>
                            2. 회원은 관계 법령과 본 약관의 규정, 이용안내 및 서비스와 관련하여
                            공지한 주의사항을 성실히 준수하여야 합니다.
                          </li>
                          <li>
                            3. 회원은 약관 및 관계법령에서 규정한 사항과 회사가 통지하는 사항 등을
                            준수하여야 하며, 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
                          </li>
                          <li>
                            4. 회원은 회사의 사전승낙 없이 서비스를 이용하여 영업활동을 할 수
                            없으며, 그 영업활동으로 인한 결과에 대하여 회사는 책임을 지지 않는다.
                          </li>
                        </ol>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">제 10 조 ("서비스"의 제공 등)</strong>
                        <ol>
                          <li>
                            1. 회사는 회원에게 아래와 같은 서비스를 제공합니다.
                            <ol>
                              <li>1. 새로운임시주소및계정주소의생성</li>
                              <li>2. 토큰등디지털자산의확인</li>
                              <li>3. 토큰등디지털자산의전송</li>
                              <li>
                                4. 서비스를 통해 보여지는 토큰의 등록 및 삭제 (브라우저 확장
                                프로그램인 경우)
                              </li>
                              <li>5. DApp의각종트랜잭션요청에대한승인및서명</li>
                              <li>
                                6. 기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해
                                회원에게 제공하는 일체의 서비스
                              </li>
                            </ol>
                          </li>
                          <li>2. 서비스는 연중무휴, 1 일 24 시간 제공함을 원칙으로 합니다.</li>
                          <li>
                            3. 회사는 Network 장애 또는 컴퓨터 등 정보통신설비의 보수점검, 교체 및
                            고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을
                            일시적으로 중단할 수 있습니다. 이 경우 회사는 합리적 방법으로 회원에게
                            통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우
                            사후에 통지할 수 있습니다.
                          </li>
                        </ol>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">
                          제11조 (타 블록체인 플랫폼 기반 디지털 자산 이용 제한)
                        </strong>
                        <ol>
                          <li>
                            1. 본 서비스는 블록체인 플랫폼인 Protocon Network 에서 사용 가능한 토큰
                            및 디지털 자산 등의 전송과 보관 등을 허용하고 있으며 그 외 블록체인
                            플랫폼을 기반으로 하는 가상자산 및 디지털 자산 등의 전송 및 보관 등은
                            허용되지 않습니다. 회원은 회사가 제공 또는 허용한 디지털 자산에 한하여
                            전송, 보관 등을 수행할 수 있습니다.
                          </li>
                          <li>
                            2. 전 항에도 불구하고 이를 확인하지 않은 회원의 부주의로 인하여 전송된
                            기타 블록체인 플랫폼 기반 가상자산 및 디지털 자산 등에 대해서 회사는
                            책임 지지 않으며, 회원은 이를 이유로 회사에게 어떠한 청구도 할 수
                            없습니다.
                          </li>
                        </ol>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">제 12 조 ("서비스"의 변경 및 중단)</strong>
                        <ol>
                          <li>
                            1. 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라
                            제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.
                          </li>
                          <li>
                            2. 서비스의 내용, 이용방법, 이용시간 등에 대하여 변경이 있는 경우에는
                            회사는 변경사유, 변경될 서비스의 내용 및 제공일자 등을 변경 전에
                            서비스를 통해 합리적인 방식으로 공지합니다. 단, 회사가 사전에 고지할 수
                            없는 부득이한 사유가 있는 경우에는 사후에 공지할 수 있으나 가능한 한
                            신속히 회원에게 공지합니다.
                          </li>
                          <li>
                            3.
                            회사는무료로제공되는서비스의일부또는전부를회사의정책및운영의필요상수정,
                            중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 있거나 회사의
                            고의 또는 과실로 인해 회원의 지갑/계정 주소가 분실 또는 삭제되는 경우
                            회사는 회원에게 합당한 보상을 할 수 있습니다.그외 회사의 귀책사유가 없는
                            경우에는 회원에게 별도의 보상을 하지 않습니다.
                          </li>
                        </ol>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">제 13 조 (계약해제, 해지 등)</strong>
                        <ol>
                          <li>
                            1. 회원은 언제든지 서비스 삭제를 통해 회사와의 이용계약을 해지할 수
                            있습니다.
                          </li>
                          <li>
                            2. 회사는 다음과 같은 사유가 있는 경우 이용계약을 해지할 수 있습니다.
                            <ol>
                              <li>1. 회원이본약관에위배되는행위를한경우</li>
                              <li>
                                2. 불법프로그램의 제공 및 서비스 운영 방해, 불법통신 및 해킹,
                                악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련 법령에
                                위배되는 행위를 한 경우
                              </li>
                              <li>
                                3. 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나
                                그러한 행위를 시도한 경우
                              </li>
                              <li>
                                4. 기타 회사가 합리적인 판단에 기하여 서비스의 제공을 거부할 필요가
                                있다고 인정하는 경우
                              </li>
                            </ol>
                          </li>
                          <li>
                            3. 본 조에 따른 이용계약 해지 시 회원이 서비스 이용을 통해 획득한 모든
                            혜택이 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.
                          </li>
                          <li>
                            4. 회사는 프라이빗 키(Private Key)을 별도 보관, 관리 또는 저장하지
                            않습니다. 따라서 회원은 서비스 제거 전 프라이빗 키(Private Key)를 백업
                            및 관리에 유의해 주시기 바랍니다.
                          </li>
                          <li>
                            5. 제3항에 따라 회사가 이용계약을 해지하는 경우 회사는 회원의 이의신청
                            접수 및 처리 등을 위하여 일정 기간 동안 회원의 정보를 보관할 수
                            있습니다.
                          </li>
                        </ol>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">제 14 조 (책임제한)</strong>
                        <ol>
                          <li>
                            1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할
                            수 없는 경우에는 서비스제공에 관한 책임이 면제됩니다.
                          </li>
                          <li>
                            2. 회사는 회원의 귀책사유로 인한 서비스이용의 장애에 대하여는 회사의
                            고의 또는 과실이 없는 한, 책임을 지지 않습니다.
                          </li>
                          <li>
                            3. 회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도,
                            정확성 등의 내용에 관하여는 책임을 지지 않습니다.
                          </li>
                          <li>
                            4. 회사는회원간또는회원과제3자상호간에서비스를매개로하여거래등을한
                            경우에는 책임이 면제됩니다.
                          </li>
                          <li>
                            5. 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이
                            없거나 회사의 고의/과실이 없는 한 책임을 지지 않습니다.
                          </li>
                          <li>
                            6. 회사는 블록체인 플랫폼 Protocon Network 의 장애로 인한 손해에
                            대해서는 회사의 고의/과실이 있지 않는 한 책임을 지지 않습니다.
                          </li>
                          <li>
                            7. 회사는 프라이빗 키(Private Key)를 통한 디지털 자산의 전송 기능을
                            제공하지만, 회원의 디지털 자산 자체에 대한 관리 의무를 부담하지
                            않습니다. 프라이빗 키(Private Key)의 보관 및 도난, 분실 방지를 포함하여
                            디지털 자산에 대한 모든 책임은 회원에게 있으며, 회사의 귀책 사유가 없는
                            경우에는 어떠한 책임도 지지 않습니다.
                          </li>
                          <li>
                            8. 회원이 제 3 자 서비스에서 제공하는 주소의 정확성에 대한 책임은
                            회원에게 있으며, 회사는 회원이 잘못 기재한 주소로 서명 및 전송에
                            대해서는 책임을 지지 않습니다.
                          </li>
                        </ol>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">제 15 조 (손해배상)</strong>
                        <ol>
                          <li>
                            1. 회사는 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에
                            명시되지 않은 어떠한 구체적인 사항에 대하여 약정이나 보증을 하지
                            않습니다. 또한, 회사는 디지털 자산의 가치를 보증하지 않습니다. 또한
                            회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는
                            보증을 하지 않으며 이로 인해 발생한 회원의 손해에 대하여 회사의 고의나
                            과실이 있지 않는 한 책임을 지지 않습니다.
                          </li>
                          <li>
                            2. 회사의 책임 있는 사유로 인하여 회원에게 손해가 발생한 경우 회사의
                            손해배상 범위는 민법에서 정하고 있는 통상손해를 포함하고 기타 간접,
                            부수, 특별 손해에 대해서는 어떠한 경우에도 책임을 지지 않습니다.
                          </li>
                          <li>
                            3. 제2항에도불구하고다음각호의어느하나에해당하는경우에는회원이그책임의
                            전부 또는 일부를 부담할 수 있습니다.
                            <ol>
                              <li>
                                1. 회원이 손해 발생의 원인 또는 손해 발생 사실을 알았음에도 불구하고
                                회사에 통지하지 않은 경우
                              </li>
                              <li>
                                2. 회원이 고의 또는 과실로 제 3자에게 지갑 관련 계정 및 거래 정보를
                                유출하거나 지갑을 사용하게 한 경우
                              </li>
                              <li>3. 그 외에 손해 발생에 있어서 회원의 고의나 과실이 있는 경우</li>
                            </ol>
                          </li>
                          <li>
                            4. 회사의 고의나 과실이 있지 않는 한 회사는 회사의 책임 없는 사유로
                            인하여 회원에게 발생한 손해에 대하여는 배상책임이 없습니다.
                          </li>
                          <li>
                            5. 회원이 회사에게 제공한 정보가 사실과 다를 경우, 회사는 언제든지
                            서비스의 제공을 중단하고 본 약관에 의해 계약 전체 또는 일부를 해지할 수
                            있으며, 이로 인하여 회사에게 손해가 발생한 경우, 회원에게 그 손해의
                            배상을 청구할 수 있습니다.
                          </li>
                          <li>
                            6. 회원이 회사의 시스템 운영을 방해하는 등 고의 또는 과실로
                            법령,본약관,공공의 실서와 선량한 풍속에 위배하는 행위 등을 통하여 회사에
                            손해를 발생시킨 경우에는 회사에 발생한 손해를 모두 배상해야 합니다.
                          </li>
                          <li>
                            7. 회원이 회사에게 손해배상을 청구할 경우 회사는 회원과 합의하여 회원의
                            지갑으로 디지털 자산을 지급하는 방법으로 회원의 손해를 배상할 수
                            있습니다.
                          </li>
                          <li>
                            8. 회사는 프라이빗 키(Private Key)를 별도 보관, 관리 또는 저장하지
                            않습니다. 회사는 회원의 프라이빗 키(Private Key) 관리에 대한 책임이
                            없으며 프라이빗 키(Private Key)의 보관, 관리 및 저장에 관한 책임은
                            전적으로 회원에게 있습니다. 회원은 서비스 삭제 전 반드시 프라이빗
                            키(Private Key)를 백업해야 하며 회원이 백업하지 않고 서비스를 삭제하여
                            발생하는 모든 손해에 대해서는 회사의 고의 또는 과실이 없는 한 배상책임이
                            없습니다.
                          </li>
                        </ol>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">제 16 조 (지식재산권)</strong>
                        <p>
                          회사의 서비스 관련 모든 지식재산권은 회사의 소유이며 회원은 서비스 범위
                          내에서 본 약관 등으로 회사가 정한 방법에 따라 서비스를 이용할 권리를
                          가지며 어떠한 경우에도 회사의 지식재산권의 변경, 등록, 취소, 침해 등을 할
                          권리가 없습니다.
                        </p>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">제 17 조 (준거법 및 재판관할)</strong>
                        <ol>
                          <li>1. 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
                          <li>
                            2. 회사와 회원간 발생한 분쟁은 대한상사중재원의 규율로 대한민국 서울에서
                            중재로 해결하고 중재 결과는 모든 당사자를 구속합니다.
                          </li>
                          <li>
                            3. 해외에 주소나 거소가 있는 회원의 경우 회사와 회원간 발생한 분쟁에
                            관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로
                            합니다.
                          </li>
                        </ol>
                      </div>
                      <div className="rules-box">
                        <strong className="rules-tit">부칙</strong>
                        <ol>
                          <li>1. 이 약관은 2022년 01월 01일부터 적용됩니다.</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </article>
      </div>
    </main>
  );
};

export default TermsPage;
