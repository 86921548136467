import SideBar from '../../../components/layout/Sidebar';
import { routes } from '../../../constant';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accountInfoAtom, operationAtom, setResultAtom } from '../../../store/atoms';
import { useIsLogin } from '../../../hooks/useIsLogis';
import { useMaintainHook } from '../../../hooks/useIsMaintainHook';
import { SignOperationItem } from '../../../types/account';
import { useTranslation } from 'react-i18next';
import { ConfirmBtn } from '../index';
import styled from 'styled-components';
import { useIsMobile } from '../../../hooks/useIsMobile';
import OPEN_NEW_IMG from '../../../assets/images/ico_open-new.png';

const MultiSigResponsePage = () => {
  const [visible, setVisible] = useState('');
  const { WALLET } = routes;
  const accountInfo = useRecoilValue(accountInfoAtom);
  const operationState = useRecoilValue(operationAtom);
  const operationResult = useRecoilValue(setResultAtom);
  const [_item, setItem] = useState<SignOperationItem[]>([]);
  const [_hash, setHash] = useState('');
  const { t } = useTranslation();
  const { data } = operationResult;
  const isMobile = useIsMobile();

  useIsLogin();
  useMaintainHook();

  useEffect(() => {
    if (operationState.json) {
      const {
        json: {
          fact: { hash },
        },
      } = operationState;

      const {
        json: {
          fact: { items },
        },
      } = operationState;

      setItem(items);
      setHash(hash);
    }
  }, []);

  return (
    <main>
      <div id="contents">
        <SectionLayout className="multi-sig-response">
          <div className="cont-wrap transfer completed">
            <div className="middle-inner">
              <div className="cont-box">
                {!isMobile && (
                  <SideBar
                    visible={visible}
                    setVisible={setVisible}
                    isLogin={true}
                    activeLogo={2}
                  />
                )}
                <div className="cont-body">
                  <Link to={WALLET}>
                    <strong className="tit back">{t(`info_text_20`)}</strong>
                  </Link>
                  <h3>Completed 🎉</h3>
                  <div className="transfer-wrap">
                    <ul>
                      <li>
                        <span className="small-tit">Created Address</span>
                        <div className="key-area">
                          <p>{accountInfo.address}</p>
                          <button type="button" className="btn-box">
                            <a
                              href={`${process.env.REACT_APP_EXPLORER}/operation/${_hash}`}
                              target="_blank"
                            >
                              <img src={OPEN_NEW_IMG} alt="" />
                            </a>
                          </button>
                        </div>
                        <p className="ico-info">{t(`section_seven_text_5`)}</p>
                      </li>

                      <ConfirmBtn>
                        <Link to="/wallet">Confirm</Link>
                      </ConfirmBtn>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SectionLayout>
      </div>
    </main>
  );
};

export default MultiSigResponsePage;

const SectionLayout = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .key-area {
    p {
      height: auto;
      width: 100%;
      padding-right: 36px;
      box-sizing: border-box;
    }
    .btn-box {
      position: absolute;
      top: 22px;
      right: 22px;
    }
  }

  @media (max-width: 1024px) {
    padding-top: 12px;
    align-items: flex-start;
    .cont-wrap {
      .cont-box {
        display: block;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: auto;
        padding: 0px 20px 32px;
      }
    }
    .tit {
      font-weight: 800;
      font-size: 18px;
      line-height: 34px !important;
    }
    .completed h3 {
      margin-top: 32px;
      font-weight: 800;
      font-size: 32px;
      line-height: 44px;
      color: #3de0e0;
    }
    .completed h3 + .transfer-wrap {
      margin-top: 0px;
    }
    .transfer-wrap .ico-info {
      margin-bottom: 40px;
    }
    .small-tit {
      margin-top: 32px;
    }
    .transfer-wrap li {
      margin-top: 0px;
      a {
        width: 100%;
        font-weight: 700;
      }
    }
  }
`;
