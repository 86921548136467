import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BasicHoverBtn = styled.a`
  &:hover {
    background-color: #4ae6e6 !important;
    opacity: 1 !important;
  }

  cursor: pointer;
`;

export const BasicHoverLabel = styled.label`
  &:hover {
    background-color: #4ae6e6 !important;
    opacity: 1 !important;
  }

  cursor: pointer;
`;

export const BasicDarkHoverBtn = styled.a`
  &:hover {
    background-color: #21b8b8 !important;
    opacity: 1 !important;
  }

  cursor: pointer;
`;

export const BasicHoverText = styled.a`
  &:hover {
    color: #32d1d1 !important;
    opacity: 1 !important;
  }
  cursor: pointer;
`;

export const BasicHoverLinkText = styled(Link)`
  &:hover {
    color: #2cb8b8 !important;
    opacity: 1 !important;
  }
  cursor: pointer;
`;

export const BasicHoverLink = styled(Link)`
  &:hover {
    background-color: #4ae6e6 !important;
    opacity: 1 !important;
  }
  cursor: pointer;
`;

export const ButtonHover = styled.button`
  &:hover {
    background-color: #4ae6e6 !important;
    opacity: 1 !important;
  }
  cursor: pointer;
`;

export const GrayButtonHover = styled.button`
  &:hover {
    background-color: #d1d5d9 !important;
    opacity: 1 !important;
  }
  cursor: pointer;
`;

export const RedButtonHover = styled.button`
  &:hover {
    background-color: #e6485e !important;
    opacity: 1 !important;
  }
  cursor: pointer;
`;
