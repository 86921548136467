import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import copy from 'copy-to-clipboard';

// @ts-ignore
import { Generator } from 'mitumc';

import {
  accountInfoAtom,
  accountListAtom,
  historyAtom,
  isLoginAtom,
  isTempLoginAtoms,
  networkAtom,
  networkTypeAtom,
} from '../../store/atoms';
import SideBar from '../../components/layout/Sidebar';

import COPY_IMG from '../../assets/images/ico_copy.png';
import AssetsList from '../../components/AssetsList';
import { getAccountInformation, getPubAccounts, isCheckTempUser } from '../../service/account';
import { accountInfoUtil } from '../../utils/manufactureStore';
import { useMaintainHook } from '../../hooks/useIsMaintainHook';
import { convertCoinAmount } from '../../utils/helper';
import { AccountHistoryResBody, AccountInfo } from '../../types/account';
import { ArticleWrapper } from '../../styles/responsive';
import axios from 'axios';
import AccountLists from '../../components/AccountLists';
import { useTransactionHook } from '../../hooks/useTransaction';
import useModal from '../../hooks/useModal';
import AddTempAddressModal from '../../components/Modal/AddTempAddress';
import { Trans, useTranslation } from 'react-i18next';
import Header from '../../components/layout/Header';
import { useIsMobile } from '../../hooks/useIsMobile';
import styled from 'styled-components';

const WalletPage = () => {
  const [visibleLogo, setVisibleLogo] = useState('');
  const { t } = useTranslation();
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const network = useRecoilValue(networkAtom);
  const histories = useRecoilValue(historyAtom);
  const isLogin = useRecoilValue(isLoginAtom);
  const isTempUser = useRecoilValue(isTempLoginAtoms);
  const networkType = useRecoilValue(networkTypeAtom);
  const networkState = useRecoilValue(networkAtom);
  const [accountLists, setAccountLists] = useRecoilState(accountListAtom);
  const [visibleText, setVisibleText] = useState('none');
  const { ModalPortal, openModal, closeModal } = useModal();

  const isMobile = useIsMobile();
  const history = useHistory();

  const onCreateTempAddress = async () => {
    const result = await isCheckTempUser(accountInfo.publicKey, networkType);
    if (result._embedded.length === 3) {
      alert(t(`info_text_17`));
      return;
    }
    openModal();
  };

  const onMouseOver = () => {
    setVisibleText('block');
  };

  const onToggleSelectBox = () => {
    setVisibleText(visibleText === 'block' ? 'none' : 'block');
  };

  const onMouseLeave = () => {
    setVisibleText('none');
  };
  const onCopy = (type: 'public' | 'address' | 'temp') => () => {
    switch (type) {
      case 'public':
        copy(accountInfo.publicKey);
        alert('copied');
        break;
      case 'address':
        copy(accountInfo.address);
        alert('copied');
        break;
      case 'temp':
        copy(isTempUser.publicKey);
        alert('copied');
        break;
    }
  };

  const getAccountSelector = async () => {
    const accounts = await getPubAccounts(
      accountInfo.publicKey,
      network.networkPubAccounts,
      networkType
    );
    if (accounts._embedded === null) {
      setAccountLists({
        error: true,
        next: '',
        address: [],
      });
      return;
    }

    let result = accounts._embedded.map((x) => {
      return x._embedded.address;
    });
    console.log(result, accounts._embedded);
    result.unshift(accounts._links.next.href);

    if (result.length < 2) {
      setAccountLists({
        error: true,
        next: '',
        address: [],
      });
      return;
    }

    const _next = result.shift() as string;

    setAccountLists({
      error: false,
      next: _next,
      address: result,
    });
  };

  const onRefresh = () => {
    history.push('/login');
  };
  useEffect(() => {
    if (!isLogin) {
      history.push('/login');
    }
  }, []);

  useEffect(() => {
    bootstrap();
  }, []);

  // useMaintainHook();

  const bootstrap = async () => {
    const account = await getAccountInformation(accountInfo.address, networkType);
    const accountInfoResult: { account: AccountInfo } = accountInfoUtil(
      account._embedded.address,
      accountInfo.privateKey,
      accountInfo.publicKey,
      account
    );

    const pubAccounts = await getPubAccounts(
      accountInfo.publicKey,
      networkState.networkPubAccounts,
      networkType
    );
    await getAccountSelector();

    // accountInfoResult.account.balances = accountInfoResult.account.balances.map((bal) => {
    //   return { currency: bal.currency, amount: convertCoinAmount(bal.amount) };
    // });
    setAccountInfo(accountInfoResult!.account);
  };

  // useTransactionHook();
  console.log(accountInfo.accountType);
  return (
    <main>
      {isMobile && <Header />}
      <div id="contents" style={{ display: isMobile ? 'block' : 'flex', justifyContent: 'center' }}>
        <ArticleWrapper>
          <SectionLayout>
            <div className="cont-wrap">
              <div className="middle-inner">
                <div className="cont-box sub-box">
                  {!isMobile && (
                    <SideBar
                      visible={visibleLogo}
                      setVisible={setVisibleLogo}
                      isLogin={true}
                      activeLogo={0}
                    />
                  )}
                  <div className="cont-body my-wallet">
                    <strong className="tit">My Wallet</strong>
                    {/*  Address Selection */}
                    <div className="address-box">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          gap: '4px',
                        }}
                      >
                        <div>
                          <strong
                            className="sub-tit"
                            style={{ display: 'inline-block', marginRight: '4px' }}
                          >
                            ADDRESS
                          </strong>

                          {/* Selection Modal */}
                          {accountLists.address.length > 0 && (
                            <div
                              className="select-box sub-combo"
                              onClick={onToggleSelectBox}
                              onMouseOver={onMouseOver}
                              onMouseLeave={onMouseLeave}
                            >
                              <button
                                style={{ marginLeft: '5px' }}
                                className={visibleText === 'none' ? '' : 'on'}
                              >
                                {accountInfo.accountType}
                              </button>

                              <div className="select-options" style={{ display: visibleText }}>
                                <ul>
                                  {accountLists.address.map((addr, i) => (
                                    <AccountLists
                                      key={i}
                                      address={addr}
                                      visibleText={visibleText}
                                    />
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            fontWeight: 500,
                            fontSize: '12px',
                            lineHeight: '14px',
                            cursor: 'pointer',
                          }}
                        >
                          {!isTempUser.isLogin && <div onClick={onCreateTempAddress}>+ ADD</div>}
                        </div>
                      </div>
                      <div className="key-area">
                        <p
                          style={{
                            overflowY: accountInfo && !isTempUser.isLogin ? 'hidden' : 'hidden',
                            marginRight: '40px',
                          }}
                        >
                          {accountInfo && !isTempUser.isLogin
                            ? accountInfo.address
                            : isTempUser.publicKey}
                        </p>
                        <button
                          type="button"
                          className="copy"
                          onClick={onCopy(isTempUser.isLogin ? 'temp' : 'address')}
                          style={{ right: '28px', top: '20px' }}
                        >
                          <img src={COPY_IMG} alt="" />
                        </button>
                      </div>
                      {isTempUser.isLogin ? (
                        <div style={{ marginTop: '4px' }}>
                          <p className="ico-error left">
                            <Trans i18nKey={t(`section_seven_text_10`)} />
                          </p>
                        </div>
                      ) : null}
                    </div>

                    {/* Assets List */}
                    {isTempUser.isLogin ? (
                      <AssetsList
                        balances={[{ currency: 'PEN', amount: '0' }]}
                        isTempLogin={true}
                      />
                    ) : (
                      <AssetsList balances={accountInfo.balances} />
                    )}
                    {/*<div className="assets-box">*/}
                  </div>
                </div>
              </div>
            </div>
          </SectionLayout>

          <section className="popup">
            <div className="pop-wrap text-c error-key">
              <p className="ico-error">
                <i className="br">어드레스 또는 프라이빗 키를</i> 다시 한 번 확인해주세요.
              </p>
              <button type="button" className="btn-blue">
                확인
              </button>
            </div>

            <div className="pop-wrap text-c error-access">
              <p className="ico-error">
                <i className="br">접속에 실패하였습니다.</i>잠시 후 다시 시도하여 주세요.
              </p>
              <button type="button" className="btn-blue">
                확인
              </button>
            </div>
          </section>
        </ArticleWrapper>
      </div>
      <ModalPortal>
        <AddTempAddressModal closeModal={closeModal} addressLength={accountLists.address.length} />
      </ModalPortal>
    </main>

    // <div>
    //   <div>
    //     <div>{accountInfo.address}</div>
    //
    //     <div>
    //       <ul>
    //         {accountInfo.balances.map((b, i) => (
    //           <li>
    //             <span>{b.currency}</span>
    //             <span> </span>
    //             <span>{b.amount}</span>
    //           </li>
    //         ))}
    //       </ul>
    //     </div>
    //
    //     <div>
    //       <button onClick={onRefresh}>refresh</button>
    //     </div>
    //
    //     <div style={{ marginTop: '20px' }}>
    //       <button onClick={onChangePage('create')}>create</button>
    //       <button onClick={onChangePage('transfer')}>transfer</button>
    //     </div>
    //
    //     <div
    //       style={{
    //         border: '1px solid red',
    //         width: '100%',
    //         height: '500px',
    //         overflow: 'auto',
    //         marginTop: '20px',
    //       }}
    //     >
    //       {histories.length ? <TransactionHistory history={histories} /> : null}
    //     </div>
    //   </div>
    // </div>
  );
};

export default WalletPage;

const SectionLayout = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .select-box.sub-combo > div {
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .key-area p {
    height: auto;
  }

  @media (max-width: 1024px) {
    margin-top: 90px;
    display: block;

    .key-area p {
      width: 100%;
    }
    .sub-tit {
      font-weight: 700;
      line-height: 24px;
    }
    .cont-wrap {
      .cont-box {
        display: block;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: auto;
        padding: 0px 20px 32px;
      }
    }
    li.balance-item {
      /* box-shadow: none; */
    }
    .assets-box {
      margin-top: 20px;
    }
    .address-box {
      margin-top: 32px;
      position: relative;
    }

    .select-box.sub-combo > div {
      width: 354px;
      box-sizing: border-box;
      position: absolute;
      left: -72px;
      top: 32px;
    }
    .key-area p {
      height: auto;
    }
    .cont-body .assets-box li {
      padding: 16px;
    }
  }
`;
