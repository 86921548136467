export const routes = {
  LOGIN: '/login',
  WALLET: '/wallet',
  OPERATION: '/operation/:mode',
  RESPONSE: '/complete',
  KEY_GENERATE: '/key-generate',
  GET_ADDRESS: '/get-address',
  SETTING: '/setting',
  TRANSACTION: '/operation',
  CREATE_ADDRESS: '/create-address',
  CREATE_MAIN_ADDRESS: '/create-address/main',
  CREATE_ADDRESS_ETHER: '/create-address/ether',
  CREATE_ADDRESS_ETHER_DONE: '/create-address/ether/done',
  HOME: '/',
  CREATE_MULTI_SIG_ADDRESS: '/multi-sig/main',
  CREATE_MULTI_SIG_WALLET: '/multi-sig/create',
  MULTI_LOADING: '/multi-sig/loading',
  MULTI_SIG_RESPONSE: '/multi-sig/response',
  SIGN_OPERATION_LANDING: '/sign-operation/landing',
  SIGN_OPERATION_TRANSFER: '/sign-operation/transfer',
  MULTI_SIG_TRANSFER_RESPONSE: '/multi-sig/transfer/response',
  SIGN_OPERATION_PROCESS: '/sign-operation/process',
  SIGN_OPERATION_COMPLETE: '/sign-operation/complete',
  SIGN_OPERATION_LOADING: '/sign-operation/loading',
  TERM: '/setting/terms',
  PERSONAL_TERMS: '/setting/personal-terms',
};

export const OPER_CREATE_ACCOUNT = 'OPER-CREATE-ACCOUNT';
export const OPER_UPDATE_KEY = 'OPER-UPDATE-KEY';
export const OPER_TRANSFER = 'OPER-TRANSFER';
export const OPER_DEFAULT = 'OPER-DEFAULT';

export const ENQUEUE_OPERATION = 'ENQUEUE_OPERATION';

export const KEY_BTC = 'btc';
export const KEY_ETHER = 'ether';
export const KEY_STELLAR = 'stellar';

export const excludeHeaderPage = [''];

export const privateKeySuffix = 'mpr';
export const publicKeySuffix = 'mpu';
export const addressSuffix = 'mca';
