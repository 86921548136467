import { AlignCenterStyle } from '../../styles/common';
import useInput from '../../hooks/useInput';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isLoginAtom, networkAtom, networkTypeAtom, settingTimerAtom } from '../../store/atoms';
import { useEffect, useState } from 'react';
import IdleTimer from '../../lib/IdleTimer';
import SideBar from '../../components/layout/Sidebar';
import { log } from 'util';
import { useMaintainHook } from '../../hooks/useIsMaintainHook';
import { ArticleWrapper } from '../../styles/responsive';
import { routes } from '../../constant';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../hooks/useIsMobile';
import Header from '../../components/layout/Header';
import styled from 'styled-components';

const SettingPage = () => {
  const { t } = useTranslation();
  const [network, onChangeNetwork, setNetwork] = useInput('');
  const [networkId, onChangeNetworkId, setNetworkId] = useInput('');
  const [networkStore, setNetworkStore] = useRecoilState(networkAtom);
  const [networkType, setNetworkType] = useRecoilState(networkTypeAtom);
  const [logoutTimer, setLogoutTimer] = useRecoilState(settingTimerAtom);

  const [visibleLogo, setVisibleLogo] = useState('');

  const isMobile = useIsMobile();
  const isLogin = useRecoilValue(isLoginAtom);
  const { TERM, PERSONAL_TERMS } = routes;

  const [isTimeout, setIsTimeout] = useState(false);

  const onSetNetwork = () => {
    setNetworkStore({
      ...networkStore,
      network,
      networkAccount: `${network}/account/`,
      networkBroadcast: `${network}/builder/send`,
      networkSearchFact: `${network}/operation/`,
    });

    alert(`네트워크 주소 변경완료, 현재 네트워크 주소: ${network}`);
  };

  const onSetNetworkId = () => {
    setNetworkStore({
      ...networkStore,
      networkId,
    });
    alert(`네트워크 ID 주소 변경완료, 현재 네트워크 주소: ${networkId}`);
  };

  const onClearNetwork = () => {
    setNetworkStore({
      ...networkStore,
      network: process.env.REACT_APP_API_URL as string,
      networkAccount: process.env.REACT_APP_API_ACCOUNT as string,
      networkBroadcast: process.env.REACT_APP_API_BROADCAST as string,
      networkSearchFact: process.env.REACT_APP_API_SEARCH_FACT as string,
    });
    alert(`네트워크 주소 변경완료, 현재 네트워크 주소: ${process.env.REACT_APP_API_UR}`);
  };

  const onClearNetworkId = () => {
    setNetworkStore({
      ...networkStore,
      networkId: process.env.REACT_APP_NETWORK_ID as string,
    });
  };

  const onChangeNetworkType = (type: 'main' | 'test') => () => {
    setNetworkType(type);
  };

  const onChangeLogoutTimer = (type: 600 | 1800 | 3600) => () => {
    setLogoutTimer(type);
  };

  useEffect(() => {
    setNetwork(networkStore.network);
    setNetworkId(networkStore.networkId);
  }, []);

  useMaintainHook();
  return (
    <>
      <main>
        {isMobile && <Header />}
        <SectionLayout>
          <div className="cont-wrap">
            <div className="middle-inner">
              <div className="cont-box">
                {!isMobile && (
                  <SideBar
                    visible={visibleLogo}
                    setVisible={setVisibleLogo}
                    isLogin={isLogin}
                    activeLogo={1000}
                    bottomLogo={0}
                  />
                )}
                <div className="cont-body settings">
                  <strong className="tit">Settings</strong>
                  <ul>
                    <li className="setting-item">
                      <strong>{t(`info_text_4`)}</strong>

                      <div className="radio-box">
                        <input
                          type="radio"
                          id="network01"
                          name="network"
                          defaultChecked={networkType === 'main'}
                          onClick={onChangeNetworkType('main')}
                        />
                        <label htmlFor="network01">{t(`main_net_text`)}</label>
                        <input
                          type="radio"
                          id="network02"
                          name="network"
                          defaultChecked={networkType === 'test'}
                          onClick={onChangeNetworkType('test')}
                        />
                        <label htmlFor="network02">{t(`test_net_text`)}</label>
                      </div>
                    </li>
                    <li className="setting-item">
                      <strong>{t(`info_text_9`)}</strong>
                      <div className="radio-box timer-box">
                        <input
                          type="radio"
                          id="timer01"
                          name="timer"
                          defaultChecked={logoutTimer === 600}
                          onClick={onChangeLogoutTimer(600)}
                        />
                        <label htmlFor="timer01">{t(`info_text_6`)}</label>
                        <input
                          type="radio"
                          id="timer02"
                          name="timer"
                          defaultChecked={logoutTimer === 1800}
                          onClick={onChangeLogoutTimer(1800)}
                        />
                        <label htmlFor="timer02" className="center-line">
                          {t(`info_text_7`)}
                        </label>
                        <input
                          type="radio"
                          id="timer03"
                          name="timer"
                          defaultChecked={logoutTimer === 3600}
                          onClick={onChangeLogoutTimer(3600)}
                        />
                        <label htmlFor="timer03">{t(`info_text_8`)}</label>
                      </div>
                    </li>
                    <li className="top-line">
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          alert('support@protocon.com 으로 문의해주시기 바랍니다.');
                        }}
                      >
                        <strong>{t(`section_four_text_2`)}</strong>
                      </a>
                    </li>
                    <li>
                      <Link to={TERM}>
                        <strong>{t(`section_five_text_1`)}</strong>
                      </Link>
                    </li>
                    <li>
                      <Link to={PERSONAL_TERMS}>
                        <strong>{t(`section_five_text_2`)}</strong>
                      </Link>
                    </li>
                    <li>
                      <strong>{t(`info_text_10`)}</strong>
                      <span>Protocon Wallet Ver. {process.env.REACT_APP_VERSION}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </SectionLayout>
      </main>
    </>
  );
};

export default SettingPage;

const SectionLayout = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 1024px) {
    padding-top: 64px;
    box-sizing: border-box;
    align-items: flex-start;
    width: 100%;
    .cont-wrap {
      .cont-box {
        display: block;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .cont-body {
        width: 100%;
        padding: 0px 20px 32px;
        height: 100%;
        overflow-y: auto;
      }
    }

    .tit {
      padding-top: 28px;
    }
    .tit + div {
      margin-top: 24px;
    }
    .tit + div p {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #777e90;
    }

    .settings ul {
      margin-top: 32px;
    }
  }
`;
