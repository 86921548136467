import SIDE_WALLET from '../../assets/images/ico_side-wallet.png';
import SIDE_KEY from '../../assets/images/ico_side-key.png';
import SIDE_QR_CODE from '../../assets/images/ico_side-qr-code.png';
import SIDE_SIGN from '../../assets/images/ico_side-sign.png';
import SIDE_SWAP from '../../assets/images/ico_side-swap.png';
import MULTI_SIG from '../../assets/images/ico_side-multisig.png';

import { Link } from 'react-router-dom';
import React, { SetStateAction, VFC } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { accountInfoAtom, isNewTransactionAtom, isTempLoginAtoms } from '../../store/atoms';
import { routes } from '../../constant';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: string;
  setVisible: React.Dispatch<SetStateAction<string>>;
  defaultOn: number;
  isLogin?: boolean;
}

const LinkWrapper = styled(Link)`
  > em {
    opacity: 0.5;
  }
  :hover {
    > em {
      opacity: 1;
    }
  }
`;

const LogoList: VFC<Props> = ({ visible, setVisible, defaultOn, isLogin = false }) => {
  const accountInfo = useRecoilValue(accountInfoAtom);
  const isTempUser = useRecoilValue(isTempLoginAtoms);
  const [isNewTransaction, setIsNewTransactionAtom] = useRecoilState(isNewTransactionAtom);
  const { t } = useTranslation();
  const {
    LOGIN,
    WALLET,
    OPERATION,
    RESPONSE,
    KEY_GENERATE,
    GET_ADDRESS,
    HOME,
    SETTING,
    TRANSACTION,
    CREATE_ADDRESS,
    CREATE_MULTI_SIG_ADDRESS,
    SIGN_OPERATION_LANDING,
  } = routes;
  const sideBarList = [
    { text: `${t(`info_text_46`)}`, img: SIDE_WALLET, redirect: LOGIN },
    // { text: 'Generate Keypair', img: SIDE_KEY, redirect: KEY_GENERATE },
    { text: `${t(`home_one_text_3`)}`, img: SIDE_QR_CODE, redirect: CREATE_ADDRESS },
    // { text: 'SETTINGS', img: SIDE_SETTING },
  ];

  const loginSidebar = [
    {
      text: `${t(`info_text_1`)}`,
      img: SIDE_WALLET,
      redirect: isTempUser.isLogin ? `/wallet` : `/wallet`,
    },
    // { text: 'Sign Operation', img: SIDE_SIGN, redirect: HOME },
    { text: `${t(`info_text_3`)}`, img: SIDE_SWAP, redirect: TRANSACTION },
    {
      text: `${t(`MULTI_SIG_text`)}`,
      img: MULTI_SIG,
      redirect:
        accountInfo.accountType === 'multi' ? SIGN_OPERATION_LANDING : CREATE_MULTI_SIG_ADDRESS,
    },

    // { text: 'Generate Keypair', img: SIDE_KEY, redirect: KEY_GENERATE },
    // { text: 'Get Address', img: SIDE_QR_CODE, redirect: HOME },
  ];

  const isTempLoginSidebar = [
    {
      text: `${t(`info_text_1`)}`,
      img: SIDE_WALLET,
      redirect: isTempUser.isLogin ? `/wallet` : `/wallet`,
    },
    // { text: 'Sign Operation', img: SIDE_SIGN, redirect: HOME },
    { text: `${t(`info_text_3`)}`, img: SIDE_SWAP, redirect: TRANSACTION },
    // { text: '멀티시그 생성하기', img: MULTI_SIG, redirect: CREATE_MULTI_SIG_ADDRESS },

    // { text: 'Generate Keypair', img: SIDE_KEY, redirect: KEY_GENERATE },
    // { text: 'Get Address', img: SIDE_QR_CODE, redirect: HOME },
  ];

  const renderItem = isTempUser.isLogin ? isTempLoginSidebar : isLogin ? loginSidebar : sideBarList;
  return (
    <>
      {renderItem.map((s, i) => {
        return (
          <li
            key={i}
            className={i === 0 ? 'on' : 'on'}
            onMouseOver={() => {
              setVisible(s.text);
            }}
            onMouseLeave={() => {
              setVisible('');
            }}
            onClick={() => {
              if (s.text === `${t(`info_text_3`)}`) {
                setIsNewTransactionAtom(false);
              }
            }}
          >
            <LinkWrapper to={s.redirect}>
              <img
                src={s.img}
                alt={s.text}
                style={{ opacity: defaultOn === i ? 1 : s.text === visible ? 1 : 0.7 }}
              />
              {s.text === `${t(`info_text_3`)}` && isNewTransaction && <em className="transmit" />}
            </LinkWrapper>
            <div
              className="bubble"
              style={{
                display: visible === s.text ? 'block' : 'none',
              }}
            >
              {s.text}
            </div>
          </li>
        );
      })}
    </>
  );
};

export default LogoList;
