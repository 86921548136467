import React, { FC, useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { useRecoilValue } from 'recoil';
import { isLoginAtom, settingTimerAtom } from '../../store/atoms';

const IdleTimerContainer: FC = ({ children }) => {
  const idleTimerRef = useRef(null)!;
  const logoutTimer = useRecoilValue(settingTimerAtom);
  const isLogin = useRecoilValue(isLoginAtom);
  console.log(logoutTimer, 'setting');
  const onLogout = () => {
    if (isLogin) {
      console.log('logout');
      alert('자동 잠금 시간이 만료되었습니다.');
      window.location.href = '/login';
    }
  };

  const onIdle = () => {
    onLogout();
  };

  return (
    <>
      <IdleTimer ref={idleTimerRef as any} timeout={logoutTimer * 1000} onIdle={onIdle}>
        {children}
      </IdleTimer>
    </>
  );
};

export default IdleTimerContainer;
