import {
  broadcast,
  checkFeeAmount,
  getAccountHistory,
  getAccountInformation,
  isCompletedSignOperation,
} from '../service/account';
import bigInt from 'big-integer';
import web3 from 'web3';
import { SetterOrUpdater } from 'recoil';
import {
  AccountHistoryResBody,
  AccountInfo,
  BalanceHistory,
  Fact,
  FactSigns,
  KeysChild,
  SignOperation,
} from '../types/account';
import { accountInfoUtil, setHistoryUtil } from './manufactureStore';
import { OPER_CREATE_ACCOUNT, OPER_TRANSFER } from '../constant';
import hint from '../text/hint.json';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { json } from 'stream/consumers';
import axios from 'axios';
import { Network, OperationResult } from '../types/operation';
import BIT_COIN_IMG from '../assets/images/ico_assets-coin.png';
import MILE_IMG from '../assets/images/ico_assets-mile.png';
import TOKEN_IMG from '../assets/images/ico_assets-token.png';
import { routes } from '../constant';
import { ReactComponent as PRO_COIN_IMG } from '../assets/images/protocon_coin.svg';

export function parsePrice(price: string) {
  if (!price) {
    return ``;
  }

  return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}
//

export async function calcAcceptableFeeToken(base: string, current: string, currency: string) {
  console.log(base, current, currency, 123123123);
  const fee: any = await checkFeeAmount('main', currency);
  const convertToBaseAmount = convertToOriginalAmount(base);
  const convertCalcAmount = convertToOriginalAmount(current);
  const convertCalcMinBalance = convertToOriginalAmount(
    convertToDecimalAmount(fee._embedded.policy.new_account_min_balance)
  );

  const compareMinimumAmount = compareCoin(
    convertToOriginalAmount(convertCalcAmount),
    convertCalcMinBalance
  );

  const sumCoinAndFee = bigInt(convertToOriginalAmount(convertCalcAmount))
    .add(bigInt(convertCalcMinBalance))
    .toJSON();

  const convertFee = convertToDecimalAmount(fee._embedded.policy.feeer.amount) as string;

  console.log(123123123123);
  console.log(
    convertCalcAmount,
    convertCalcMinBalance,
    compareCoin(convertToBaseAmount, sumCoinAndFee),
    convertFee,
    compareMinimumAmount,
    convertToDecimalAmount(fee._embedded.policy.new_account_min_balance)
  );
  return [
    compareCoin(convertToBaseAmount, sumCoinAndFee),
    convertFee as string,
    compareMinimumAmount,
    convertToDecimalAmount(fee._embedded.policy.new_account_min_balance),
  ];
}

export function isHaveSameCoin(coinArr: { currency: string; fee: string }[], newCoin: string) {
  let checkCoin = false;
  coinArr.forEach((coin) => {
    const key = Object.values(coin);
    if (key[0] === newCoin) {
      checkCoin = true;
      return;
    }
  });
  return checkCoin;
}

export const convertCoinAmount = (coin: string) => {
  const bn = web3.utils.fromWei(coin, 'ether');
  let prefix = '';
  let suffix = '';
  if (bn.includes('.')) {
    const buffer = bn.split('.');
    prefix = parsePrice(buffer[0]);
    suffix = buffer[1].slice(0, 8);
    return prefix + '.' + suffix;
  }
  return parsePrice(bn);
};

export const addRestDotNumber = (amount: string) => {
  if (amount.includes('.')) {
    const buffer = amount.split('.');
    const prefix = parsePrice(buffer[0]);
    const suffix = buffer[1];
    return prefix + '.' + suffix;
  }
  return parsePrice(amount);
};

export const compareCoin = (base: string, current: string) => {
  // base = current 0 number Type
  // base > current 1
  // base < current -1
  const compareResult = bigInt(base).compare(current);
  return compareResult >= 0;
};

export const convertToOriginalAmount = (amount: string) => {
  return web3.utils.toWei(amount, 'ether');
};

export const convertToDecimalAmount = (amount: string): string => {
  return web3.utils.fromWei(amount, 'ether');
};

export const convertToOriginalOnlyNumberType = (coin: string) => {
  return String(bigInt(coin).multiply(bigInt(10).pow(18)).valueOf());
};

export const changeAccountType = async (
  setHistory: SetterOrUpdater<BalanceHistory[]>,
  address: string,
  networkType: 'main' | 'test',
  publicKey: string,
  privateKey: string,
  baseAccountInfo: AccountInfo,
  setAccountInfo: SetterOrUpdater<AccountInfo>
) => {
  let accountHistory: AccountHistoryResBody;
  let accountInfoResult: { account: AccountInfo };

  const account = await getAccountInformation(address, networkType);
  accountHistory = await getAccountHistory(address, networkType);
  accountInfoResult = accountInfoUtil(address, privateKey, publicKey, account);
  const history = setHistoryUtil(accountHistory, address);

  const { account: accountInfo } = accountInfoResult;
  console.log(accountInfo, 456456);
  if (baseAccountInfo.address === accountInfo.address) {
    return;
  }

  setAccountInfo({
    ...baseAccountInfo,
    accountType: accountInfo.accountType,
    address: accountInfo.address,
    balances: accountInfo.balances,
    publicKeys: accountInfo.publicKeys,
  });
  setHistory(history!);
};

export const getAccountType = async (
  address: string,
  publicKey: string,
  privateKey: string,
  networkType: 'main' | 'test'
) => {
  let accountInfoResult: { account: AccountInfo };

  const account = await getAccountInformation(address, networkType);
  accountInfoResult = accountInfoUtil(address, privateKey, publicKey, account);

  const { account: accountInfo } = accountInfoResult;
  console.log(accountInfo, 'accoundasjnd');
  return accountInfo.accountType;
};

export const checkSignOperation = (fact: any, publicKeys: { key: string; weight: number }[]) => {
  let count = 0;
  const keys = publicKeys.map((k) => k.key);

  fact.forEach((f: any) => {
    if (keys.includes(f.signer)) {
      count++;
    }
  });
  return count === publicKeys.length;
};

export const onSend = async (
  json: any,
  operation: string,
  setOperationResultState: SetterOrUpdater<OperationResult>,
  notify: any,
  history: any
) => {
  const { RESPONSE } = routes;
  try {
    console.log(123, operation);
    const result = await broadcast(json, 'main');
    if (result) {
      let data;
      switch (operation) {
        case OPER_CREATE_ACCOUNT:
          data = json.fact.items.map(
            (x: any) => x.keys.hash + ':' + hint.address + '-' + process.env.REACT_APP_VERSION
          );
          break;
        case OPER_TRANSFER:
        default:
          data = undefined;
          break;
      }
      setOperationResultState({
        isBroadcast: true,
        isStateIn: false,
        res: result,
        data,
        status: 200,
      });
      history.replace(RESPONSE);
    } else {
      notify('네트워크 에러가 발생했습니다.');
      return;
    }
  } catch (e) {
    notify(e.response.data.title)();
    notify(e.response.data);
    return;
    try {
      setOperationResultState({
        isBroadcast: false,
        isStateIn: false,
        res: e.response.data,
        data: undefined,
        status: e.response.data.status,
      });
    } catch (err) {
      setOperationResultState({
        isBroadcast: false,
        isStateIn: false,
        res: { title: '404 network error ' },
        data: undefined,
        status: 404,
      });
    }
  }
};

export const removeMySignOperation = (myPublicKey: string, operation: any) => {
  const result = operation.fact_signs.filter((s: any) => s.signer !== myPublicKey);
  return JSON.parse(JSON.stringify(result));
};

export const addSignOperation = (base: SignOperation, signArray: SignOperation[]) => {
  const bufferArray: FactSigns[] = [];
  signArray.forEach((s) => {
    s.fact_signs.forEach((f) => {
      bufferArray.push(f);
    });
  });
  return { ...base, fact_signs: bufferArray };
};

export const isCompletedSignsImportFile = (
  publicKeys: { key: string; weight: number }[],
  parsed: SignOperation
) => {
  let count = 0;
  const pubKeys: string[] = [];
  publicKeys.forEach((pb) => {
    pubKeys.push(Object.values(pb)[0] as string);
  });
  parsed.fact_signs.forEach((fa) => {
    if (pubKeys.includes(fa.signer)) {
      count++;
    }
  });

  return pubKeys.length === count;
};

export const isValidationImportJsonFile = async (
  network: Network,
  networkType: 'main' | 'test',
  operation: SignOperation[]
) => {
  if (operation.length === 1) {
    let isCompleted = false;

    try {
      const result = await isCompletedSignOperation(networkType, operation[0].fact.hash);
      isCompleted = result._embedded.in_state;
    } catch (err) {
      isCompleted = false;
    }
    return [isCompleted, '전송이 완료된 JSON 파일입니다. 다시 확인 해주세요.'];
  } else {
    let isDifferent = false;
    operation.reduce((acc, cur) => {
      if (acc.fact.hash !== cur.fact.hash) {
        isDifferent = true;
        return cur;
      }
      return cur;
    });
    return [
      isDifferent,
      '동일한 서명파일이 아닙니다. Fact hash가 같은 서명 파일을 업로드해주세요 :)',
    ];
  }

  // const jsonFile: SignOperation[] = operation;
  // const validationAddress: string[] = [];
  // let check = false;
  // jsonFile.forEach((fact) => {
  //   fact.fact.items.forEach((item) => {
  //     if (!item.receiver) {
  //       validationAddress.push(item.keys.hash);
  //       return;
  //     }
  //     validationAddress.push(item.receiver);
  //   });
  // });
  // validationAddress.reduce((acc, cur) => {
  //   if (acc !== cur) {
  //     check = true;
  //   }
  //   return acc;
  // });
  // return check;
};

export const IsItPossibleToSendToken = (
  keys: KeysChild[],
  factSigns: FactSigns[],
  threshold: number
) => {
  let bufferArray: number[] = [];
  keys.forEach((k) => {
    factSigns.forEach((f) => {
      if (k.key === f.signer) {
        bufferArray.push(k.weight);
      }
    });
  });

  const weightSum = bufferArray.reduce((acc, cur) => {
    return acc + cur;
  });

  console.log(weightSum, threshold);

  return weightSum >= threshold;
};

export const selectImageFunction = (url: string) => {
  switch (url) {
    case 'CWC':
      return 'https://walletinfo.crypworld.network/cwc.svg';
    case 'CWG':
      return 'https://walletinfo.crypworld.network/cwg.svg';
    case 'CWM':
      return ' https://walletinfo.crypworld.network/cwm.svg';
    case 'PEN':
      return 'https://play-license.s3.ap-northeast-2.amazonaws.com/protocon.svg';

    default:
      return 'https://walletinfo.crypworld.network/cwg.svg';
    // default:
    //   return 'https://walletinfo.crypworld.network/cwg.svg';
  }
};
