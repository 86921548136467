import { SetterOrUpdater } from 'recoil';
import { AccountInfo, BalanceHistory } from '../../types/account';
import { VFC } from 'react';
import { loginHandler } from '../../utils/onLogin';
import { Network } from '../../types/operation';
import { Trans, useTranslation } from "react-i18next";
import { ButtonHover, GrayButtonHover } from "../Button/BasicHover";

interface Props {
  privateKey: string;
  openModal: any;
  networkType: 'main' | 'test';
  setIsTempUser: SetterOrUpdater<{ isLogin: boolean; publicKey: string }>;
  setIsLogin: SetterOrUpdater<boolean>;
  router: any;
  networkState: Network;
  setAccountInfo: SetterOrUpdater<AccountInfo>;
  setHistory: SetterOrUpdater<BalanceHistory[]>;
  confirmCloseModal: () => void;
}

const ConfirmLoginModal: VFC<Props> = ({
  privateKey,
  openModal,
  networkType,
  setIsTempUser,
  setIsLogin,
  router,
  networkState,
  setAccountInfo,
  setHistory,
  confirmCloseModal,
}) => {
  const { t } = useTranslation()
  return (
    <section className="popup" style={{ display: 'block' }}>
      <div className="pop-wrap text-c error-key">
        <p className="ico-error">
          <Trans i18nKey="info_text_16" />
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <GrayButtonHover
            type="button"
            className="btn-type01"
            style={{ background: '#dee2e6', color: '#000', marginRight: '10px' }}
            onClick={confirmCloseModal}
          >
            {t(`close_Text`)}
          </GrayButtonHover>

          <ButtonHover
            type="button"
            className="btn-type01"
            onClick={loginHandler(
              privateKey,
              openModal,
              networkType,
              setIsTempUser,
              setIsLogin,
              router,
              networkState,
              setAccountInfo,
              setHistory
            )}
          >
            {t(`Ok_Text`)}
          </ButtonHover>
        </div>
      </div>
    </section>
  );
};

export default ConfirmLoginModal;
