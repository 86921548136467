import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Link, useHistory } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
// @ts-ignore
import { toKeypair, getKeypairFromPrivateKey } from 'mitumc';

import { isAddressValid, isPrivateKeyValid } from '../../lib/Vadation';
import {
  accountInfoAtom,
  historyAtom,
  isLoginAtom,
  isTempLoginAtoms,
  networkAtom,
  networkTypeAtom,
} from '../../store/atoms';
import {
  getAccountHistory,
  getAccountInformation,
  getGenesisAccountInfo,
  getPubAccounts,
  isCheckTempUser,
} from '../../service/account';
import useInput from '../../hooks/useInput';
import { accountInfoUtil, setHistoryUtil } from '../../utils/manufactureStore';
import {
  AccountHistoryResBody,
  AccountInfo,
  AccountInfoResBody,
  BalanceHistory,
} from '../../types/account';

// import SIDE_LOGO from '../../assets/images/logo_side.png';
import SIDE_WALLET from '../../assets/images/ico_side-wallet.png';
import SIDE_KEY from '../../assets/images/ico_side-key.png';
import BG_PATH from '../../assets/images/bg_path.png';
import SIDE_QR_CODE from '../../assets/images/ico_side-qr-code.png';
import SIDE_SETTING from '../../assets/images/ico_side-setting.png';
import LogoList from '../../components/sidebar/LogoList';
import NEW_SIDE_LOGO from '../../assets/images/protoconwallet-logo.svg';
import LOCK_IMG from '../../assets/images/ico_lock.png';
import EYE_IMG from '../../assets/images/ico_eye.png';
import useModal from '../../hooks/useModal';
import LoginErrorModal from '../../components/Modal/LoginError';
import NetworkErrorModal from '../../components/Modal/NetworkError';
import { routes } from '../../constant';
import { useMaintainHook } from '../../hooks/useIsMaintainHook';
import { convertCoinAmount } from '../../utils/helper';
import { ArticleWrapper } from '../../styles/responsive';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { basicPrimaryHoverStyle } from '../../styles/common';
import {
  BasicHoverBtn,
  BasicHoverLinkText,
  BasicHoverText,
} from '../../components/Button/BasicHover';

const LoginPage = () => {
  const [accountAddressInput, onChangeAccountAddressInput] = useInput('');
  const [privateKeyInput, onChangePrivateInput] = useInput('');
  const [inputType, setInputType] = useState('password');
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [history, setHistory] = useRecoilState(historyAtom);
  const router = useHistory();
  const [visibleLogo, setVisibleLogo] = useState('');
  const [visibleSettingLogo, setVisibleSettingLogo] = useState('none');

  const [currentLangSelect, setCurrentLangSelect] = useState('ENG');

  const networkType = useRecoilValue(networkTypeAtom);
  const networkState = useRecoilValue(networkAtom);
  const [isTempUser, setIsTempUser] = useRecoilState(isTempLoginAtoms);
  const { ModalPortal, openModal, closeModal } = useModal();

  const { t } = useTranslation();

  const {
    ModalPortal: LoginPortal,
    openModal: loginOpenModal,
    closeModal: loginCloseModal,
  } = useModal();

  const { CREATE_ADDRESS } = routes;

  const onLogin = async () => {
    const accountAddr = accountAddressInput.trim();
    const privateKey = privateKeyInput.trim();
    if (!isPrivateKeyValid(privateKey)) {
      openModal();
      return;
    }

    let accountHistory: AccountHistoryResBody;
    let accountInfo: AccountInfoResBody;
    let pubkey;
    let accountInfoResult: { account: AccountInfo };

    try {
      pubkey = getKeypairFromPrivateKey(privateKey).getPublicKey();
      const checkTempLogin = await isCheckTempUser(pubkey, networkType);

      if (checkTempLogin._embedded === null) {
        setIsTempUser({
          isLogin: true,
          publicKey: `${pubkey}?threshold=100&weight=100`,
        });
        setIsLogin(true);
        router.push(`/wallet`);
        return;
      }
    } catch (err) {
      console.log(err);
      openModal();
      return;
    }

    try {
      const pubAccounts = await getPubAccounts(
        pubkey,
        networkState.networkPubAccounts,
        networkType
      );
      if (!pubAccounts._embedded) {
        openModal();
        return;
      }

      const pubResult = await pubAccounts._embedded.map((x) => {
        return x._embedded.address;
      });
      const account = await getAccountInformation(pubResult[0], networkType);
      accountHistory = await getAccountHistory(account._embedded.address, networkType);
      accountInfoResult = accountInfoUtil(account._embedded.address, privateKey, pubkey, account);
      const historyResult = setHistoryUtil(accountHistory, account._embedded.address);
      const { account: accountInfo } = accountInfoResult!;

      // accountInfoResult.account.balances = accountInfoResult.account.balances.map((bal) => {
      //   return { currency: bal.currency, amount: convertCoinAmount(bal.amount) };
      // });
      setAccountInfo({
        address: accountInfo.address,
        accountType: accountInfo.accountType,
        balances: accountInfo.balances,
        privateKey: accountInfo.privateKey,
        publicKey: pubkey,
        publicKeys: accountInfo.publicKeys,
        // restoreKey: accountInfo.restoreKey
      });

      // setAccountInfo(accountInfoResult!.account);

      setHistory(historyResult!);
      setIsLogin(true);

      router.push(`/wallet`);
    } catch (err) {
      console.log(err, 'err');
      try {
        if (err.response.status === 404) {
          const pubAccounts = await getPubAccounts(
            pubkey,
            networkState.networkPubAccounts,
            networkType
          );

          if (!pubAccounts._embedded) {
            openModal();
            return;
          }

          const pubResult = await pubAccounts._embedded.map((x) => {
            return x._embedded.address;
          });

          const account = await getGenesisAccountInfo(networkType, pubResult[0]);
          // accountHistory = await getAccountHistory(account._embedded.address, networkType);
          accountInfoResult = accountInfoUtil(
            account._embedded.address,
            privateKey,
            pubkey,
            account
          );

          // const historyResult = setHistoryUtil(accountHistory, account._embedded.address);
          const { account: accountInfo } = accountInfoResult!;

          // setAccountInfo(accountInfoResult!.account);
          setAccountInfo({
            address: accountInfo.address,
            accountType: accountInfo.accountType,
            balances: accountInfo.balances,
            privateKey: accountInfo.privateKey,
            publicKey: pubkey,
            publicKeys: accountInfo.publicKeys,
            // restoreKey: accountInfo.restoreKey
          });

          // setHistory(historyResult!);
          setIsLogin(true);
          router.push(`/wallet`);
          return;
        }
      } catch (err) {
        openModal();
        return;
      }
    }
  };

  useEffect(() => {
    if (isLogin) {
      router.push(`/wallet`);
    }
  }, []);
  useMaintainHook();
  return (
    <Container>
      <div id="contents">
        <ArticleWrapper>
          <section>
            <div className="cont-wrap open-wallet">
              <div className="middle-inner">
                <div className="cont-box create-address-box">
                  <div className="side">
                    <Link to="/" className="logo">
                      <img src={NEW_SIDE_LOGO} alt="" />
                    </Link>
                    <ul className="top">
                      <LogoList visible={visibleLogo} setVisible={setVisibleLogo} defaultOn={0} />
                    </ul>
                    <div className="bottom">
                      <ul>
                        <li
                          onMouseOver={() => {
                            setVisibleSettingLogo('block');
                          }}
                          onMouseLeave={() => {
                            setVisibleSettingLogo('none');
                          }}
                        >
                          <Link to="/setting">
                            <img src={SIDE_SETTING} alt="" />
                          </Link>
                          <div className="bubble" style={{ display: visibleSettingLogo }}>
                            {t(`info_text_4`)}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="cont-body create-address-comment">
                    <span className="lang">
                      <a
                        className="eng"
                        style={{
                          cursor: 'pointer',
                          opacity: currentLangSelect === 'ENG' ? 1 : 0.4,
                        }}
                        onClick={() => {
                          setCurrentLangSelect('ENG');
                          i18n.changeLanguage('en');
                        }}
                      >
                        ENG
                      </a>
                      <a
                        className="kor"
                        style={{
                          cursor: 'pointer',
                          opacity: currentLangSelect === 'KOR' ? 1 : 0.4,
                        }}
                        onClick={() => {
                          setCurrentLangSelect('KOR');
                          i18n.changeLanguage('ko');
                        }}
                      >
                        KOR
                      </a>
                    </span>

                    <strong className="tit">Open Wallet</strong>
                    <p className="create-address-comment login-comment ">
                      {t(`section_three_text_3`)}
                    </p>
                    <p className="desc">{t(`info_text_29`)}</p>
                    <div className="input-box" style={{ marginTop: '48px' }}>
                      <span className="input-password" style={{ display: 'block' }}>
                        <input
                          style={{ outline: 'none' }}
                          type={inputType}
                          placeholder={t(`info_text_2`)}
                          value={privateKeyInput}
                          onChange={onChangePrivateInput}
                        />
                        {inputType === 'password' ? (
                          <i
                            className="ico-input ico-lock"
                            onClick={() => {
                              setInputType('text');
                            }}
                          >
                            <img src={LOCK_IMG} alt="" />
                          </i>
                        ) : (
                          <button
                            type="button"
                            style={{ display: 'block' }}
                            className="ico-input ico-eye"
                            onClick={() => {
                              setInputType('password');
                            }}
                          >
                            <img src={EYE_IMG} alt="" />
                          </button>
                        )}
                      </span>
                    </div>
                    <div>
                      <BasicHoverBtn
                        className="wallet-open btn-type01"
                        onClick={onLogin}
                        style={{ marginTop: '16px' }}
                      >
                        {t(`info_text_1`)}
                      </BasicHoverBtn>
                    </div>

                    <span className="first-visit" style={{ marginTop: '24px' }}>
                      {t(`home_one_text_4`)}{' '}
                      <BasicHoverLinkText to={CREATE_ADDRESS}>
                        {t(`home_one_text_3`)}
                      </BasicHoverLinkText>
                    </span>

                    <div className="btn-wrap btn-bottom">
                      <BasicHoverText
                        style={{ cursor: 'pointer' }}
                        className="create-wallet"
                        href="https://socialinfratech.notion.site/socialinfratech/How-to-create-a-wallet-3acfae892b094a98b2bd62f4bb61763a"
                        target="_blank"
                      >
                        {t(`info_text_45`)}
                      </BasicHoverText>
                      <BasicHoverText
                        style={{ cursor: 'pointer' }}
                        className="divider"
                        href="https://socialinfratech.notion.site/socialinfratech/How-to-send-receive-tokens-105cf4cbae7d43c5bf4d097461ada475"
                        target="_blank"
                      >
                        {t(`info_text_44`)}
                      </BasicHoverText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <ModalPortal>
            <LoginErrorModal closeModal={closeModal} />
          </ModalPortal>

          <LoginPortal>
            <NetworkErrorModal closeModal={loginCloseModal} />
          </LoginPortal>
        </ArticleWrapper>
      </div>
    </Container>
  );
};

export default LoginPage;

const Container = styled.main`
  overflow: hidden;
  .btn-wrap.btn-bottom {
    display: flex;
    justify-content: center;
  }
  .wallet-open.btn-type01 {
    margin-bottom: 132px;
  }
  @media (max-width: 1024px) {
    height: 120vh;
    .btn-wrap.btn-bottom {
      padding-top: 42px;
    }
    .wallet-open.btn-type01 {
      margin-bottom: 0px;
    }
    #contents {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    article {
      width: 100%;
    }
    .cont-wrap .cont-body {
      height: 90%;
      max-width: 520px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      padding: 20px;
    }

    .open-wallet .tit + p {
    }
  }
`;
