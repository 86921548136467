import { VFC } from 'react';
import { Trans } from 'react-i18next';

interface Props {
  closeModal: () => void;
}

const LoginErrorModal: VFC<Props> = ({ closeModal }) => {
  return (
    <section className="popup" style={{ display: 'block' }}>
      <div className="pop-wrap text-c error-key">
        <p className="ico-error">
          <Trans i18nKey="info_text_39" />
        </p>
        <button type="button" className="btn-type01" onClick={closeModal}>
          확인
        </button>
      </div>
    </section>
  );
};

export default LoginErrorModal;
