import { useRecoilValue } from 'recoil';
import { accountInfoAtom, operationAtom, setResultAtom } from '../../../store/atoms';
import { useEffect, useState } from 'react';
import SideBar from '../../../components/layout/Sidebar';
import { Link } from 'react-router-dom';
import CLEAR_IMG from '../../../assets/images/ico_checkmark.png';
import RED_IMG from '../../../assets/images/ico_clear-red.png';
import download from '../../../lib/Url';
import { convertCoinAmount } from '../../../utils/helper';
import { useIsLogin } from '../../../hooks/useIsLogis';
import { useMaintainHook } from '../../../hooks/useIsMaintainHook';
import hint from '../../../text/hint.json';
import { useTranslation } from 'react-i18next';

const MultiSigTransferResponsePage = () => {
  const { t } = useTranslation();
  const accountInfo = useRecoilValue(accountInfoAtom);
  const operation = useRecoilValue(operationAtom);
  const [visibleLogo, setVisibleLogo] = useState('');
  const [_items, setItems] = useState<any>([]);
  const [_hash, setHash] = useState('');
  const [signer, setSigner] = useState<Array<string>>([]);
  const operationResult = useRecoilValue(setResultAtom);

  const { data } = operationResult;

  const readJsonFile = () => {
    const fileUploaded = new FileReader();
    // fileUploaded.readAsText(operation.download, 'utf-8');
  };

  useEffect(() => {
    if (operation.json) {
      const { download } = operation;
      const {
        json: {
          fact: { items },
        },
      } = operation;

      const {
        json: {
          fact: { hash },
        },
      } = operation;
      const signerResult: Array<string> = [];
      operation.json.fact_signs.forEach((s: any) => {
        signerResult.push(s.signer);
      });
      console.log(signerResult);
      setSigner(signerResult);

      setItems(items);
      setHash(hash);
    }
  }, []);

  useIsLogin();
  useMaintainHook();
  console.log(operation, _items);
  return (
    <main>
      <div id="contents">
        <article>
          <section>
            <div className="cont-wrap transfer completed">
              <div className="middle-inner">
                <div className="cont-box">
                  <SideBar
                    visible={visibleLogo}
                    setVisible={setVisibleLogo}
                    isLogin={true}
                    activeLogo={0}
                  />

                  <div className="cont-body">
                    <Link to={`/wallet`}>
                      <strong className="tit back">
                        Transfer{' '}
                        {accountInfo.accountType === 'multi' ? (
                          <em style={{ color: '#3772ff' }}>Multi</em>
                        ) : null}
                      </strong>
                    </Link>
                    <h3>Completed 🎉</h3>
                    <div className="transfer-wrap">
                      <ul>
                        <li>
                          <span className="small-tit">Sign Information</span>
                          <div className="sign-info">
                            <div className="multiSign-info">
                              <div>
                                <strong>Participants</strong>
                                <ul>
                                  {accountInfo.publicKeys.map((p) => (
                                    <li key={p.key}>
                                      {p.key === accountInfo.publicKey ? 'Me' : 'Participant'}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div>
                                <strong>Signature</strong>
                                <ul>
                                  {accountInfo.publicKeys.map((p) => (
                                    <li key={p.key}>
                                      <img
                                        src={signer.includes(p.key) ? CLEAR_IMG : RED_IMG}
                                        alt=""
                                      />
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <span className="small-tit">Receiver’s Address</span>
                          <div className="text-line">
                            {_items.length > 0 && _items[0].receiver
                              ? _items[0].receiver
                              : _items.length > 0 &&
                                _items.map((x: any) => {
                                  if (x.keys) {
                                    return (
                                      x.keys.hash +
                                      '~' +
                                      hint.address +
                                      '-' +
                                      process.env.REACT_APP_VERSION
                                    );
                                  }
                                })}
                          </div>
                        </li>
                        <li>
                          <span className="small-tit">Transfer Information</span>
                          {_items.length > 0 &&
                            _items[0].amounts.map((amount: any, i: number) => (
                              <div key={i} className="two-box">
                                <div className="text-line">{amount.currency}</div>
                                <div className="text-line">{convertCoinAmount(amount.amount)}</div>
                              </div>
                            ))}
                        </li>
                      </ul>
                      <div>
                        <a
                          href={operation.download}
                          download={`${operation.filename}.json`}
                          target="_blank"
                          rel="noreferrer"
                          className="btn-type01"
                        >
                          <span className="ico-down">{t(`info_text_24`)}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </article>
      </div>
    </main>
  );
};

export default MultiSigTransferResponsePage;
