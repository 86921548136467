import { VFC } from 'react';

interface Props {
  closeModal: () => void;
}

const NetworkErrorModal: VFC<Props> = ({ closeModal }) => {
  return (
    <section className="popup" style={{ display: 'block' }}>
      <div className="pop-wrap text-c error-access">
        <p className="ico-error">
          <i className="br">접속에 실패하였습니다.</i>잠시 후 다시 시도하여 주세요.
        </p>
        <button type="button" className="btn-type01" onClick={closeModal}>
          확인
        </button>
      </div>
    </section>
  );
};

export default NetworkErrorModal;
