import { VFC } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { maintainInfoAtom, selectCoinAtom } from '../../store/atoms';
import bigInt from 'big-integer';
import Big from 'big.js';
import { convertCoinAmount, parsePrice, selectImageFunction } from '../../utils/helper';
import { parseAmountToDecimal, parseDecimalToAmount } from '../../lib/Parse';
import styled from 'styled-components';
import BIT_COIN_IMG from '../../assets/images/ico_assets-coin.png';
import MILE_IMG from '../../assets/images/ico_assets-mile.png';
import TOKEN_IMG from '../../assets/images/ico_assets-token.png';
import NEW_COIN_SYMBOL from '../../assets/images/coin_symbol.svg';
import { BasicHoverLink } from "../Button/BasicHover";

interface Props {
  balances: {
    currency: string;
    amount: string;
  }[];
  isTempLogin?: boolean;
}

const LiWrapper = styled.li`
  background: url(${BIT_COIN_IMG});
  width: 100px;
  height: 100px;
`;

const AssetsList: VFC<Props> = ({ balances, isTempLogin = false }) => {
  const [selectCoin, setSelectCoin] = useRecoilState(selectCoinAtom);
  const onSelectCoin = (cur: string, amount: string) => () => {
    setSelectCoin({
      amount,
      currency: cur,
    });
  };

  return (
    <div className="assets-box">
      <strong className="sub-tit">ASSETS</strong>

      <ul>
        {balances.map((bal, i) => {
          return (
            <li key={i + bal.currency} className="balance-item">
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <img
                      src={selectImageFunction(bal.currency)}
                      alt=""
                      style={{ marginRight: '16px' }}
                    />
                  </div>
                  <div>
                    <span style={{ width: '100%' }}>{bal.currency}</span>
                    <strong style={{ width: '100%' }}>
                      {convertCoinAmount(bal.amount)} {bal.currency}
                    </strong>
                  </div>
                </div>
                {isTempLogin ? null : (
                  <BasicHoverLink
                    onClick={onSelectCoin(bal.currency, bal.amount)}
                    to="/operation/transfer"
                    className="btn-type01-s"
                  >
                    Transfer
                  </BasicHoverLink>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AssetsList;
