import { VFC } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { accountInfoAtom, networkTypeAtom, operationAtom, setResultAtom } from '../../store/atoms';
import { useHistory } from 'react-router-dom';
import { broadcast } from '../../service/account';
import { OPER_CREATE_ACCOUNT, OPER_TRANSFER, OPER_UPDATE_KEY, routes } from '../../constant';
import hint from '../../text/hint.json';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import { ButtonHover, RedButtonHover } from "../Button/BasicHover";

interface Props {
  onClickHandler?: () => void;
  closeHandler: () => void;
}

const CreateMultiSigConfirmationModal: VFC<Props> = ({ onClickHandler, closeHandler }) => {
  const [operationState, setOperation] = useRecoilState(operationAtom);
  const [operationResultState, setOperationResultState] = useRecoilState(setResultAtom);
  const history = useHistory();
  const { json, filename, download, operation } = operationState;
  const networkType = useRecoilValue(networkTypeAtom);
  const notify = () => toast.error('응답을 받지 못했습니다.(네트워크 에러)');
  const { MULTI_LOADING } = routes;
  const router = useHistory();
  const { t } = useTranslation();
  const onSend = async () => {
    try {
      const result = await broadcast(json, networkType).catch((err) => {
        notify();
      });
      // console.log(result, 'result');
      if (result) {
        let data;
        switch (operation) {
          case OPER_CREATE_ACCOUNT:
            data =
              json &&
              json.fact.items.map(
                (x: any) => x.keys.hash + ':' + hint.address + '-' + process.env.REACT_APP_VERSION
              );
            break;

          case OPER_UPDATE_KEY:
            data = json && json.fact.hash;
            break;
          case OPER_TRANSFER:
          default:
            data = undefined;
            break;
        }
        setOperationResultState({
          isBroadcast: true,
          isStateIn: false,
          res: result,
          data,
          status: 200,
        });
      } else {
        notify();
        setOperationResultState({
          isBroadcast: true,
          isStateIn: false,
          res: result,
          data: undefined,
          status: 400,
        });
      }
      router.push(MULTI_LOADING);
    } catch (err) {
      notify();
      console.log(err, 'error');
    }
  };

  return (
    <section className="popup" style={{ display: 'block' }}>
      <div className="pop-wrap text-c create-multisig">
        <strong
          className="pop-tit"
          style={{ fontSize: '18px', fontWeight: 600, color: '#23262f', fontFamily: 'OpenSans' }}
        >
          Create MULTISIG Wallet
        </strong>
        <p>
          <Trans i18nKey={`info_text_21`} />
        </p>
        <div className="flex-between">
          <RedButtonHover
            type="button"
            className="btn-type01 small"
            onClick={closeHandler}
            style={{ backgroundColor: '#ff5069' }}
          >
            {t(`section_seven_text_3`)}
          </RedButtonHover>
          <ButtonHover type="button" className="btn-type01 small" onClick={onSend}>
            {t(`section_seven_text_2`)}
          </ButtonHover>
        </div>
      </div>
    </section>
  );
};

export default CreateMultiSigConfirmationModal;
